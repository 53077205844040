import angular from 'angular';

const blankPalm = `${process.env.PUBLIC_URL}/images/blank-palm.svg`;
const blankEye = `${process.env.PUBLIC_URL}images/blank-eye.svg`;
const blankFinger = `${process.env.PUBLIC_URL}/images/blank-finger.svg`;
const blankSignature = `${process.env.PUBLIC_URL}/images/blank-signature.svg`;
const noFingerImage = `${process.env.PUBLIC_URL}/images/no-image-finger.svg`;
const noIrisImage = `${process.env.PUBLIC_URL}/images/no-image-iris.svg`;

angular.module('neurotecAbisWebClientApp')
	.service('AdjudicationService', ['$log', 'AdjudicationCasesResource', 'AdjudicationResource', '$q', function ($log, AdjudicationCasesResource, AdjudicationResource, $q) {
		this.getFingerImages = function (person, caseId, fingerId, type) {
			const deferred = $q.defer();
			AdjudicationResource.getFingerImage({
				caseId,
				modalityId: fingerId,
				type,
				encounterId: person.encounterId
			}, (data) => {
				person.fingers[fingerId].imageUrl = URL.createObjectURL(data.image);
				deferred.resolve();
			}, (response) => {
				if (response.status === 404) {
					person.fingers[fingerId].image = noFingerImage;
					person.fingers[fingerId].imageUrl = noFingerImage;
					person.fingers[fingerId].forceImageType = 'NONE';
					person.fingers[fingerId].unclickable = true;
					deferred.resolve();
				} else {
					deferred.reject();
					throw new Error(response.statusText);
				}
			});
			person.fingers[fingerId].imageUrl = blankFinger;
			return deferred.promise;
		};

		this.getPalmImages = function (person, caseId, palmId, type) {
			const deferred = $q.defer();
			AdjudicationResource.getPalmImage({
				caseId,
				modalityId: palmId,
				type,
				encounterId: person.encounterId
			}, (data) => {
				person.palms[palmId].imageUrl = URL.createObjectURL(data.image);
				deferred.resolve();
			}, (response) => {
				if (response.status === 404) {
					person.palms[palmId].image = noFingerImage;
					person.palms[palmId].imageUrl = noFingerImage;
					person.palms[palmId].forceImageType = 'NONE';
					person.palms[palmId].unclickable = true;
					deferred.resolve();
				} else {
					deferred.reject();
					throw new Error(response.statusText);
				}
			});
			person.palms[palmId].imageUrl = blankPalm;
			return deferred.promise;
		};

		this.getIrisImages = function (person, caseId, irisId) {
			const deferred = $q.defer();
			AdjudicationResource.getIrisImage({
				caseId,
				modalityId: irisId,
				encounterId: person.encounterId
			}, (data) => {
				person.irises[irisId].imageUrl = URL.createObjectURL(data.image);
				deferred.resolve();
			}, (response) => {
				if (response.status === 404) {
					person.irises[irisId].image = noIrisImage;
					person.irises[irisId].imageUrl = noIrisImage;
					person.irises[irisId].unclickable = true;
					deferred.resolve();
				} else {
					deferred.reject();
					throw new Error(response.statusText);
				}
			});
			person.irises[irisId].imageUrl = blankEye;
			return deferred.promise;
		};

		this.getSignatureImage = function (person, caseId) {
			return AdjudicationResource.getSignatureImage({
				caseId,
				encounterId: person.encounterId
			}, (data) => {
				person.signature = {
					imageUrl: URL.createObjectURL(data.image)
				};
				person.signaturesCount = 1;
			}, (response) => {
				if (response.status === 404) {
					person.signature.imageUrl = {
						imageUrl: blankSignature
					};
					person.signature.unclickable = true;
				} else {
					throw new Error(response.statusText);
				}
			}).$promise;
			// person.signature.imageUrl = require('public/images/blank-signature.svg');
		};

		this.getImages = function (caseId, personObj, types) {
			var { person } = personObj;
			var fingerId;
			var irisId;
			var palmId;
			const promises = [];

			if (person.faces[0]) {
				const deferred = $q.defer();
				promises.push(deferred.promise);
				AdjudicationResource.getFaceImage({ caseId, modalityId: 0, encounterId: person.encounterId }, (data) => {
					person.faces[0].imageUrl = URL.createObjectURL(data.image);
					deferred.resolve();
				}, (response) => {
					if (response.status === 404) {
						person.faces[0].imageUrl = noFingerImage;
						person.faces[0].image = noFingerImage;
						person.faces[0].unclickable = true;
						deferred.resolve();
					} else {
						deferred.reject();
						throw new Error(response.statusText);
					}
				});
			}
			for (irisId = 0; irisId < person.irises.length; irisId += 1) {
				promises.push(this.getIrisImages(person, caseId, irisId));
			}
			for (fingerId = 0; fingerId < person.fingers.length; fingerId += 1) {
				promises.push(this.getFingerImages(person, caseId, fingerId, types.fingerType));
			}
			for (palmId = 0; palmId < person.palms.length; palmId += 1) {
				promises.push(this.getPalmImages(person, caseId, palmId, types.palmType));
			}
			// person.signature = {};
			person.signaturesCount = 0;
			this.getSignatureImage(person, caseId);
			return $q.all(promises);
		};
	}]);
