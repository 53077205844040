import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('palm', ['$sce', '$translate', 'PainterService', 'ParserResource', 'Utils', 'namingService', 'SettingsService', function ($sce, $translate, PainterService, ParserResource, Utils, namingService, SettingsService) {
		return {
			template: require('../../views/directives/palm.html'),
			restrict: 'E',
			replace: true,
			scope: {
				palm: '=',
				palmsScore: '=',
				position: '=',
				imgClick: '&',
				showMatchingDetails: '=',
				noHover: '='
			},
			link(scope, element, attrs) {
				scope.isBlur = SettingsService.getShouldBlurImages().palm;

				scope.imageOnError = function () {
					if (scope.palm && scope.palm.image) {
						if (scope.parsedPalm !== null && scope.parsedPalm !== undefined) {
							URL.revokeObjectURL(scope.parsedPalm);
							scope.parsedPalm = null;
						}
						ParserResource.parseImage({ data: scope.palm.image }, (result) => {
							scope.parsedPalm = URL.createObjectURL(Utils.b64toBlob(result.data));
						});
					}
				};

				scope.upperSnakeToKebabCase = namingService.upperSnakeToKebabCase;

				scope.hasImgClick = function () {
					function hasHitDetails() {
						return scope.palm.matchingDetails && (scope.palm.matchingDetails != null &&
							scope.palm.matchingDetails.length > 0 &&
							scope.showMatchingDetails);
					}
					return angular.isDefined(attrs.imgClick) &&
							scope.palm != null &&
							(hasHitDetails() || scope.finger.unclickable !== true);
				};

				scope.imgClickInternal = function () {
					if (scope.hasImgClick()) {
						scope.imgClick();
					}
				};

				scope.$watch(() => scope.palm, (newValue) => {
					if (newValue != null) {
						scope.overlay = newValue.isFailedToExtract;
						$translate(['subject.quality', `biometrics.palms.position.${scope.position}`]).then((t) => {
							scope.tooltipText = $sce.trustAsHtml(`<div>
							${scope.position ? `${t[`biometrics.palms.position.${scope.position}`]}` : ''}
							<br/>
							${newValue.quality ? `${t['subject.quality']}: ${newValue.quality}` : ''}</div>`);
						});

						scope.markPalmAsHovered = function () {
							if (newValue.matchingDetails && !scope.noHover) {
								PainterService.markPalmAsHovered(newValue.matchingDetails, newValue.index);
							}
						};
						scope.unhoverBiometric = function () {
							if (newValue.matchingDetails && !scope.noHover) {
								PainterService.unhoverBiometric();
							}
						};
						scope.shouldPalmHighlight = function () {
							return !scope.noHover ? PainterService.shouldPalmHighlight(newValue.index, scope.showMatchingDetails) : false;
						};
					}
				});

				scope.parsePosition = function (position) {
					if (position === 'UNKNOWN_PALM') return position;
					const substrings = position.split('_');
					return substrings[1];
				};
			}
		};
	}]);
