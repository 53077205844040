import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('drawingService', ['ConvertionUtils', function (ConvertionUtils) {
		var MTStrokeCommands = {};
		const DEFAULT_MINUTIA_COLOR = '#FF0000';

		const minutiaColors = ['#b74600', '#aa00c7', '#FFFF00', '#00FF00', '#0000FF', '#4B0082', '#8B00FF'];
		this.getMinutiaColor = function (index, matedMinutiae, containerName) {
			const namesArray = ['probe', 'hit'];
			const idx = matedMinutiae.findIndex(couple => couple[`index${namesArray.indexOf(containerName) + 1}`] === index);
			if (idx !== -1) {
				return minutiaColors[idx % minutiaColors.length];
			}
			return DEFAULT_MINUTIA_COLOR;
		};

		this.getDefaultMinutiaColor = function () {
			return DEFAULT_MINUTIA_COLOR;
		};

		function getEndPoint(angle, len) {
			return {
				x: (len * Math.cos(angle)),
				y: (len * Math.sin(angle))
			};
		}

		this.drawMinutia = function (graphics, minutia, width, color, hitArea) {
			if (width == null) {
				width = 1.0;
			}
			if (color == null) {
				({ color } = graphics);
			}

			function drawMinutiaInternal(graphics) {
				graphics.drawCircle(0, 0, 4);

				var lineLength = 12;
				if (minutia.bifurcation) {
					var halfAngle = Math.PI / 12;
					var endPoint1 = getEndPoint(-halfAngle, lineLength);
					var endPoint2 = getEndPoint(halfAngle, lineLength);
					graphics.moveTo(0, 0);
					graphics.lineTo(endPoint1.x, endPoint1.y);
					graphics.moveTo(0, 0);
					graphics.lineTo(endPoint2.x, endPoint2.y);
				} else {
					graphics.moveTo(0, 0);
					graphics.lineTo(lineLength, 0);
				}
			}

			const transparentWhite = 'rgba(255, 255, 255, 0.3)';
			graphics.setStrokeStyle(width + 1).beginStroke(transparentWhite);
			drawMinutiaInternal(graphics);

			if (hitArea === true) {
				graphics.setStrokeStyle(width).beginStroke(color).beginFill(color);
			} else {
				graphics.setStrokeStyle(width).beginStroke(color);
			}
			drawMinutiaInternal(graphics);
		};

		this.createMinutia = function (minutia, index, color) {
			const minutiaContainer = new window.createjs.Container();

			const shape = new window.createjs.Shape();
			shape.name = index;
			shape.rotation = ConvertionUtils.radiansToDegress(minutia.angle);
			shape.x = minutia.x;
			shape.y = minutia.y;
			shape.snapToPixel = true;
			shape.color = color;
			this.drawMinutia(shape.graphics, minutia, null, color);

			const infoContainer = new window.createjs.Container();
			infoContainer.name = 'info';

			const text = new window.createjs.Text(index, '6px bold', 'white');
			text.name = 'text';
			text.set({
				textAlign: 'center',
				textBaseline: 'middle',
				x: minutia.x + 8,
				y: minutia.y + 0.5,
			});

			const box = new window.createjs.Shape();
			box.name = 'box';
			box.graphics.beginFill('rgba(0, 1, 0, 0.4)');
			box.graphics.moveTo(minutia.x, minutia.y + 4);
			box.graphics.lineTo(minutia.x + 12, minutia.y + 4);
			box.graphics.lineTo(minutia.x + 12, minutia.y - 4);
			box.graphics.lineTo(minutia.x, minutia.y - 4);
			box.graphics.arc(minutia.x, minutia.y, 4, -0.5 * Math.PI, -1.5 * Math.PI);

			infoContainer.addChild(box, text);
			minutiaContainer.addChild(shape, infoContainer);
			return minutiaContainer;
		};

		this.createMinutiaHitArea = function (minutia) {
			const shape = new window.createjs.Shape();
			this.drawMinutia(shape.graphics, minutia, 10, 'black', true);

			return shape;
		};

		this.drawCore = function (graphics, core, width, color) {
			if (width == null) {
				width = 1;
			}
			if (color == null) {
				color = 'red';
			}

			graphics.setStrokeStyle(width).beginStroke(color);

			var squareSideLength = 20;
			var squareCoord = -squareSideLength / 2;
			graphics.drawRect(squareCoord, squareCoord, squareSideLength, squareSideLength);

			var endPoint = getEndPoint(core.angle, 20);
			graphics.moveTo(0, 0);
			graphics.lineTo(endPoint.x, endPoint.y);
		};

		this.createCore = function (core, color) {
			var shape = new window.createjs.Shape();

			shape.x = core.x;
			shape.y = core.y;

			this.drawCore(shape.graphics, core, null, color);

			shape.shadow = new window.createjs.Shadow('#505050', 0, 0, 1);

			return shape;
		};

		this.createCoreHitArea = function (core) {
			var shape = new window.createjs.Shape();
			this.drawCore(shape.graphics, core, 6, 'black');
			return shape;
		};

		this.drawDelta = function (graphics, delta, width, color) {
			if (width == null) {
				width = 1;
			}
			if (color == null) {
				color = 'red';
			}

			graphics.setStrokeStyle(width).beginStroke(color);

			graphics.drawPolyStar(0, 0, 20, 3, 0, 30);

			var endPoint;
			if (!isNaN(delta.angle1)) {
				endPoint = getEndPoint(delta.angle1, 30);
				graphics.moveTo(0, 0);
				graphics.lineTo(endPoint.x, endPoint.y);
			}
			if (!isNaN(delta.angle2)) {
				endPoint = getEndPoint(delta.angle2, 30);
				graphics.moveTo(0, 0);
				graphics.lineTo(endPoint.x, endPoint.y);
			}
			if (!isNaN(delta.angle3)) {
				endPoint = getEndPoint(delta.angle3, 30);
				graphics.moveTo(0, 0);
				graphics.lineTo(endPoint.x, endPoint.y);
			}
		};

		this.createDelta = function (delta, color) {
			var shape = new window.createjs.Shape();

			shape.x = delta.x;
			shape.y = delta.y;

			this.drawDelta(shape.graphics, delta, null, color);

			shape.shadow = new window.createjs.Shadow('#505050', 0, 0, 1);

			return shape;
		};

		this.createDeltaHitArea = function (delta) {
			var shape = new window.createjs.Shape();
			this.drawDelta(shape.graphics, delta, 3, 'black');
			return shape;
		};

		this.drawDoubleCore = function (graphics, width, color) {
			if (width == null) {
				width = 1;
			}
			if (color == null) {
				color = 'red';
			}

			graphics.setStrokeStyle(width).beginStroke(color);

			graphics.drawCircle(0, 0, 10);
		};

		this.createDoubleCore = function (doubleCore, color) {
			var shape = new window.createjs.Shape();

			shape.x = doubleCore.x;
			shape.y = doubleCore.y;

			this.drawDoubleCore(shape.graphics, null, color);

			shape.shadow = new window.createjs.Shadow('#505050', 0, 0, 1);

			return shape;
		};

		this.createDoubleCoreHitArea = function () {
			var shape = new window.createjs.Shape();
			this.drawDoubleCore(shape.graphics, 5, 'black');
			return shape;
		};

		this.createMatchingTree = function (edges, matedMinutiae, pairIndexName, minutiae, color) {
			var width = 2.0;

			if (color == null) {
				color = 'purple';
			}

			var shape = new window.createjs.Shape();
			MTStrokeCommands[pairIndexName] = shape.graphics.setStrokeStyle(width).beginStroke(color).command;

			for (var i = 0; i < edges.length; i += 1) {
				var edge = edges[i];

				var from = minutiae[matedMinutiae[edge.from][pairIndexName]];
				var to = minutiae[matedMinutiae[edge.to][pairIndexName]];

				shape.graphics.moveTo(from.x, from.y);
				shape.graphics.lineTo(to.x, to.y);
			}

			shape.shadow = new window.createjs.Shadow('#505050', 0, 0, 1);

			return shape;
		};

		this.createRectangleHitArea = function (rect) {
			var shape = new window.createjs.Shape();
			shape.graphics.beginFill('black');
			shape.graphics.drawRect(rect.x, rect.y, rect.width, rect.height);
			return shape;
		};

		this.getMTStrokeCommands = function () {
			return MTStrokeCommands;
		};

		this.lightenHexColor = function (color, luminosity) {
			// validate hex string
			color = color.toString().replace(/[^0-9a-f]/gi, '');
			if (color.length < 6) {
				color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
			}
			luminosity = luminosity || 0;

			// convert to decimal and change luminosity
			var newColor = '#';
			var c;
			var i;
			var black = 0;
			var white = 255;
			for (i = 0; i < 3; i += 1) {
				c = parseInt(color.substr(i * 2, 2), 16);
				c = Math.round(Math.min(Math.max(black, c + (luminosity * white)), white)).toString(16);
				newColor += (`00${c}`).substr(c.length);
			}
			return newColor;
		};

		function hexToRGB(hex, alpha) {
			var r = parseInt(hex.slice(1, 3), 16);
			var g = parseInt(hex.slice(3, 5), 16);
			var b = parseInt(hex.slice(5, 7), 16);

			if (alpha) {
				return `rgba(${r}, ${g}, ${b}, ${alpha})`;
			}
			return `rgb(${r}, ${g}, ${b})`;
		}

		this.getDefaultDarkerHexColor = function (color) {
			return hexToRGB(this.lightenHexColor(color, -0.4), 0.5);
		};

		this.drawLine = function (graphics, from, to, color, width) {
			if (!width) {
				width = 1;
			}

			graphics.beginFill(color);
			graphics.setStrokeStyle(width).beginStroke(color);
			graphics.moveTo(from.x, from.y);
			graphics.lineTo(to.x, to.y);
		};
	}]);
