import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('GalleriesResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseManagementUrl()}/galleries/:action/:id`, { galleryId: '@id', action: '@action' }, {
			getGalleries: {
				method: 'GET',
				isArray: true
			},
			getSizes: {
				method: 'GET',
				isArray: false,
				params: {
					action: 'galleries-sizes'
				}
			}
		});
	}]);
