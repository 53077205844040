import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import ActionCard from '../components/ActionCard';
import useHasAnyAuthority from '../hooks/hasAnyAuthority';
import NRow from '../components/base/NRow';
import EnrollIcon from '../icons/enroll.svg';
import IdentifyIcon from '../icons/identify.svg';
import PassportIcon from '../icons/passport.svg';
import SearchIcon from '../icons/search.svg';
import TransactionsIcon from '../icons/transactions.svg';
import AdjudicationIcon from '../icons/adjudication.svg';
import DeduplicationIcon from '../icons/deduplication.svg';
import LatentIcon from '../icons/latent.svg';
import i18next from 'i18next';

interface IConditionalProps {
	evaluate: boolean;
}

const HomeView: React.FC = () => {
	const { t } = useTranslation();
	const { hasAnyAuthority } = useHasAnyAuthority();

	const Conditional: React.FC<IConditionalProps> = ({ evaluate, children }) => {
		if (!evaluate) {
			return null;
		}
		return <>{children}</>;
	};

	// Fake translation suspense untill react 18
	if (!i18next.exists('actions.actions')) {
		return null;
	}

	return (
		<>
			<Header>
				<i className="fa fa-bars"></i>
				{' ' + t('actions.actions')}
			</Header>

			<NRow>
				<Conditional
					evaluate={hasAnyAuthority([
						'PERMISSION_BIOMETRIC_ENROLL_WITH_DUPLICATE_CHECK',
						'PERMISSION_BIOMETRIC_ENROLL',
					])}
				>
					<ActionCard
						name={t('actions.enroll.enroll')}
						url="/actions/enroll"
						icon={<EnrollIcon className="icon-fill-white img-responsive fill-width-icon" />}
					/>
				</Conditional>

				<Conditional evaluate={hasAnyAuthority(['PERMISSION_BIOMETRIC_IDENTIFY'])}>
					<ActionCard
						name={t('actions.identify.identify')}
						url="/actions/identify"
						icon={<IdentifyIcon className="icon-fill-white img-responsive fill-width-icon" />}
					/>
				</Conditional>

				<Conditional evaluate={hasAnyAuthority(['PERMISSION_BIOMETRIC_IDENTIFY'])}>
					<ActionCard
						name={t('actions.scan.scan-document')}
						url="/actions/scan-document"
						icon={<PassportIcon className="icon-fill-white img-responsive fill-width-icon" />}
					/>
				</Conditional>

				<Conditional evaluate={hasAnyAuthority(['PERMISSION_ENCOUNTER_LIST'])}>
					<ActionCard
						name={t('actions.search.search')}
						url="/actions/subjects"
						icon={<SearchIcon className="icon-fill-white img-responsive fill-width-icon" />}
					/>
				</Conditional>

				<Conditional evaluate={hasAnyAuthority(['PERMISSION_TRANSACTION_LIST'])}>
					<ActionCard
						name={t('actions.transactions.transactions')}
						url="/actions/transactions"
						icon={<TransactionsIcon className="icon-fill-white img-responsive fill-width-icon" />}
					/>
				</Conditional>

				<Conditional
					evaluate={
						hasAnyAuthority(['PERMISSION_ADJUDICATION_VIEW']) && !hasAnyAuthority(['MODIFIER_ADJUDICATION_SUPERVISOR'])
					}
				>
					<ActionCard
						name={t('actions.adjudication')}
						url="/actions/adjudication/cases"
						icon={<AdjudicationIcon className="icon-fill-white img-responsive fill-width-icon" />}
					/>
				</Conditional>

				<Conditional
					evaluate={
						hasAnyAuthority(['PERMISSION_ADJUDICATION_VIEW']) && hasAnyAuthority(['MODIFIER_ADJUDICATION_SUPERVISOR'])
					}
				>
					<ActionCard
						name={t('actions.deduplication.deduplication')}
						url="/actions/deduplication"
						icon={<DeduplicationIcon className="icon-fill-white img-responsive fill-width-icon" />}
					/>
				</Conditional>

				<Conditional evaluate={hasAnyAuthority(['PERMISSION_OTHER_LATENT_PRINT'])}>
					<ActionCard
						name={t('actions.latent-editor.latent-editor')}
						url="/actions/latent-editor"
						icon={<LatentIcon className="icon-fill-white img-responsive fill-width-icon" />}
					/>
				</Conditional>
			</NRow>
		</>
	);
};

export default HomeView;
