import angular from 'angular';
import moment from 'moment';

angular.module('neurotecAbisWebClientApp')
	.service('DeviceServerParametersService', ['$q', 'DeviceServerParametersResource', 'DeviceServerResource', 'AlertService', 'Utils',
		function ($q, DeviceServerParametersResource, DeviceServerResource, AlertService, Utils) {
			const DEVICE_PARAMETERS_KEY = 'devices';
			const FINGERS_PARAMS_KEYS = ['Fingers.QualityAlgorithm', 'Fingers.CheckForDuplicates'];
			const FACES_PARAMS_KEYS = [
				'Faces.DetermineGender', 'Faces.RecognizeExpression', 'Faces.RecognizeEmotion',
				'Faces.DetermineAge', 'Faces.DetermineEthnicity', 'Faces.CheckIcaoCompliance', 'Faces.IcaoMaximalRoll',
				'Faces.IcaoMaximalYaw', 'Faces.IcaoMaximalPitch', 'Faces.IcaoSaturationThreshold', 'Faces.IcaoSharpnessThreshold',
				'Faces.IcaoBackgroundUniformityThreshold', 'Faces.IcaoGrayscaleDensityThreshold', 'Faces.IcaoLookingAwayThreshold', 'Faces.IcaoRedEyeThreshold',
				'Faces.IcaoFaceDarknessThreshold', 'Faces.IcaoUnnaturalSkinToneThreshold', 'Faces.IcaoWashedOutThreshold', 'Faces.IcaoPixelationThreshold',
				'Faces.IcaoSkinReflectionThreshold', 'Faces.IcaoGlassesReflectionThreshold', 'Faces.IcaoRemoveRedEye', 'Faces.IcaoRemoveBackground'
			];

			this.initDeviceServer = function (params = []) {
				return DeviceServerResource.initDeviceServer(params).$promise;
			};

			function compareVersions(givenVersion, lastCompatible, lastComparableLevel = 1) {
				const givenVersionArr = givenVersion.split('.');
				const lastCompatibleArr = lastCompatible.split('.');

				// lastComparableLevel - last comparable version sub-number. All latter numbers will be ignored.
				const decision = { isCompatible: undefined, biggerVersion: null };
				for (let i = 0; i < Math.max(givenVersionArr.length, lastCompatibleArr.length); i += 1) {
					const v1 = parseInt(givenVersionArr[i], 10) || 0;
					const v2 = parseInt(lastCompatibleArr[i], 10) || 0;

					if (decision.isCompatible === undefined && v1 !== v2 && i < lastComparableLevel) {
						decision.isCompatible = false;
						decision.biggerVersion = (decision.biggerVersion === null && v1 > v2) ? givenVersion : lastCompatible;
					}
				}

				decision.isCompatible = decision.isCompatible === undefined ? true : decision.isCompatible;
				decision.biggerVersion = decision.biggerVersion === null ? givenVersion : decision.biggerVersion;
				return decision;
			}

			function dateCheckIfNeedsUpdate(backendParams, deviceServerParams) {
				const backendLastUpdated = backendParams.find(param => param.key === 'LastUpdated').value;
				const deviceServerLastUpdated = deviceServerParams.parameters.find(param => param.name === 'LastUpdated').defaultValue;
				return backendLastUpdated !== deviceServerLastUpdated;
			}

			function checkVersions() {
				const deferred = $q.defer();

				DeviceServerResource.getVersion().$promise
					.then((deviceServerVersionData) => {
						DeviceServerParametersResource.getParameters({ names: 'version' }).$promise
							.then((rawBackendParametersVersionData) => {
								const backendParametersVersionData = rawBackendParametersVersionData[0].value;
								if (deviceServerVersionData.hash === backendParametersVersionData.hash) {
									deferred.resolve();
								} else {
									const decision = compareVersions(deviceServerVersionData.version, backendParametersVersionData.version);
									const deviceServerDate = moment(deviceServerVersionData.date, 'YYYY-MM-DD');
									const backendDate = moment(backendParametersVersionData.date, 'YYYY-MM-DD');
									if ((decision.biggerVersion === deviceServerVersionData.version && deviceServerVersionData.version !== backendParametersVersionData.version)
										|| backendDate.isBefore(deviceServerDate)) {
										deferred.reject({ message: 'capture-service.parameters.downgrade' });
									} else {
										deferred.reject({ message: 'capture-service.parameters.update' });
									}
								}
							})
							.catch(() => {
								deferred.reject({ status: 'WAITING_FOR_INITIALIZATION', message: 'capture-service.parameters.setup' });
							});
					})
					.catch((error) => {
						if (error.status !== -1) { // DS is turned on
							deferred.reject({ status: 'STATUS_ERROR', message: 'capture-service.parameters.incompatible-device-server' });
						}
					});

				return deferred.promise;
			}

			this.fetchAndInit = function (parameters) {
				const deferred = $q.defer();

				checkVersions()
					.then(() => {
						DeviceServerResource.getInfo().$promise
							.then((deviceServerParams) => {
								this.getParameters([...deviceServerParams.parameters.map(param => param.name), DEVICE_PARAMETERS_KEY])
									.then((backendParams) => {
										backendParams = backendParams.map(param => ({
											key: param.key,
											value: (typeof param.value !== 'object' && param.value !== null) ? param.value.toString() : param.value
										}));

										if (dateCheckIfNeedsUpdate(backendParams, deviceServerParams)) {
											if (!parameters) {
												deferred.resolve(this.initDeviceServer(backendParams));
											} else {
												deferred.resolve(this.initDeviceServer(parameters));
											}
										} else {
											deferred.resolve();
										}
									});
							});
					})
					.catch(err => deferred.reject(err));

				return deferred.promise;
			};

			function transformResponse(parameters, preserveOtherProperties = false) {
				return preserveOtherProperties
					? parameters.map(param => (Object.assign({}, { key: param.name }, param.value.value !== undefined ? param.value : { value: param.value })))
					: parameters.map(param => ({ key: param.name, value: param.value.value !== undefined ? param.value.value : param.value }));
			}


			this.getParameters = function (names = [], getResponseAsKeyValueModel = true) {
				function transformDeviceParams(parameters) {
					const newArr = [{ key: 'DeviceParameters', value: [] }];
					parameters.forEach((parameterObj) => {
						const [, extractedDevice] = parameterObj.name.split('device-parameters.');
						newArr[0].value.push({
							key: extractedDevice,
							value: parameterObj.value.map(prop => ({ key: prop.name, value: prop.value }))
						});
					});
					return newArr;
				}

				function removeEmpty(params) {
					return params.filter((param) => {
						if (param.value.value !== undefined) {
							return Utils.isBoolean(param.value.value) ? true : !!param.value.value;
						}
						return Utils.isBoolean(param.value) ? true : !!param.value;
					});
				}

				const deferred = $q.defer();
				if (names.length <= 0) deferred.reject([]);

				DeviceServerParametersResource.getParameters({ names }).$promise
					.then((parameters) => {
						const newParameters = transformResponse(removeEmpty(parameters), !getResponseAsKeyValueModel);

						if (newParameters.length > 0 && names.includes('devices')) {
							const devicesListIndex = newParameters.findIndex(param => param.key === 'devices');
							const devicesList = newParameters[devicesListIndex].value;
							newParameters.splice(devicesListIndex, 1);

							if (devicesList.length > 0) {
								DeviceServerParametersResource.getParameters({ names: devicesList.map(device => `device-parameters.${device}`) }).$promise
									.then((devices) => {
										const devicesParameters = transformDeviceParams(devices);
										deferred.resolve([...newParameters, ...devicesParameters]);
									});
							} else {
								deferred.resolve(newParameters);
							}
						} else {
							deferred.resolve(newParameters);
						}
					})
					.catch(deferred.reject);

				return deferred.promise;
			};

			this.getFingersConfiguration = function () {
				const deferred = $q.defer();
				this.getParameters(FINGERS_PARAMS_KEYS)
					.then((fingers) => {
						deferred.resolve(fingers);
					})
					.catch(error => deferred.reject(error));
				return deferred.promise;
			};

			this.getFacesConfiguration = function () {
				function transformFacesParams(parameters) {
					return parameters.reduce((acc, param) => {
						if (param.parameterType === 'capture') {
							acc.capture.push({
								key: param.key,
								value: param.value
							});
						} else {
							acc.icao.push({
								key: param.key,
								value: param.value
							});
						}
						return acc;
					}, { capture: [], icao: [] });
				}

				const deferred = $q.defer();
				this.getParameters(FACES_PARAMS_KEYS, false)
					.then((faces) => {
						deferred.resolve(transformFacesParams(faces));
					})
					.catch(error => deferred.reject(error));
				return deferred.promise;
			};

			this.getDevicesConfiguration = function () {
				return this.getParameters(DEVICE_PARAMETERS_KEY);
			};

			this.checkForUpdate = function () {
				const deferred = $q.defer();
				checkVersions()
					.then(() => {
						DeviceServerResource.getInfo().$promise
							.then((deviceServerParams) => {
								this.getParameters([...deviceServerParams.parameters.map(param => param.name), DEVICE_PARAMETERS_KEY])
									.then((backendParams) => {
										backendParams = backendParams.map(param => ({
											key: param.key,
											value: (typeof param.value !== 'object' && param.value !== null) ? param.value.toString() : param.value
										}));
										if (dateCheckIfNeedsUpdate(backendParams, deviceServerParams)) {
											this.initDeviceServer(backendParams)
												.then(() => deferred.resolve(backendParams));
										} else {
											deferred.resolve({});
										}
									});
							});
					})
					.catch((err) => {
						if (err.message) {
							AlertService.show(err.message, { type: 'danger', translate: true });
						}
						deferred.reject($q.when(err));
					});

				return deferred.promise;
			};

			this.setParameters = function (parameters) {
				return DeviceServerParametersResource.setParameters(parameters).$promise;
			};
		}]);
