import angular from 'angular';
import FileSaver from 'file-saver';

angular.module('neurotecAbisWebClientApp')
	.controller('SubjectCtrl', ['$confirm', '$scope', '$state', '$stateParams', '$uibModal', '$translate', '$q', '$window', 'store', 'AbisService', 'AlertService', 'AuthDataHolder', 'BiometricsService', 'blockUI', 'EncountersResource', 'NotificationsService', 'SubjectService', 'EncounterService', 'BiographicDataService', 'SubjectPageService', 'Utils', 'ConvertionUtils', 'SortHelper',
		function ($confirm, $scope, $state, $stateParams, $uibModal, $translate, $q, $window, store, AbisService, AlertService, AuthDataHolder, BiometricsService, blockUI, EncountersResource, NotificationsService, SubjectService, EncounterService, BiographicDataService, SubjectPageService, Utils, ConvertionUtils, SortHelper) {
			if (!$stateParams.subjectId || !$stateParams.encounterId || !$stateParams.galleryId) {
				$state.go('actions.home', null, { location: 'replace' });
				SubjectPageService.invalidateFetchedResults();
				blockUI.reset();
			}
			$scope.actionPageName = $stateParams.previousState && $stateParams.previousState.name;

			const { subjectId, galleryId } = $stateParams;
			$scope.transactions = {
				main: {
					transactions: [],
					encounterIdToTransactionIdx: new Map()
				},
				group: {
					transactions: [],
					encounterIdToTransactionIdx: new Map()
				}
			};
			$scope.timelineSeries = [];
			$scope.historyEvents = [];
			$scope.transactionLifecycleDates = [];
			$scope.events = [];
			$scope.subject = {};

			const newEncountersGroup = () => ({
				encounters: [],
				loadedEncounters: new Set(),
				modalitiesOptions: {},
				main: {
					encounter: {},
					selectedEncounterId: $stateParams.encounterId,
				},
				compare: {
					encounter: {},
					selectedEncounterId: 0,
				}
			});

			$scope.subjects = newEncountersGroup();
			$scope.group = newEncountersGroup();

			$scope.cnv = ConvertionUtils;

			$scope.getNavigationOptions = () => SubjectPageService.getNavigationOptions();
			$scope.setTab = newTab => SubjectPageService.setNavigationTab(newTab);
			$scope.setSubTab = newSubTab => SubjectPageService.setNavigationSubTab(newSubTab);
			$scope.setSaveNavigationOptions = newSaveOption => SubjectPageService.setSaveNavigationOptions(newSaveOption);

			$scope.hasKey = BiographicDataService.hasKey;
			BiographicDataService.get().then((fields) => {
				$scope.fields = fields;
			});

			$scope.irisOrder = EncounterService.irisOrder;
			$scope.fingerOrder = EncounterService.fingerOrder;
			$scope.palmOrder = EncounterService.palmOrder;
			$scope.positionsKeys = EncounterService.positionsKeys;

			$scope.getEncountersContainer = () => $scope.subjects;
			$scope.getEncountersTransactions = () => $scope.transactions.main;

			$scope.$watchGroup(['subjects.main.encounter', 'subjects.compare.encounter'], newValue => updateModalities(newValue[0], newValue[1]));
			$scope.$watchGroup(['group.main.encounter', 'group.compare.encounter'], newValue => updateModalities(newValue[0], newValue[1]));
			$scope.$watch(() => SubjectPageService.getNavigationOptions(), (newValue) => {
				if (!newValue) return;

				switch (newValue.activeTab) {
				case 'tab-subject':
					$scope.getEncountersContainer = () => $scope.subjects;
					$scope.getEncountersTransactions = () => $scope.transactions.main;
					checkIfLoadingScreenIsNeeded();
					break;
				case 'tab-group':
					$scope.getEncountersContainer = () => $scope.group;
					$scope.getEncountersTransactions = () => $scope.transactions.group;
					break;
				default:
					break;
				}
			}, true);

			function checkIfLoadingScreenIsNeeded() {
				const stopWatchingForInitialization = $scope.$watch('initializing', (isInitializing) => {
					if (isInitializing) {
						blockUI.start('app.loading');
					} else {
						blockUI.stop();
						stopWatchingForInitialization();
					}
				});
			}

			function updateModalities(leftSideEncounter, rightSideEncounter) {
				if (leftSideEncounter && !Utils.isObjectEmpty(leftSideEncounter)) {
					if (rightSideEncounter && !Utils.isObjectEmpty(rightSideEncounter)) {
						$scope.getEncountersContainer().modalitiesOptions = BiometricsService.getModalitiesOptions(leftSideEncounter, rightSideEncounter);
					} else {
						$scope.getEncountersContainer().modalitiesOptions = BiometricsService.getModalitiesOptions(leftSideEncounter);
					}
				}
			}

			$scope.getListNumber = function (encounterModel) {
				return $scope.getEncountersContainer().encounters.findIndex(el => el.encounterId === encounterModel.selectedEncounterId) + 1;
			};

			function checkForCollision(encountersContainer, nextIndex, direction) {
				let payloadIndex = nextIndex;

				if (encountersContainer.encounters[nextIndex].encounterId === encountersContainer.main.selectedEncounterId
					|| encountersContainer.encounters[nextIndex].encounterId === encountersContainer.compare.selectedEncounterId) {
					payloadIndex += direction;

					if (payloadIndex < 0) { // check if new index is below bounds
						payloadIndex = encountersContainer.encounters.length - 1;
					} else if (payloadIndex === encountersContainer.encounters.length) { // check if new index is above bounds
						payloadIndex = 0;
					}
				}

				return payloadIndex;
			}

			function findNewEncounterIndex(encountersContainer, encounterModel, direction) {
				let index = encountersContainer.encounters.findIndex(el => el.encounterId === encounterModel.selectedEncounterId);

				if (direction > 0 && (index === (encountersContainer.encounters.length - 1))) { // next button check for out of bounds
					index = 0;
				} else if (direction < 0 && index === 0) { // prev button check for out of bounds
					index = encountersContainer.encounters.length - 1;
				} else {
					index += direction;
				}
				return index;
			}

			function synchronizeModel(selectedModel, newEncounterId) {
				if (selectedModel.selectedEncounterId !== newEncounterId) { // for previous, next buttons
					selectedModel.selectedEncounterId = newEncounterId;
				}
			}

			function moveToEncounter(encountersContainer, encounterModel, pairEncounterModel, direction, isSilentUpdate) {
				let index = findNewEncounterIndex(encountersContainer, encounterModel, direction);

				if ($scope.getNavigationOptions().activeSubTab.includes('compare')) {
					index = checkForCollision(encountersContainer, index, direction);
				}

				synchronizeModel(encounterModel, encountersContainer.encounters[index].encounterId);
				changeEncounter(encountersContainer, encounterModel, pairEncounterModel, encounterModel.selectedEncounterId, isSilentUpdate);
			}

			$scope.previousEncounter = function (isSilentUpdate) {
				const direction = -1;
				moveToEncounter($scope.getEncountersContainer(), $scope.getEncountersContainer().main, $scope.getEncountersContainer().compare, direction, isSilentUpdate);
			};

			$scope.nextEncounter = function (isSilentUpdate) {
				const direction = 1;
				moveToEncounter($scope.getEncountersContainer(), $scope.getEncountersContainer().main, $scope.getEncountersContainer().compare, direction, isSilentUpdate);
			};

			$scope.previousComparison = function (isSilentUpdate) {
				const direction = -1;
				moveToEncounter($scope.getEncountersContainer(), $scope.getEncountersContainer().compare, $scope.getEncountersContainer().main, direction, isSilentUpdate);
			};

			$scope.nextComparison = function (isSilentUpdate) {
				const direction = 1;
				moveToEncounter($scope.getEncountersContainer(), $scope.getEncountersContainer().compare, $scope.getEncountersContainer().main, direction, isSilentUpdate);
			};

			function loadBiometrics(encountersContainer, pairEncounterModel, encounterId) {
				return $q((resolve) => {
					const encounter = encountersContainer.encounters.find(e => e.encounterId === encounterId);
					SubjectPageService.loadEncounter(encounterId, encounter)
						.then(() => {
							updateModalities(encounter, pairEncounterModel.encounter);
							resolve(encounter);
						});
				});
			}

			function loadTabContents() {
				if ($scope.getNavigationOptions().activeTab === 'tab-timeline') {
					$scope.openTimeline();
				} else if ($scope.getNavigationOptions().activeTab === 'tab-events') {
					$scope.openEvents();
				}
			}

			$scope.transactionTypes = {};
			function loadEventsTypes() {
				const deferred = $q.defer();
				$translate([
					'transactions.type.enroll-with-duplicate-check', 'transactions.type.enroll',
					'transactions.type.identify', 'transactions.type.verify', 'transactions.type.verify-update', 'transactions.type.update', 'transactions.type.delete'
				]).then((translations) => {
					$scope.transactionTypes = {
					/* jshint sub:true */
						ENROLL_WITH_DUPLICATE_CHECK: translations['transactions.type.enroll-with-duplicate-check'],
						ENROLL: translations['transactions.type.enroll'],
						IDENTIFY: translations['transactions.type.identify'],
						VERIFY: translations['transactions.type.verify'],
						VERIFY_UPDATE: translations['transactions.type.verify-update'],
						UPDATE: translations['transactions.type.update'],
						DELETE: translations['transactions.type.delete']
					/* jshint sub:false */
					};
					deferred.resolve();
				});
				return deferred.promise;
			}

			$scope.subjectStatuses = {};
			function loadSubjectStatuses() {
				const deferred = $q.defer();
				$translate([
					'subject.status.ENROLLABLE', 'subject.status.TEMPORARY_ENROLLED', 'subject.status.ENROLLED', 'subject.status.DELETED'
				]).then((translations) => {
					$scope.subjectStatuses = {
					/* jshint sub:true */
						ENROLLABLE: translations['subject.status.ENROLLABLE'],
						TEMPORARY_ENROLLED: translations['subject.status.TEMPORARY_ENROLLED'],
						ENROLLED: translations['subject.status.ENROLLED'],
						DELETED: translations['subject.status.DELETED'],
					/* jshint sub:false */
					};
					deferred.resolve();
				});
				return deferred.promise;
			}

			function loadTransactionStatuses() {
				const deferred = $q.defer();
				$translate([
					'transactions.status.registered', 'transactions.status.in-progress',
					'transactions.status.adjudication-waiting', 'transactions.status.adjudication-in-progress', 'transactions.status.adjudication-conflict',
					'transactions.status.duplicate-found', 'transactions.status.not-matched', 'transactions.status.matched', 'transactions.status.rejected', 'transactions.status.ok'
				]).then((translations) => {
					$scope.transactionStatuses = {
					/* jshint sub:true */
						REGISTERED: translations['transactions.status.registered'],
						IN_PROGRESS: translations['transactions.status.in-progress'],
						ADJUDICATION_WAITING: translations['transactions.status.adjudication-waiting'],
						ADJUDICATION_IN_PROGRESS: translations['transactions.status.adjudication-in-progress'],
						ADJUDICATION_CONFLICT: translations['transactions.status.adjudication-conflict'],
						REJECTED: translations['transactions.status.rejected'],
						OK: translations['transactions.status.ok'],
						MATCHED: translations['transactions.status.matched'],
						NOT_MATCHED: translations['transactions.status.not-matched'],
						DUPLICATE_FOUND: translations['transactions.status.duplicate-found']
					/* jshint sub:false */
					};
					deferred.resolve();
				});
				return deferred.promise;
			}

			function loadStatuses() {
				return $q.all([
					loadSubjectStatuses(),
					loadEventsTypes(),
					loadTransactionStatuses()
				]);
			}

			function getTransactionCallIfTempStatus(encounterId) {
				if (!$scope.getEncountersTransactions().encounterIdToTransactionIdx.has(encounterId)
					&& $scope.getEncountersContainer().encounters.find(encounter => encounter.encounterId === encounterId).status === 'TEMPORARY_ENROLLED') {
					return SubjectPageService.getTransactions([encounterId]);
				}
				return $q(r => r(null));
			}

			function init() {
				$scope.initializing = true;
				loadStatuses().then(() => {
					SubjectPageService.getSubject(subjectId, galleryId)
						.then((subject) => {
							$scope.subject = subject;
							loadTabContents();
							SubjectPageService.getEncounters($scope.subject.encounterIds)
								.then((encounters) => {
									$scope.subjects.encounters = encounters;
									$scope.subjects.main.encounter = $scope.subjects.encounters[$scope.subjects.encounters.findIndex(e => e.encounterId === $scope.subjects.main.selectedEncounterId)];
									$q.all([
										loadBiometrics($scope.subjects, $scope.subjects.compare, $scope.subjects.main.selectedEncounterId),
										getTransactionCallIfTempStatus($scope.subjects.main.encounter.encounterId)
									]).then((results) => {
										const [encounter, transactions] = results;
										if (transactions) {
											$scope.getEncountersTransactions().transactions.push(transactions[0]);
											$scope.getEncountersTransactions().encounterIdToTransactionIdx.set(encounter.encounterId, $scope.transactions.main.transactions.length - 1);
										}
										$scope.subjects.main.encounter = encounter;
										$scope.subjects.loadedEncounters.add(encounter.encounterId);
									}).finally(() => {
										$scope.initializing = false;
									});
								});
						})
						.catch(tryLoadFromEncounter);
				});
			}

			async function tryLoadFromEncounter() {
				try {
					const resultSubject = await EncountersResource.get({ encounterId: $stateParams.encounterId }).$promise;
					redirectToSubject(resultSubject.subjectId, resultSubject.galleryId);
				} catch {
					handleFailedSubjectLoading();
				}
				blockUI.stop();
			}

			function redirectToSubject(newSujectId, newGalleryId) {
				$state.go('actions.subject.encounter', {
					encounterId: $stateParams.encounterId,
					subjectId: newSujectId,
					galleryId: newGalleryId,
					previousState: $stateParams.previousState
				}, {
					location: 'replace'
				});
			}

			function handleFailedSubjectLoading() {
				AlertService.show('subject.failed-to-load', { type: 'danger', msTimeout: 6000, translate: true });
				$window.history.back();
			}

			function changeStateUrl(encounterId) {
				$state.transitionTo('actions.subject.encounter', {
					subjectId,
					encounterId,
					galleryId
				}, { reload: false, notify: false, location: 'replace' });
			}

			function changeEncounter(encountersContainer, encounterModel, pairEncounterModel, encounterId, isSilentUpdate) {
				return $q((resolve) => {
					if (!isSilentUpdate) {
						changeStateUrl(encounterId);
					}

					if (!encountersContainer.loadedEncounters.has(encounterId)) {
						blockUI.start('app.loading');
						$q.all([
							loadBiometrics(encountersContainer, pairEncounterModel, encounterId),
							getTransactionCallIfTempStatus(encounterId)
						]).then((results) => {
							const [encounter, transactions] = results;
							if (transactions) {
								$scope.getEncountersTransactions().transactions.main.push(transactions[0]);
								$scope.getEncountersTransactions().encounterIdToTransactionIdx.set(encounter.encounterId, $scope.transactions.main.transactions.length - 1);
							}
							encounterModel.encounter = encounter;
							encountersContainer.loadedEncounters.add(encounter.encounterId);
							resolve();
						})
							.finally(blockUI.stop);
					} else {
						encounterModel.encounter = encountersContainer.encounters.find(e => e.encounterId === encounterId);
					}
				});
			}

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$scope.abisRequest = function () {
				AbisService.postRequest(AbisService.getDto());
			};

			$scope.updateSubject = function (event) {
				event.stopPropagation();

				if (!$scope.isUpdateValid()) {
					AlertService.show('subject.actions.update.invalid-status', { type: 'danger', msTimeout: 6000, translate: true });
					return;
				}

				if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
					AbisService.setGalleryId('actions.update', $scope.subjects.main.encounter.galleryId);
				}

				SubjectPageService.saveMasterForUpdateOperation($scope.getEncountersContainer().main.encounter)
					.then(() => {
						$state.go('actions.update', { subject: $scope.subjects.main.encounter, previousState: $state.current });
					});
			};

			function handlePostDelete(transaction) {
				NotificationsService.add(transaction.requestId);
				AlertService.show('encounter.actions.to-be-deleted');
				$state.go('actions.home');
			}

			function handleDeleteFail(error) {
				$translate('transactions.request-failed').then((translation) => {
					AlertService.show(`${translation}: ${error.data.message}`, { type: 'danger', msTimeout: 6000, translate: false });
				});
			}

			$scope.isDeleteValid = function (encounter = null) {
				function isStatusValidForDelete(encounterStatus) {
					return !['TEMPORARY_ENROLLED', 'DELETED'].some(status => status === encounterStatus);
				}
				if (encounter) {
					return isStatusValidForDelete(encounter.status);
				}
				return $scope.subjects.encounters.some(e => isStatusValidForDelete(e.status));
			};

			$scope.isUpdateValid = function (encounter = null) {
				function isDeleted(encounterStatus) {
					return encounterStatus === 'DELETED';
				}
				if (encounter) {
					return isDeleted(encounter.status);
				}
				return $scope.subjects.encounters.some(e => !isDeleted(e.status));
			};

			$scope.deleteSubject = function (event, encounter = $scope.subjects.main.encounter) {
				if (!$scope.isDeleteValid(encounter)) {
					AlertService.show('subject.actions.delete.invalid-status', { type: 'danger', msTimeout: 6000, translate: true });
					return;
				}

				event.stopPropagation();
				$translate('subject.actions.confirm-delete', $scope.subject).then((translation) => {
					$confirm({ text: translation }).then(() => {
						blockUI.start();

						const galleryId = AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST') && encounter.galleryId;

						SubjectPageService.deleteSubject(encounter.subjectId, galleryId)
							.then(handlePostDelete)
							.catch(handleDeleteFail)
							.finally(blockUI.stop);
					});
				});
			};

			$scope.deleteEncounter = function (event, encounter = $scope.subjects.main.encounter) {
				if (!$scope.isDeleteValid(encounter)) {
					AlertService.show('subject.actions.delete.invalid-status', { type: 'danger', msTimeout: 6000, translate: true });
					return;
				}

				event.stopPropagation();
				$translate('encounter.actions.confirm-delete', { encounterId: encounter.encounterId }).then((translation) => {
					$confirm({ text: translation }).then(() => {
						blockUI.start();
						const galleryId = AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST') && encounter.galleryId;
						SubjectPageService.deleteEncounter(encounter.subjectId, encounter.encounterId, galleryId)
							.then(handlePostDelete)
							.catch(handleDeleteFail)
							.finally(blockUI.stop);
					});
				});
			};

			$scope.exportEncounter = function (event, encounter = $scope.subjects.main.encounter) {
				event.stopPropagation();
				EncountersResource.export({ encounterId: encounter.encounterId }).$promise
					.then((value) => {
						FileSaver.saveAs(value.nist, `encounter_${encounter.encounterId}.nist`);
					});
			};

			function toFunctionPromise(url) {
				return () => $q(resolve => resolve(url));
			}

			$scope.showLeftFacePreview = function () {
				if (!$scope.getEncountersContainer().main.encounter.faces[0].unclickable) {
					previewModal('preview.face', toFunctionPromise($scope.getEncountersContainer().main.encounter.faces[0].imageUrl), 'face');
				}
			};
			$scope.showRightFacePreview = function () {
				if (!$scope.getEncountersContainer().compare.encounter.faces[0].unclickable) {
					previewModal('preview.face', toFunctionPromise($scope.getEncountersContainer().compare.encounter.faces[0].imageUrl), 'face');
				}
			};

			$scope.showLeftFingerPreview = function (index) {
				if (!$scope.getEncountersContainer().main.encounter.fingers[index].unclickable) {
					previewModal('preview.finger', toFunctionPromise($scope.getEncountersContainer().main.encounter.fingers[index].imageUrl), 'finger');
				}
			};
			$scope.showRightFingerPreview = function (index) {
				if (!$scope.getEncountersContainer().compare.encounter.fingers[index].unclickable) {
					previewModal('preview.finger', toFunctionPromise($scope.getEncountersContainer().compare.encounter.fingers[index].imageUrl), 'finger');
				}
			};

			$scope.showLeftIrisPreview = function (index) {
				if (!$scope.getEncountersContainer().main.encounter.irises[index].unclickable) {
					previewModal('preview.iris', toFunctionPromise($scope.getEncountersContainer().main.encounter.irises[index].imageUrl), 'iris');
				}
			};
			$scope.showRightIrisPreview = function (index) {
				if (!$scope.getEncountersContainer().compare.encounter.irises[index].unclickable) {
					previewModal('preview.iris', toFunctionPromise($scope.getEncountersContainer().compare.encounter.irises[index].imageUrl), 'iris');
				}
			};

			$scope.showLeftPalmPreview = function (index) {
				if (!$scope.getEncountersContainer().main.encounter.palms[index].unclickable) {
					previewModal('preview.palm', toFunctionPromise($scope.getEncountersContainer().main.encounter.palms[index].imageUrl), 'palm');
				}
			};
			$scope.showRightPalmPreview = function (index) {
				if (!$scope.getEncountersContainer().compare.encounter.palms[index].unclickable) {
					previewModal('preview.palm', toFunctionPromise($scope.getEncountersContainer().compare.encounter.palms[index].imageUrl), 'palm');
				}
			};

			$scope.showLeftSignaturePreview = function () {
				if (!$scope.getEncountersContainer().main.encounter.signature.unclickable) {
					previewModal('preview.signature', toFunctionPromise($scope.getEncountersContainer().main.encounter.signature.imageUrl), 'signature');
				}
			};
			$scope.showRightSignaturePreview = function () {
				if (!$scope.getEncountersContainer().compare.encounter.signature.unclickable) {
					previewModal('preview.signature', toFunctionPromise($scope.getEncountersContainer().compare.encounter.signature.imageUrl), 'signature');
				}
			};

			$scope.onClickCompareTab = function () {
				let nextEncounterIndex = $scope.getEncountersContainer().encounters.findIndex(e => e.encounterId === $scope.getEncountersContainer().main.selectedEncounterId) + 1;
				if (nextEncounterIndex >= $scope.getEncountersContainer().encounters.length) {
					nextEncounterIndex = 0;
				}
				$scope.getEncountersContainer().compare.encounter = $scope.getEncountersContainer().encounters[nextEncounterIndex];
				$scope.getEncountersContainer().compare.selectedEncounterId = $scope.getEncountersContainer().compare.encounter.encounterId;
				changeEncounter($scope.getEncountersContainer(), $scope.getEncountersContainer().compare, $scope.getEncountersContainer().main, $scope.getEncountersContainer().compare.selectedEncounterId, true);
			};

			function assignDefaultGroupMember() {
				function loadGroupEncounter() {
					$scope.group.encounters[groupIndex] = primarySubject;
					$scope.group.loadedEncounters.add($scope.group.main.selectedEncounterId);
					changeEncounter($scope.group, $scope.group.main, $scope.group.compare, $scope.group.main.selectedEncounterId, true);
				}

				function isPrimarySubjectLoaded() {
					return $scope.subjects.loadedEncounters.has(primarySubject.encounterId);
				}

				function loadPrimarySubject() {
					return $q((resolve) => {
						blockUI.start('app.loading');
						loadBiometrics($scope.subjects, $scope.subjects.compare, primarySubject.encounterId)
							.then((encounter) => {
								$scope.subjects.loadedEncounters.add(encounter.encounterId);
								resolve();
							})
							.finally(blockUI.stop);
					});
				}

				const groupIndex = $scope.group.encounters.findIndex(member => member.encounterId === $scope.subject.primaryEncounterId);
				$scope.group.main.selectedEncounterId = $scope.group.encounters[groupIndex].encounterId;

				const primarySubject = $scope.subjects.encounters.find(e => e.encounterId === $scope.subject.primaryEncounterId);
				if (!isPrimarySubjectLoaded()) {
					loadPrimarySubject()
						.then(loadGroupEncounter);
				}
				loadGroupEncounter();
			}

			$scope.onClickCompareGroupMembers = function () {
				if (Utils.isObjectEmpty($scope.group.main.encounter)) {
					assignDefaultGroupMember();
				}
				$scope.onClickCompareTab();
			};

			function previewModal(title, getImageUrl, modality) {
				if (!getImageUrl) { return; }
				var scope = $scope.$new(true);
				scope.title = title;
				scope.getImageUrl = getImageUrl;
				scope.modality = modality;
				$uibModal.open({
					template: require('../../views/modal/simple-preview-modal.html'),
					controller: 'SimplePreviewModalCtrl',
					size: 'dynamic',
					scope
				}).result.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			}

			$scope.verify = function () {
				if (!$scope.isVerifyAvailable()) {
					AlertService.show('subject.actions.verify.invalid-status', { type: 'danger', msTimeout: 6000, translate: true });
					return;
				}

				SubjectService.setId($scope.subjects.main.encounter.subjectId);
				SubjectService.setBiographicData($scope.subjects.main.encounter.biographicData);

				if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
					AbisService.setGalleryId('actions.verify', $scope.subjects.main.encounter.galleryId);
				}

				$state.go('actions.verify', {
					subject: $scope.subjects.main.encounter,
					subjectId: $scope.subjects.main.encounter.subjectId,
					previousState: $state.current,
				});
			};

			$scope.timelineConfig = {
				colorfulBadges: true,
				showId: true,
				showLegend: true,
			};

			function getSubjectIndex(transaction) {
				function isSubjectInHistory() {
					return $scope.historyEvents.length > 0
						&& Array.isArray($scope.historyEvents.some(eventGroup => eventGroup[0].subjectId === transaction.subjectId));
				}

				if (!isSubjectInHistory()) {
					$scope.timelineSeries.push([]);
					$scope.historyEvents.push([]);
					$scope.transactionLifecycleDates.push([]);
					return $scope.historyEvents.length - 1;
				}
				return $scope.historyEvents.findIndex(eventGroup => eventGroup[0].subjectId === transaction.subjectId);
			}

			function toTimeline(transaction, interpretedStatus) {
				return {
					requestId: transaction.requestId,
					encounterId: transaction.encounterId,
					changedAt: transaction.createdAt,
					status: transaction.status,
					interpretedStatus,
					processingUnitId: null,
					userName: transaction.userName,
					userId: transaction.userId,
					transactionType: transaction.type
				};
			}

			function isSubjectDeleted() {
				return !$scope.subject.active;
			}

			function handleTransaction(transaction) {
				function hasEnrolledEncounters() {
					return $scope.historyEvents[subjectIndex].some(transaction => ['ENROLL', 'ENROLL_WITH_DUPLICATE_CHECK'].includes(transaction.type));
				}

				function isTransactionInAdjudication() {
					const adjudicationStatuses = ['ADJUDICATION_WAITING', 'ADJUDICATION_IN_PROGRESS', 'ADJUDICATION_CONFLICT'];
					return adjudicationStatuses.includes(transaction.status);
				}

				function isLastTransaction() {
					return $scope.transactions.main.transactions.findIndex(t => t.requestId === transaction.requestId) === ($scope.transactions.main.transactions.length - 1);
				}

				function getInterpretationOfTransaction() {
					switch (transaction.type) {
					case 'UPDATE':
					case 'VERIFY_UPDATE':
						return 'subject.appeared-as.subject-updated';
					case 'DELETE':
						if (isSubjectDeleted() && isLastTransaction()) {
							return 'subject.appeared-as.subject-deleted';
						}
						return 'subject.appeared-as.encounter-deleted';
					case 'ENROLL':
					case 'ENROLL_WITH_DUPLICATE_CHECK':
						if (isTransactionInAdjudication()) {
							return 'subject.appeared-as.in-adjudication';
						} else if (hasEnrolledEncounters()) {
							return 'subject.appeared-as.duplicate-found';
						}
						return 'subject.appeared-as.subject-enrolled';
					default:
						return '';
					}
				}

				function setTimelineProperties() {
					$scope.historyEvents[subjectIndex].push(toTimeline(transaction, getInterpretationOfTransaction()));
				}

				const subjectIndex = getSubjectIndex(transaction);
				setTimelineProperties();
			}

			function isHistoryLoaded() {
				return $scope.historyEvents.length > 0;
			}

			function getSeries() {
				$scope.timelineSeries = $scope.transactions.main.transactions.map(transaction => transaction.subjectId);
			}

			function getEventColumns() {
				$scope.eventColumns = [
					{
						icon: 'fa-id-badge',
						header: 'transactions.report.encounter-id',
					}
				];
			}

			const newOptions = () => ({
				isLoading: false,
				maxPagesReached: false,
				loadingDisabled: false
			});

			$scope.timelineOptions = newOptions();
			$scope.openTimeline = function () {
				$scope.setTab('tab-timeline');
				if (!isHistoryLoaded()) {
					$scope.loadMoreTimeline();
				}
			};

			function handleTimelineResponse(response) {
				const [transactions, options] = response;
				const oldTransactionLength = $scope.transactions.main.transactions.length;
				transactions.forEach((transaction, index) => {
					if (!$scope.transactions.main.encounterIdToTransactionIdx.has(transaction.requestId)) {
						$scope.transactions.main.transactions.push(transaction);
						$scope.transactions.main.encounterIdToTransactionIdx.set(transaction.requestId, oldTransactionLength + index);
					}

					handleTransaction(transaction);
				});
				$scope.timelineOptions.loadingDisabled = !options.isLoadingAvailable;
				$scope.timelineOptions.maxPagesReached = options.maxPagesReached;
				getSeries();
				getEventColumns();
			}

			$scope.reloadTimeline = function () {
				if ($scope.timelineOptions.isLoading) return;

				$scope.transactions.main = {
					transactions: [],
					encounterIdToTransactionIdx: new Map()
				};
				$scope.timelineSeries = [];
				$scope.historyEvents = [];
				$scope.transactionLifecycleDates = [];
				$scope.timelineOptions.isLoading = true;
				SubjectPageService.reloadTimeline($scope.subject.encounterIds)
					.then(handleTimelineResponse)
					.finally(() => { $scope.timelineOptions.isLoading = false; });
			};

			$scope.loadMoreTimeline = function () {
				$scope.timelineOptions.isLoading = true;
				if (!SubjectPageService.isDataSaved('transactions')) {
					SubjectPageService.getTimelineElements($scope.subject.encounterIds)
						.then(handleTimelineResponse)
						.finally(() => { $scope.timelineOptions.isLoading = false; });
				} else {
					handleTimelineResponse(SubjectPageService.getSavedTimeline());
					$scope.timelineOptions.isLoading = false;
				}
			};

			$scope.isHighlighted = function (encounter) {
				return encounter && encounter.encounterId && $scope.subject.primaryEncounterId === encounter.encounterId;
			};

			$scope.getColorFromStatus = function (encounter) {
				if (!encounter) return;
				switch (encounter.status) {
				case 'REJECTED':
				case 'DELETED':
					return 'bg-danger';
				default:
					return 'bg-primary';
				}
			};

			$scope.getSubjectBadge = function (encounter) {
				if ($scope.isHighlighted(encounter)) {
					return 'bg-warning';
				}
				$scope.getColorFromStatus();
			};

			function saveCacheableData() {
				const transactionsEmpty = $scope.transactions.main.transactions.length === 0;
				const eventsEmpty = $scope.events.length === 0;
				SubjectPageService.setSaveFetchedResults(
					true,
					!transactionsEmpty ? $scope.transactions.main.transactions : null,
					!eventsEmpty ? $scope.events : null
				);
			}

			$scope.getTransactionPayload = function (requestId) {
				return {
					transactionID: requestId,
					previousState: $state.current
				};
			};

			$scope.goToPage = function (requestId) {
				function getLink() {
					return 'actions.transaction';
				}

				function onClick() {
					$scope.saveViewBeforeRedirection();
				}

				return {
					getLink,
					getPayload: () => $scope.getTransactionPayload(requestId),
					onClick
				};
			};

			$scope.getGroupMemberPayload = function (encounter) {
				return { encounterId: encounter.encounterId, subjectId: encounter.subjectId, galleryId };
			};

			$scope.$on('$destroy', () => {
				SubjectPageService.invalidateNavigationOptions();
				SubjectPageService.invalidateFetchedResults();
			});

			$scope.groupOptions = newOptions();
			$scope.openGroupTab = function () {
				$scope.setTab('tab-group');
				if (!$scope.getNavigationOptions().activeSubTab.includes('subtab-group')) {
					$scope.setSubTab('subtab-group-list');
				}

				const isGroupLoaded = $scope.group.encounters.length !== 0;
				if (!isGroupLoaded && $scope.subject.groupId) {
					loadGroupFirstTime();
				}
			};

			function assignNewGroupMembers(transactions, encounters) {
				encounters.forEach((encounter, index) => {
					const isEncounterSaved = $scope.getEncountersTransactions().encounterIdToTransactionIdx.has(encounter.encounterId);
					if (!isEncounterSaved) {
						$scope.getEncountersContainer().encounters.push(encounter);

						if (transactions[index]) {
							const transactionsLength = $scope.getEncountersTransactions().transactions.push(transactions[index]);
							$scope.getEncountersTransactions().encounterIdToTransactionIdx.set(encounter.encounterId, transactionsLength - 1);
						}
					}
				});
			}

			function handleGroupOptions(options) {
				$scope.groupOptions.loadingDisabled = !options.isLoadingAvailable;
				$scope.groupOptions.maxPagesReached = options.maxPagesReached;
			}

			function getMoreGroupItems() {
				return $q((resolve, reject) => {
					SubjectPageService.getGroupElements($scope.subject.groupId, galleryId)
						.then((results) => {
							const [encounters, transactions, options] = results;
							handleGroupOptions(options);
							resolve([encounters, transactions]);
						})
						.catch(() => {
							reject();
						})
						.finally(() => {
							reject();
						});
				});
			}

			$scope.loadMoreGroupItems = function () {
				return $q((resolve, reject) => {
					$scope.groupOptions.isLoading = true;
					getMoreGroupItems()
						.then((results) => {
							const [encounters, transactions] = results;
							assignNewGroupMembers(transactions, encounters);
							resolve();
						})
						.catch(() => {
							AlertService.show('subjects.group.loading-failed');
							reject();
						})
						.finally(() => {
							$scope.groupOptions.isLoading = false;
						});
				});
			};

			function getPrimarySubject() {
				return SubjectPageService.getGroupElement($scope.subject.subjectId, galleryId);
			}

			function loadGroupFirstTime() {
				$scope.groupOptions.isLoading = true;
				$q.all([
					getMoreGroupItems(),
					getPrimarySubject()
				])
					.then((responses) => {
						const [[encounters, transactions], [primaryEncounter, primaryTransaction]] = responses;
						transactions.unshift(primaryTransaction[0]);
						encounters.unshift(primaryEncounter[0]);
						assignNewGroupMembers(transactions, encounters);
					})
					.catch(() => {
						AlertService.show('subjects.group.loading-failed');
					})
					.finally(() => {
						$scope.groupOptions.isLoading = false;
					});
			}

			function goToCompareInternal(encounterId) {
				function assignCompareToPressedEncounter() {
					$scope.getEncountersContainer().compare.encounter = $scope.getEncountersContainer().encounters.find(e => e.encounterId === encounterId);
					$scope.getEncountersContainer().compare.selectedEncounterId = $scope.getEncountersContainer().compare.encounter.encounterId;
				}

				assignCompareToPressedEncounter();
				changeEncounter(
					$scope.getEncountersContainer(), $scope.getEncountersContainer().compare,
					$scope.getEncountersContainer().main, $scope.getEncountersContainer().compare.selectedEncounterId, true
				);
			}

			$scope.goToSubjectCompare = function (encounterId) {
				goToCompareInternal(encounterId);
				$scope.setSubTab('subtab-subject-compare');
			};

			$scope.goToGroupCompare = function (encounterId) {
				assignDefaultGroupMember();
				goToCompareInternal(encounterId);
				$scope.setSubTab('subtab-group-compare');
			};

			$scope.goToSubject = function (encounterId) {
				synchronizeModel($scope.getEncountersContainer().main, encounterId);
				changeEncounter($scope.getEncountersContainer(), $scope.getEncountersContainer().main, $scope.getEncountersContainer().compare, encounterId, false);
				$scope.setSubTab('subtab-subject');
			};

			$scope.getSubjectStatus = function (encounterId = $scope.subject.primaryEncounterId) {
				if ($scope.getEncountersContainer().encounters.length === 0) return;

				const subject = $scope.getEncountersContainer().encounters.find(e => e.encounterId === encounterId);
				return subject.status === 'TEMPORARY_ENROLLED' && $scope.getEncountersTransactions().encounterIdToTransactionIdx.size > 0
					? $scope.cnv.toReadable($scope.transactionStatuses, $scope.getEncountersTransactions().transactions[$scope.getEncountersTransactions().encounterIdToTransactionIdx.get(subject.encounterId)].status)
					: $scope.cnv.toReadable($scope.subjectStatuses, subject.status);
			};

			$scope.getPrimaryEncounter = function () {
				return $scope.subjects.encounters.find(e => e.encounterId === $scope.subject.primaryEncounterId);
			};

			$scope.goBack = function () {
				if (!$stateParams.previousState) {
					$state.go('actions.search');
				} else {
					$window.history.back();
				}
			};

			$scope.isChevronVisible = function () {
				if ($scope.getNavigationOptions().activeSubTab.includes('compare')) {
					return $scope.getEncountersContainer().encounters.length > 2;
				}
				return $scope.getEncountersContainer().encounters.length > 1;
			};

			function isEventsLoaded() {
				return $scope.events.length > 0;
			}

			$scope.eventsOptions = newOptions();
			const EVENTS_SORT_KEY = ':subject-events:sort';
			$scope.eventsSort = SortHelper.create('createdAt', true);
			$scope.openEvents = function () {
				$scope.setTab('tab-events');
				if (!isEventsLoaded()) {
					$scope.loadMoreEvents();
				}
			};

			function addExternalStatus(transactionsWhereProbe, transactionsWhereHit) {
				transactionsWhereProbe.forEach((transaction) => { transaction.externalStatus = probeTypeStatusToExternal(transaction); });
				transactionsWhereHit.forEach((transaction) => { transaction.externalStatus = hitTypeStatusToExternal(transaction); });
			}

			function handleEventsResponse(response) {
				const [[transactionsWhereProbe, transactionsWhereHit], options] = response;
				addExternalStatus(transactionsWhereProbe, transactionsWhereHit);
				$scope.events = [...$scope.events, ...Utils.mergeSortedArrays(transactionsWhereProbe, transactionsWhereHit, $scope.eventsSort.field, $scope.eventsSort.reverse)];
				$scope.eventsOptions.isLoading = false;
				$scope.eventsOptions.loadingDisabled = !options.isLoadingAvailable;
				$scope.eventsOptions.maxPagesReached = options.maxPagesReached;
				store.set(EVENTS_SORT_KEY, $scope.eventsSort);
			}

			function loadSortIfExists() {
				if (store.get(EVENTS_SORT_KEY)) {
					$scope.eventsSort = SortHelper.create(store.get(EVENTS_SORT_KEY).field, true, store.get(EVENTS_SORT_KEY).reverse);
				}
			}

			$scope.loadMoreEvents = function () {
				$scope.eventsOptions.isLoading = true;
				loadSortIfExists();

				if (!SubjectPageService.isDataSaved('events')) {
					SubjectPageService.getEventsElements($scope.subject.subjectId, $scope.eventsSort)
						.then(handleEventsResponse)
						.finally(() => { $scope.eventsOptions.isLoading = false; });
				} else {
					const [events, options] = SubjectPageService.getSavedEvents();
					$scope.events = events;
					$scope.eventsOptions.isLoading = false;
					$scope.eventsOptions.loadingDisabled = !options.isLoadingAvailable;
					$scope.eventsOptions.maxPagesReached = options.maxPagesReached;
					store.set(EVENTS_SORT_KEY, $scope.eventsSort);
				}
			};

			$scope.reloadEvents = function () {
				if ($scope.eventsOptions.isLoading) return;

				$scope.events = [];
				$scope.eventsOptions.isLoading = true;
				SubjectPageService.reloadEvents($scope.subject.subjectId, $scope.eventsSort)
					.then(handleEventsResponse)
					.finally(() => { $scope.eventsOptions.isLoading = false; });
			};

			function hitTypeStatusToExternal(transaction) {
				const mapper = {
					IDENTIFY: {
						MATCHED: 'subject.appeared-as.identified',
						NOT_MATCHED: 'subject.appeared-as.identified',
						REJECTED: 'subject.appeared-as.rejected'
					},
					ENROLL_WITH_DUPLICATE_CHECK: {
						ADJUDICATION_WAITING: 'subject.appeared-as.potential-duplicate',
						ADJUDICATION_IN_PROGRESS: 'subject.appeared-as.potential-duplicate',
						ADJUDICATION_CONFLICT: 'subject.appeared-as.potential-duplicate',
						OK: 'subject.appeared-as.not-hit',
						DUPLICATE_FOUND: 'subject.appeared-as.duplicate',
						REJECTED: 'subject.appeared-as.rejected'
					},
					VERIFY_UPDATE: {
						MATCHED: 'subject.appeared-as.verified-and-updated',
						NOT_MATCHED: 'subject.appeared-as.not-matched',
						REJECTED: 'subject.appeared-as.rejected'
					}
				};
				return mapper[transaction.type][transaction.status];
			}

			function probeTypeStatusToExternal(transaction) {
				switch (transaction.type) {
				case 'VERIFY': {
					const mapper = {
						MATCHED: 'subject.appeared-as.verified',
						NOT_MATCHED: 'subject.appeared-as.not-matched',
						REJECTED: 'subject.appeared-as.rejected'
					};
					return mapper[transaction.status];
				}
				case 'ENROLL': {
					const mapper = {
						OK: 'subject.appeared-as.enrolled',
						REJECTED: 'subject.appeared-as.rejected'
					};
					return mapper[transaction.status];
				}
				case 'ENROLL_WITH_DUPLICATE_CHECK': {
					const mapper = {
						OK: 'subject.appeared-as.enrolled',
						DUPLICATE_FOUND: 'subject.appeared-as.enrolled-as-duplicate',
						ADJUDICATION_WAITING: 'subject.appeared-as.in-adjudication',
						ADJUDICATION_IN_PROGRESS: 'subject.appeared-as.in-adjudication',
						ADJUDICATION_CONFLICT: 'subject.appeared-as.in-adjudication',
						REJECTED: 'subject.appeared-as.rejected'
					};
					return mapper[transaction.status];
				}
				case 'UPDATE': {
					const mapper = {
						OK: 'subject.appeared-as.updated',
						REJECTED: 'subject.appeared-as.rejected'
					};
					return mapper[transaction.status];
				}
				case 'DELETE': {
					if (isSubjectDeleted() && transaction.encounterId === null) {
						return 'subject.appeared-as.subject-deleted';
					}
					if (transaction.status === 'REJECTED') {
						return 'subject.appeared-as.rejected';
					}
					return 'subject.appeared-as.encounter-deleted';
				}
				default:
					Error(`Unknown transaction: ${transaction.type}`);
				}
			}

			$scope.sortBy = function (field) {
				if ($scope.eventsOptions.isLoading) return;
				$scope.eventsSort.sort(field);
				$scope.reloadEvents();
			};

			$scope.saveViewBeforeRedirection = function () {
				SubjectPageService.setSaveNavigationOptions(true);
				saveCacheableData();
			};

			$scope.isVerifyAvailable = function () {
				return $scope.subjects.main.encounter.status !== 'DELETED';
			};

			function isLastEncounterInTheList(container, model) {
				const lastElementEncounterId = container.encounters[container.encounters.length - 1].encounterId;
				return lastElementEncounterId === model.selectedEncounterId;
			}

			function isFirstEncounterInTheList(container, model) {
				const firstElementEncounterId = container.encounters[0].encounterId;
				return firstElementEncounterId === model.selectedEncounterId;
			}

			$scope.nextGroupEncounter = async function (model, pairModel, change, isSilentUpdate) {
				if ($scope.groupOptions.isLoading) return;

				const isFetchAvailable = !$scope.groupOptions.loadingDisabled;
				if (isFetchNeeded(model, pairModel) && isFetchAvailable) {
					await $scope.loadMoreGroupItems();
				}

				change(isSilentUpdate);
			};

			function nextGroupEncounter(modelName, pairModelName, change) {
				return async (isSilentUpdate) => {
					if ($scope.groupOptions.isLoading) return;

					const model = $scope.getEncountersContainer()[modelName];
					const pairModel = $scope.getEncountersContainer()[pairModelName];
					const isFetchAvailable = !$scope.groupOptions.loadingDisabled;
					if (isFetchNeeded(model, pairModel) && isFetchAvailable) {
						try {
							blockUI.start('app.loading');
							await $scope.loadMoreGroupItems();
							blockUI.stop();

							alertIfMaxedOut();
						} catch {
							blockUI.stop();
							return;
						}
					}

					const isNextOutOfBounds = ($scope.getEncountersContainer().encounters.findIndex(encounter => encounter.encounterId === model.selectedEncounterId) + 1) === $scope.getEncountersContainer().encounters.length;
					if (isNextOutOfBounds) {
						AlertService.show('subject.group.no-group-members-left', { type: 'info', msTimeout: 6000, translate: true });
						return;
					}
					change(isSilentUpdate);
				};
			}

			function alertIfMaxedOut() {
				if (!$scope.groupOptions.isLoading && $scope.groupOptions.maxPagesReached) {
					AlertService.show('subject.group.limit-reached', { type: 'info', msTimeout: 6000, translate: true });
				}
			}

			$scope.nextGroupEncounter = nextGroupEncounter('main', 'compare', $scope.nextEncounter);
			$scope.nextGroupCompareEncounter = nextGroupEncounter('compare', 'main', $scope.nextComparison);

			$scope.isNextGroupEncounterAvailable = function (model, pairModel) {
				const container = $scope.getEncountersContainer();
				const isNextLast = (container.encounters.findIndex(encounter => encounter.encounterId === model.selectedEncounterId) + 1)
					=== (container.encounters.length - 1);
				const isNextEncounterNotAvailable = (isLastEncounterInTheList(container, model) || (isNextLast && isLastEncounterInTheList(container, pairModel)))
					&& $scope.groupOptions.loadingDisabled;
				return !isNextEncounterNotAvailable;
			};

			$scope.isPrevGroupEncounterAvailable = function (model, pairModel) {
				const container = $scope.getEncountersContainer();
				const isNextFirst = (container.encounters.findIndex(encounter => encounter.encounterId === model.selectedEncounterId) - 1) === 0;
				const isPrevEncounterNotAvailable = isFirstEncounterInTheList(container, model) || (isNextFirst && isFirstEncounterInTheList(container, pairModel));
				return !isPrevEncounterNotAvailable;
			};

			function isFetchNeeded(model, pairModel) {
				const container = $scope.getEncountersContainer();
				const isNextLast = (container.encounters.findIndex(encounter => encounter.encounterId === model.selectedEncounterId) + 1)
					=== (container.encounters.length - 1);
				return isLastEncounterInTheList(container, model)
					|| isNextLast && isLastEncounterInTheList(container, pairModel);
			}

			init();
		}]);
