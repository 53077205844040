import angular from 'angular';

angular.module('neurotecAbisWebClientApp').directive('thresholdForm', () => ({
	template: require('../../../views/directives/admin/threshold-form.html'),
	restrict: 'E',
	replace: true,
	scope: {
		title: '@',
		model: '=',
		high: '=?',
		options: '=',
		groupId: '@',
		showFar: '=?'
	},
	controllerAs: 'formCtrl',
	bindToController: true,
	link: (scope, _element, attrs, controller) => {
		controller.showFar = Object.hasOwnProperty.call(attrs, 'showFar');
	},
	controller: ['$scope', 'Utils', function ($scope, Utils) {
		const self = this;
		self.high = self.high === undefined ? null : self.high;
		self.thresholdForm = {};

		self.initModel = function () {
			$scope.$watch(angular.bind(self, function () {
				return this.thresholdForm.model.$viewValue;
			}), (newValue) => {
				if (newValue !== null) {
					self.resizeElement('model');
				}
			});

			// $scope.$watch(angular.bind(self, function () {
			// 	return this.percentage;
			// }), (newValue, oldValue) => {
			// 	if (newValue === oldValue || oldValue === undefined) return;
			// 	if (newValue) {
			// 		toggleOldModel = self.model;
			// 		toggleOldHigh = self.high;
			// 		self.model = 0;
			// 		self.high = 1;
			// 		self.internalOptions.min = 0;
			// 		self.internalOptions.max = 1;
			// 	} else {
			// 		self.model = toggleOldModel;
			// 		self.high = toggleOldHigh;
			// 		toggleOldModel = null;
			// 		toggleOldHigh = null;
			// 		self.initOptions();
			// 	}
			// 	self.thresholdForm.model.$setDirty();
			// 	self.thresholdForm.high.$setDirty();
			// });
		};

		self.initHigh = function () {
			$scope.$watch(angular.bind(self, function () {
				return this.thresholdForm.high.$viewValue;
			}), (newValue) => {
				if (newValue !== null) {
					self.resizeElement('high');
				}
			});
		};

		self.internalOptions = {};

		$scope.$watch(angular.bind(self, function () {
			return this.options;
		}), () => {
			self.initOptions();
		});

		self.initOptions = function () {
			const DEFAULT_OPTIONS = {
				min: 0,
				max: 100000,
				isRequired: false,
				isRangeValid: (a, b) => a < b,
			};

			self.internalOptions = self.options
				? Object.assign({}, DEFAULT_OPTIONS, self.options)
				: DEFAULT_OPTIONS;
		};

		self.getId = function (suffix) {
			return Utils.toKebabCase(`${self.groupId}-${self.title}-${suffix}`);
		};

		self.resizeElement = function (suffix) {
			const element = document.getElementById(self.getId(suffix));
			if (element) {
				const value = self.thresholdForm[suffix].$viewValue ? self.thresholdForm[suffix].$viewValue.toString() : self.internalOptions.max.toString();
				element.style.width = `${value.length + 2}ch`;
			}
		};
	}]
}));
