import angular from 'angular';
import FileSaver from 'file-saver';

angular.module('neurotecAbisWebClientApp')
	.directive('comparisonExport', () => ({
		template: require('../../views/directives/comparison-export.html'),
		restrict: 'E',
		replace: true,
		scope: {
			exportTitle: '@',
			exportProbeTitle: '@',
			exportHitTitle: '@',
			exportViewTitle: '@',
			getProbe: '&',
			getHit: '&',
			viewImgName: '@',
			modalityImgName: '@',
		},
		controller: ['$scope', '$filter', 'ExportUtils', function ($scope, $filter, ExportUtils) {
			$scope.downloadView = function () {
				const canvas = document.getElementById('canvas');
				canvas.toBlob((data) => {
					const date = $filter('date')(new Date(), 'yyyyMMdd_HHmmss');
					FileSaver.saveAs(data, `${$scope.viewImgName}${date}.png`);
				});
			};

			$scope.downloadModality = function (getModality, modalityName) {
				ExportUtils.downloadModality(getModality, `${modalityName}_${$scope.modalityImgName}`);
			};
		}]
	}));
