/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
// import 'babel-polyfill';
import 'jquery';
import angular from 'angular';
import 'yuki-createjs/lib/easeljs-0.8.2.combined'; // https://github.com/CreateJS/EaselJS/issues/713
import 'bootstrap';
import 'angular-auto-focus';
import 'angular-resource';
import 'angular-ui-router';
import 'moment';
import 'moment-timezone';
import 'angular-animate';
import 'angular-translate';
import 'angular-translate-handler-log';
import 'angular-block-ui';
import 'angular-ui-bootstrap';
import 'angularjs-dropdown-multiselect';
import 'angularjs-slider';
import 'bitset.js';
import 'ng-infinite-scroll';
import 'file-saver';
import 'angular-confirm';
import 'angular-hotkeys';
import 'angular-local-storage';
import 'angular-pageslide-directive';
import 'angular-storage'; // a0-angular-storage
import 'angular-loading-bar';
import 'angular-toastr';
import 'chart.js';
import 'angular-chart.js';
import 'oi.select';
import 'daterangepicker';
import { QuadTree, Box, Point, Circle } from 'js-quadtree';
import uuidv4 from 'uuid/v4';

import '../node_modules/angular-bootstrap-toggle/src/angular-bootstrap-toggle';
import './styles/main.less';
/* eslint-disable import/no-duplicates */
import './reactToAngular';
// eslint-disable-next-line no-duplicate-imports
import RegisterReactComponents from './reactToAngular';
import i18n from './config/localization.ts';
/* eslint-enable import/no-duplicates */

const MODULE_NAME = 'neurotecAbisWebClientApp';

angular.module(MODULE_NAME, [
	'angular-loading-bar',
	'LocalStorageModule',
	'angular-confirm',
	'angular-storage',
	'angularjs-dropdown-multiselect',
	'blockUI',
	'cfp.hotkeys',
	'infinite-scroll',
	'mp.autoFocus',
	'ngResource',
	'ngAnimate',
	'pageslide-directive',
	'pascalprecht.translate',
	'toastr',
	'ui.bootstrap',
	'ui.router',
	'chart.js',
	'oi.select',
	'ui.toggle',
	'rzSlider'
])
	.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
		$urlRouterProvider.otherwise((_, $location) => {
			const currentState = $location.$$url;
			if (currentState && currentState.includes('admin')) {
				return '/admin/dashboard';
			}
			return '/actions/home';
		});

		$stateProvider
			.state('login', {
				url: '/login',
				template: require('./views/login.html'),
				controller: 'LoginCtrl'
			})
			.state('password', {
				abstract: true,
				url: '/password',
				template: '<div><div ui-view></div><sticky-login-footer></sticky-login-footer></div>'
			})
			.state('password.reset', {
				url: '/reset',
				controller: 'passwordResetCtrl',
				template: require('./views/passwordReset.html'),
			})
			.state('password.confirm', {
				url: '/confirm?email&token',
				controller: 'passwordConfirmCtrl',
				template: require('./views/passwordConfirm.html'),
			})
			.state('actions', {
				abstract: true,
				url: '/actions',
				data: {
					needAuth: true
				},
				template: require('./views/base.html')
			})
			.state('actions.home', {
				url: '/home',
				component: 'homeView',
				params: {
					alert: null
				},
				template: '<home-view></home-view>'
			})
			.state('actions.enroll', {
				url: '/enroll',
				controller: 'BiometricsCtrl',
				params: {
					subject: null,
					previousState: null
				},
				data: {
					authorities: ['PERMISSION_BIOMETRIC_ENROLL_WITH_DUPLICATE_CHECK', 'PERMISSION_BIOMETRIC_ENROLL']
				},
				template: require('./views/actions/enroll.html')
			})
			.state('actions.identify', {
				url: '/identify',
				controller: 'BiometricsCtrl',
				params: {
					previousState: null
				},
				data: {
					authorities: ['PERMISSION_BIOMETRIC_IDENTIFY']
				},
				template: require('./views/actions/identify.html')
			})
			.state('actions.verify', {
				url: '/verify/:subjectId',
				controller: 'BiometricsCtrl',
				params: {
					subject: null,
					subjectId: null,
					previousState: null,
				},
				data: {
					authorities: ['PERMISSION_BIOMETRIC_VERIFY']
				},
				template: require('./views/actions/verify.html')
			})
			.state('actions.scan', {
				url: '/scan-document',
				controller: 'ScanCtrl',
				params: {
					transactionID: null,
					previousState: null
				},
				data: {
					authorities: ['PERMISSION_BIOMETRIC_VERIFY']
				},
				template: require('./views/actions/scan-document.html')
			})
			.state('actions.update', {
				url: '/update',
				controller: 'BiometricsCtrl',
				data: {
					authorities: ['PERMISSION_BIOMETRIC_UPDATE']
				},
				params: {
					subject: null,
					previousState: null
				},
				template: require('./views/actions/update.html')
			})
			.state('actions.search', {
				url: '/subjects',
				controller: 'SubjectsCtrl',
				data: {
					authorities: ['PERMISSION_ENCOUNTER_LIST']
				},
				template: require('./views/actions/subjects.html')
			})
			.state('actions.transactions', {
				url: '/transactions',
				controller: 'TransactionsCtrl',
				data: {
					authorities: ['PERMISSION_TRANSACTION_LIST']
				},
				template: require('./views/actions/transactions.html')
			})
			.state('actions.transaction.hit', {
				url: '/hit/:hitID',
				controller: 'TransactionCtrl',
				params: {
					transactionID: null,
					hitID: null
				},
				data: {
					authorities: ['PERMISSION_TRANSACTION_LIST']
				},
				template: require('./views/actions/transaction.html')
			})
			.state('actions.transaction', {
				url: '/transactions/:transactionID',
				controller: 'TransactionCtrl',
				params: {
					previousState: null
				},
				data: {
					authorities: ['PERMISSION_TRANSACTION_LIST']
				},
				template: require('./views/actions/transaction.html')
			})
			.state('actions.adjudication', {
				abstract: true,
				url: '/adjudication',
				data: {
					authorities: ['PERMISSION_ADJUDICATION_VIEW']
				},
				template: '<ui-view/>'
			})
			.state('actions.adjudication.cases', {
				url: '/cases',
				controller: 'CasesCtrl',
				template: require('./views/actions/cases.html')
			})
			.state('actions.deduplication', {
				url: '/deduplication',
				controller: 'DeduplicationCtrl as PageCtrl',
				template: require('./views/actions/deduplication.html')
			})
			.state('actions.adjudication.case', {
				url: '/cases/:caseId',
				controller: 'CaseCtrl',
				params: {
					caseId: null,
					nextCase: false,
					previousState: null
				},
				template: require('./views/actions/case.html')
			})
			.state('actions.adjudication.caseHit', {
				url: '/cases/:caseId/hits/:hitId',
				controller: 'CaseCtrl',
				params: {
					caseId: null,
					hitId: null
				},
				template: require('./views/actions/case.html')
			})
			.state('actions.subject', {
				url: '/subjects/:subjectId',
				controller: 'SubjectCtrl',
				template: require('./views/actions/subject.html'),
				params: {
					encounterId: null,
					subjectId: null,
					galleryId: null,
					previousState: null
				},
				data: {
					authorities: ['PERMISSION_SUBJECT_VIEW']
				}
			})
			.state('actions.subject.encounter', {
				url: '/encounters/:encounterId?:galleryId',
				controller: 'SubjectCtrl',
				template: require('./views/actions/subject.html'),
				data: {
					authorities: ['PERMISSION_SUBJECT_VIEW']
				}
			})
			.state('actions.capture', {
				abstract: true,
				url: '/capture',
				template: '<ui-view/>',
				data: {
					authorities: [
						'PERMISSION_BIOMETRIC_ENROLL', 'PERMISSION_BIOMETRIC_ENROLL_WITH_DUPLICATE_CHECK',
						'PERMISSION_BIOMETRIC_IDENTIFY', 'PERMISSION_BIOMETRIC_VERIFY', 'PERMISSION_BIOMETRIC_VERIFY_UPDATE'
					]
				}
			})
			.state('actions.capture.face', {
				url: '/face',
				controller: 'CaptureFaceCtrl',
				params: {
					previousState: null
				},
				template: require('./views/actions/capture/face.html')
			})
			.state('actions.capture.fingers', {
				url: '/fingers',
				controller: 'CaptureFingersCtrl',
				params: {
					previousState: null
				},
				template: require('./views/actions/capture/fingers.html')
			})
			.state('actions.capture.irises', {
				url: '/irises',
				controller: 'CaptureIrisesCtrl',
				params: {
					previousState: null
				},
				template: require('./views/actions/capture/irises.html')
			})
			.state('actions.capture.palms', {
				url: '/palms',
				controller: 'CapturePalmsCtrl',
				params: {
					previousState: null
				},
				template: require('./views/actions/capture/palms.html')
			})
			.state('actions.capture.signature', {
				url: '/signature',
				controller: 'CaptureSignatureCtrl',
				params: {
					previousState: null
				},
				template: require('./views/actions/capture/signature.html')
			})
			.state('actions.latent-editor', {
				url: '/latent-editor',
				controller: 'LatentEditorCtrl',
				data: {
					authorities: ['PERMISSION_OTHER_LATENT_PRINT']
				},
				template: require('./views/actions/latent-editor.html')
			})
			.state('admin', {
				abstract: true,
				url: '/admin',
				data: {
					needAuth: true,
					authorityMatch: /PERMISSION_ADMINISTRATION.*/g
				},
				template: require('./views/admin-base.html')
			})
			.state('admin.dashboard', {
				url: '/dashboard',
				template: require('./views/admin/dashboard.html'),
				controller: 'DashboardCtrl',
				data: {
					needAuth: true
				}
			})
			.state('admin.tasks', {
				url: '/tasks',
				template: require('./views/admin/tasks.html'),
				controller: 'TasksCtrl',
				data: {
					authorities: ['PERMISSION_ADMINISTRATION_TASKS']
				}
			})
			.state('admin.configuration', {
				abstract: true,
				url: '/configuration',
				template: '<ui-view/>'
			})
			.state('admin.configuration.parameters', {
				url: '/parameters',
				template: require('./views/admin/configuration/parameters.html'),
				controller: 'ConfParametersCtrl',
				data: {
					authorities: ['PERMISSION_ADMINISTRATION_PARAMETERS']
				}
			})
			.state('admin.configuration.biographic', {
				url: '/biographic',
				template: require('./views/admin/configuration/biographic.html'),
				controller: 'BiographicCtrl',
				data: {
					authorities: ['PERMISSION_ADMINISTRATION_BIOGRAPHIC_FIELDS']
				}
			})
			.state('admin.configuration.roles', {
				url: '/roles',
				template: require('./views/admin/configuration/roles.html'),
				controller: 'ConfRolesCtrl',
				data: {
					authorities: ['PERMISSION_ADMINISTRATION_ROLES']
				}
			})
			.state('admin.configuration.users', {
				url: '/users',
				template: require('./views/admin/configuration/users.html'),
				controller: 'ConfUsersCtrl',
				data: {
					authorities: ['PERMISSION_USER_MODIFY']
				}
			})
			.state('admin.configuration.galleries', {
				url: '/galleries',
				template: require('./views/admin/configuration/galleries.html'),
				controller: 'ConfGalleriesCtrl',
				data: {
					authorities: ['PERMISSION_GALLERY_LIST']
				}
			})
			.state('admin.configuration.device-server', {
				url: '/device-server',
				template: require('./views/admin/configuration/device-server.html'),
				controller: 'DeviceServerCtrl',
				data: {
					authorities: ['PERMISSION_ADMINISTRATION_PARAMETERS']
				}
			})
			.state('admin.metrics', {
				abstract: true,
				url: '/metrics',
				template: '<ui-view/>',
				data: {
					authorities: ['PERMISSION_ADMINISTRATION_METRICS']
				}
			})
			.state('admin.metrics.deduplication', {
				url: '/deduplication',
				controller: 'StatisticsCtrl',
				params: {
					tab: null
				},
				template: require('./views/admin/deduplication-metrics.html')
			})
			.state('admin.metrics.operations', {
				url: '/operations',
				controller: 'StatisticsCtrl',
				params: {
					tab: null
				},
				template: require('./views/admin/biometrics-metrics.html')
			})
			.state('admin.metrics.subjects', {
				url: '/subjects',
				controller: 'StatisticsCtrl',
				params: {
					tab: null
				},
				template: require('./views/admin/subjects-metrics.html')
			})
			.state('admin.metrics.matching', {
				url: '/matching',
				controller: 'StatisticsCtrl',
				params: {
					tab: null
				},
				template: require('./views/admin/matching-metrics.html')
			})
			.state('admin.metrics.transactions', {
				url: '/transactions',
				controller: 'MetricsCtrl',
				template: require('./views/admin/metrics.html'),
				data: {
					target: 'biometric'
				}
			})
			.state('admin.metrics.hardware', {
				url: '/hardware',
				controller: 'MetricsCtrl',
				template: require('./views/admin/metrics.html'),
				data: {
					target: 'hardware'
				}
			})
			.state('admin.logs', {
				url: '/logs',
				controller: 'LogsCtrl',
				template: require('./views/admin/logs.html'),
				data: {
					authorities: ['PERMISSION_ADMINISTRATION_LOGS']
				}
			})
			.state('admin.licensing', {
				url: '/licensing',
				template: require('./views/admin/licensing.html'),
				controller: 'LicensingCtrl',
				data: {
					authorities: ['PERMISSION_ADMINISTRATION_LICENSING']
				}
			})
			.state('admin.tools', {
				url: '/tools',
				controller: 'ToolsCtrl',
				template: require('./views/admin/tools.html'),
				data: {
					authorities: [
						'PERMISSION_ADMINISTRATION_DEVELOPMENT',
						'PERMISSION_ADMINISTRATION_TOOLS_CLEAR',
						'PERMISSION_ADMINISTRATION_TOOLS_RETROSPECTIVE_MODIFICATION',
						'PERMISSION_ADMINISTRATION_TOOLS_NFIQ'
					]
				}
			})
			.state('admin.locales-editor', {
				url: '/locales-editor',
				controller: 'LocalesEditorCtrl',
				template: require('./views/admin/locales-editor.html'),
				data: {
					authorities: ['PERMISSION_ADMINISTRATION_DEVELOPMENT']
				}
			});
	}])
	.run(['$rootScope', '$state', 'AuthDataHolder', 'NotificationsService', 'LoginRedirectService', function ($rootScope, $state, AuthDataHolder, NotificationsService, LoginRedirectService) {
		// QuadtreeJS module binding to the $rootScope
		$rootScope.jsQuadtree = {};
		$rootScope.jsQuadtree.QuadTree = QuadTree;
		$rootScope.jsQuadtree.Box = Box;
		$rootScope.jsQuadtree.Point = Point;
		$rootScope.jsQuadtree.Circle = Circle;
		//

		// UUID function binding to the $rootscope
		$rootScope.uuidv4 = uuidv4;
		//

		$rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState) => {
			if (toState.data != null && toState.data.needAuth) {
				if (AuthDataHolder.isAuthenticated()) {
					const missAnyAuthority = toState.data.authorities && !AuthDataHolder.hasAnyAuthority(toState.data.authorities);
					const missAuthorityMatch = toState.data.authorityMatch && !AuthDataHolder.matchAnyAuthority(toState.data.authorityMatch);
					if (missAnyAuthority || missAuthorityMatch) {
						$state.transitionTo('actions.home');
						event.preventDefault();
						return;
					}
				} else {
					if (fromState.name === '' || toState.name !== fromState.name) {
						LoginRedirectService.setRedirectParams(toState, toParams);
					}
					NotificationsService.invalidate();
					$state.transitionTo('login');
					event.preventDefault();
					return;
				}
			} else if (toState.name === 'login' && AuthDataHolder.isAuthenticated()) {
				NotificationsService.invalidate();
				$state.transitionTo('actions.home');
				event.preventDefault();
				return;
			}
			$state.previous = fromState.name;
		});
	}])
	.config(['$provide', '$httpProvider', function ($provide, $httpProvider) {
		$provide.factory('AuthInterceptor', ['$q', '$injector', 'AuthDataHolder', 'BaseUrlService', 'blockUI',
			function ($q, $injector, AuthDataHolder, BaseUrlService, blockUI) {
				return {
					request(config) {
						const isDeviceServerCall = config.url.indexOf(BaseUrlService.getCapturerServiceUrl()) === 0;
						if (!isDeviceServerCall && AuthDataHolder.isAuthenticated()) {
							/* jshint sub:true */
							config.headers.Authorization = `${AuthDataHolder.getTokenType()} ${AuthDataHolder.getAccessToken()}`;
							/* jshint sub:false */
						}
						return config;
					},
					responseError(rejection) {
						if (rejection.config.url.indexOf(BaseUrlService.getBaseManagementUrl()) === 0 ||
							rejection.config.url.indexOf(BaseUrlService.getBaseOAuthUrl()) === 0) {
							var $state = $injector.get('$state');
							var $rootScope = $injector.get('$rootScope');
							if ($state.current.name !== 'login' && $rootScope.logoutReason == null) {
								if (rejection.status === 401) {
									$rootScope.logoutReason = 'LOST_SESSION';
									AuthDataHolder.invalidate();
									$state.transitionTo('login');
									// eslint-disable-next-line no-console
									console.warn(
										'LOST_SESSION, status: %i, statusText: %s, URL: %s, Authorization: %s, response: %s, rejection: %o',
										rejection.status, rejection.statusText, rejection.config.url, rejection.config.headers.Authorization, JSON.stringify(rejection.data), rejection
									);
								} else if (rejection.status === 403) {
									$rootScope.logoutReason = 'LOST_AUTHORITIES';
									AuthDataHolder.invalidate();
									$state.transitionTo('login');
									// eslint-disable-next-line no-console
									console.warn(
										'LOST_AUTHORITIES, status: %i, statusText: %s, URL: %s, Authorization: %s, response: %s, rejection: %o',
										rejection.status, rejection.statusText, rejection.config.url, rejection.config.headers.Authorization, JSON.stringify(rejection.data), rejection
									);
								} else if (rejection.status === -1 || rejection.status === 502 || rejection.status === 504) {
									blockUI.start({
										message: 'message.overlay.connectionLost',
										buttonMessage: 'message.overlay.refresh',
										callback: () => {
											blockUI.stop();
											blockUI.state().buttonMessage = '';
											blockUI.state().callback = () => { };
											window.location.reload();
										}
									});
								}
							}
						}
						return $q.reject(rejection);
					}
				};
			}]);
		$httpProvider.interceptors.push('AuthInterceptor');
	}])
	.config(['$locationProvider', function ($locationProvider) {
		$locationProvider.hashPrefix('');
		$locationProvider.html5Mode({
			enabled: true,
			requireBase: false
		});
	}])
	.config(['$qProvider', function ($qProvider) {
		$qProvider.errorOnUnhandledRejections(false);
	}])
	.run(['SessionMonitor', function (SessionMonitor) {
		SessionMonitor.init();
	}])
	.config(['localStorageServiceProvider', function (localStorageServiceProvider) {
		localStorageServiceProvider
			.setPrefix('neurotecAbisWebClientApp')
			.setStorageType('sessionStorage');
	}])
	.run(['LanguagesService', function (LanguagesService) {
		const language = navigator.language || navigator.Language || LanguagesService.getDefaultLanguage(); // eslint-disable-line no-undef
		if (language.includes('zh')) {
			LanguagesService.setDefaultLanguage('zh_hans');
		} else if (LanguagesService.isLanguageAvalable(language)) {
			LanguagesService.setDefaultLanguage(language);
		}
	}])
	.config(['$translateProvider', function ($translateProvider) {
		$translateProvider.useSanitizeValueStrategy('escape');
		$translateProvider.useLoader('customTranslationLoader', {
			map: {
				en_US: '/resources/locale-en_US.json',
				ru_RU: '/resources/locale-ru_RU.json',
				ua_UA: '/resources/locale-ua_UA.json',
				zh_hans: '/resources/locale-zh_hans.json',
			}
		});
		$translateProvider.preferredLanguage('en_US');
		$translateProvider.fallbackLanguage('en_US');
		$translateProvider.useMissingTranslationHandlerLog();
	}])
	.run(['$translate', 'LanguagesService', function ($translate, LanguagesService) {
		$translate.use(LanguagesService.getPreferredLanguage());
		i18n.changeLanguage(LanguagesService.getPreferredLanguage());
	}])
	.config(['blockUIConfig', function (blockUIConfig) {
		blockUIConfig.autoBlock = false;
		blockUIConfig.message = 'message.overlay.loading';
		blockUIConfig.resetOnException = false;
		blockUIConfig.template = require('./views/3rd-party/blockUI.html');
	}])
	.run(['$rootScope', 'SettingsService', 'StatusService', function ($rootScope, SettingsService, StatusService) {
		$rootScope.$watch(() => SettingsService.getCapturerServiceAutoUpdate(), (newValue) => {
			if (newValue === true) {
				StatusService.initialize();
			} else {
				StatusService.stop();
			}
		});
	}])
	.run(['NotificationsService', function (NotificationsService) {
		NotificationsService.start();
	}])
	.run(['StoreService', function (StoreService) {
		StoreService.watch();
	}])
	.run(['NotificationsService', 'AuthDataHolder', function (NotificationsService, AuthDataHolder) {
		if (!AuthDataHolder.isAuthenticated()) {
			NotificationsService.invalidate();
		}
	}])
	.config(['toastrConfig', function (toastrConfig) {
		angular.extend(toastrConfig, {
			positionClass: 'toast-top-center',
			timeOut: 5000
		});
	}]);

// Requires
require('./scripts/controllers/biometrics.js');
require('./scripts/controllers/capture/face.js');
require('./scripts/controllers/capture/fingers.js');
require('./scripts/controllers/capture/irises.js');
require('./scripts/controllers/capture/palms.js');
require('./scripts/controllers/capture/signature.js');
require('./scripts/controllers/case.js');
require('./scripts/controllers/cases.js');
require('./scripts/controllers/deduplication.js');
require('./scripts/controllers/face-comparison-modal.js');
require('./scripts/controllers/finger-comparison-modal.js');
require('./scripts/controllers/help-modal.js');
require('./scripts/controllers/home.js');
require('./scripts/controllers/latent-editor.js');
require('./scripts/controllers/login.js');
require('./scripts/controllers/palm-comparison-modal.js');
require('./scripts/controllers/passwordConfirmCtrl');
require('./scripts/controllers/passwordResetCtrl');
require('./scripts/controllers/reports.js');
require('./scripts/controllers/scan-document.js');
require('./scripts/controllers/session-expiring-modal.js');
require('./scripts/controllers/settings-modal.js');
require('./scripts/controllers/simple-comparison-modal.js');
require('./scripts/controllers/simple-preview-modal.js');
require('./scripts/controllers/subject-modal.js');
require('./scripts/controllers/subject.js');
require('./scripts/controllers/subjects.js');
require('./scripts/controllers/summary-modal.js');
require('./scripts/controllers/transaction.js');
require('./scripts/controllers/transactions.js');
require('./scripts/directives/admin/fpir-table.js');
require('./scripts/directives/capture-options.js');
require('./scripts/directives/clickables.js');
require('./scripts/directives/comments.js');
require('./scripts/directives/comparison-export.js');
require('./scripts/directives/custom-validation.js');
require('./scripts/directives/date-picker.js');
require('./scripts/directives/date-range-picker.js');
require('./scripts/directives/demographic-data.js');
require('./scripts/directives/face.js');
require('./scripts/directives/finger-drawing-options.js');
require('./scripts/directives/finger.js');
require('./scripts/directives/fingers.js');
require('./scripts/directives/header-notification.js');
require('./scripts/directives/horizontal-scroll-selection.js');
require('./scripts/directives/icons.js');
require('./scripts/directives/input-file.js');
require('./scripts/directives/iris.js');
require('./scripts/directives/irises.js');
require('./scripts/directives/latent-editor/choices.js');
require('./scripts/directives/latent-editor/editor-window.js');
require('./scripts/directives/latent-editor/image-adjustment.js');
require('./scripts/directives/latent-editor/minutia-adjustment.js');
require('./scripts/directives/latent-editor/open-file.js');
require('./scripts/directives/latent-editor/progress-path.js');
require('./scripts/directives/latent-editor/resolution.js');
require('./scripts/directives/latent-editor/zoom.js');
require('./scripts/directives/multiselect-buttons.js');
require('./scripts/directives/notifications.js');
require('./scripts/directives/null-if-min.js');
require('./scripts/directives/palm-drawing-options.js');
require('./scripts/directives/palm.js');
require('./scripts/directives/palms.js');
require('./scripts/directives/person.js');
require('./scripts/directives/score-panel.js');
require('./scripts/directives/scroll-top.js');
require('./scripts/directives/signature.js');
require('./scripts/directives/sticky-footer.js');
require('./scripts/directives/sticky-header.js');
require('./scripts/directives/timeline-vertical.js');
require('./scripts/directives/timeline.js');
require('./scripts/directives/username-typeahead.js');
require('./scripts/services/abis-resources.js');
require('./scripts/services/abis.js');
require('./scripts/services/adjudication-resources.js');
require('./scripts/services/adjudication.js');
require('./scripts/services/alert.js');
require('./scripts/services/auth/auth-data-holder.js');
require('./scripts/services/auth/auth-resource.js');
require('./scripts/services/auth/auth.js');
require('./scripts/services/auth/login-redirect-service.js');
require('./scripts/services/auth/oauth2-resource.js');
require('./scripts/services/auth/session-monitor.js');
require('./scripts/services/base-url.js');
require('./scripts/services/base64-tools.js');
require('./scripts/services/biographic-service.js');
require('./scripts/services/biometrics-service.js');
require('./scripts/services/capture-page-service.js');
require('./scripts/services/capturer-resources.js');
require('./scripts/services/capturer-service.js');
require('./scripts/services/conversion-utils.js');
require('./scripts/services/custom-translation-loader.js');
require('./scripts/services/datetimepicker.js');
require('./scripts/services/deduplication-service.js');
require('./scripts/services/device-server-parameters-resource.js');
require('./scripts/services/device-server-parameters-service.js');
require('./scripts/services/device-server-parameters-settings.js');
require('./scripts/services/device-server-parameters.js');
require('./scripts/services/document-subject-service.js');
require('./scripts/services/drawing-service.js');
require('./scripts/services/encounter-service.js');
require('./scripts/services/encounter-to-subject-converter.js');
require('./scripts/services/encounters-decisions-mapper.js');
require('./scripts/services/etag-service.js');
require('./scripts/services/examiners-resource.js');
require('./scripts/services/export-utils.js');
require('./scripts/services/face-view.js');
require('./scripts/services/far-filter.js');
require('./scripts/services/far-service.js');
require('./scripts/services/filter-data-holder');
require('./scripts/services/filter-utils.js');
require('./scripts/services/filters.js');
require('./scripts/services/finger-identifier.js');
require('./scripts/services/finger-scenarios.js');
require('./scripts/services/fpir-service.js');
require('./scripts/services/galleries-resource.js');
require('./scripts/services/galleries-service.js');
require('./scripts/services/hotkey-service.js');
require('./scripts/services/icao-status-service.js');
require('./scripts/services/image-service.js');
require('./scripts/services/input-utils.js');
require('./scripts/services/jaro-winkler-service.js');
require('./scripts/services/languages-service.js');
require('./scripts/services/latent-editor-resources.js');
require('./scripts/services/matching-tree-service.js');
require('./scripts/services/multiselect-module.js');
require('./scripts/services/naming-service.js');
require('./scripts/services/notifications-service.js');
require('./scripts/services/nudged-service.js');
require('./scripts/services/operation-parameters-templates.js');
require('./scripts/services/painter.js');
require('./scripts/services/palm-scenarios.js');
require('./scripts/services/parameters-resource.js');
require('./scripts/services/parameters-service.js');
require('./scripts/services/password-resource.js');
require('./scripts/services/password-validation.js');
require('./scripts/services/precision-filter.js');
require('./scripts/services/scenario-service.js');
require('./scripts/services/settings-service.js');
require('./scripts/services/sort-helper.js');
require('./scripts/services/statistics-resource.js');
require('./scripts/services/status.js');
require('./scripts/services/store-service.js');
require('./scripts/services/subject-page-data.js');
require('./scripts/services/subject-page-service.js');
require('./scripts/services/subject-service.js');
require('./scripts/services/timeline-tools.js');
require('./scripts/services/transaction-params.js');
require('./scripts/services/utils.js');


require('./scripts/controllers/admin/configuration/biographic.js');
require('./scripts/controllers/admin/configuration/device-server.js');
require('./scripts/controllers/admin/configuration/galleries.js');
require('./scripts/controllers/admin/configuration/parameters.js');
require('./scripts/controllers/admin/configuration/role-form.js');
require('./scripts/controllers/admin/configuration/roles.js');
require('./scripts/controllers/admin/configuration/user-form.js');
require('./scripts/controllers/admin/configuration/users.js');
require('./scripts/controllers/admin/dashboard.js');
require('./scripts/controllers/admin/licensing.js');
require('./scripts/controllers/admin/locales-editor.js');
require('./scripts/controllers/admin/logs.js');
require('./scripts/controllers/admin/metrics.js');
require('./scripts/controllers/admin/statistics.js');
require('./scripts/controllers/admin/tasks.js');
require('./scripts/controllers/admin/tools.js');
require('./scripts/directives/admin/alerts.js');
require('./scripts/directives/admin/circle-pager.js');
require('./scripts/directives/admin/graph.js');
require('./scripts/directives/admin/list-box.js');
require('./scripts/directives/admin/matching-mode.js');
require('./scripts/directives/admin/pwd-validation.js');
require('./scripts/directives/admin/service-status.js');
require('./scripts/directives/admin/sidebar.js');
require('./scripts/directives/admin/sticky-header-admin.js');
require('./scripts/directives/admin/threshold-form.js');
require('./scripts/services/admin/biographic-flags.js');
require('./scripts/services/admin/chart-options-service.js');
require('./scripts/services/admin/chart-options.js');
require('./scripts/services/admin/common-values.js');
require('./scripts/services/admin/poller.js');
require('./scripts/services/admin/rest.js');

/* eslint-disable */
///////////////////////
///////////////////////////////////////////////////////
/////////
/* eslint-enable */ /* eslint-disable prettier/prettier */

// eslint-disable-next-line new-cap
RegisterReactComponents(MODULE_NAME);

export default MODULE_NAME;
