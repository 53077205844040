import angular from 'angular';
import * as $ from 'jquery';

angular.module('neurotecAbisWebClientApp')
	.directive('dateRangePicker', () => ({
		template: require('../../views/directives/date-range-picker.html'),
		restrict: 'E',
		replace: true,
		scope: {
			inputId: '@',
			inputName: '@',
			rangeOptions: '=',
			rangeModel: '=',
			onChangeCallback: '&?onChange',
		},
		controllerAs: 'dateRangeCtrl',
		bindToController: true,
		controller: ['$scope', '$timeout', '$q', '$translate', function ($scope, $timeout, $q, $translate) {
			const self = this;

			function transformToModel(start, end, label) {
				return {
					range: [start, end],
					label
				};
			}

			self.sendValue = function (start, end, label) {
				self.rangeModel = transformToModel(start, end, label);
			};

			function getInput() {
				return $(`#${self.inputId}`);
			}

			function runAfterRender(func) {
				$timeout(func);
			}

			function onChange(start, end, label) {
				self.onChangeCallback(start, end, label);
				self.sendValue(start, end, label);
			}

			function isModelValid() {
				return self.rangeModel
					&& Object.hasOwnProperty.call(self.rangeModel, 'range')
					&& self.rangeModel.range
					&& Array.isArray(self.rangeModel.range)
					&& Object.hasOwnProperty.call(self.rangeModel, 'label');
			}

			function showValue(startDate, endDate) {
				getInput().val(startDate.format(dateFormat) + separator + endDate.format(dateFormat));
			}

			function initInternalWatchers() {
				getInput().on('apply.daterangepicker', (_ev, picker) => {
					showValue(picker.startDate, picker.endDate);
				});

				getInput().on('cancel.daterangepicker', () => {
					getInput().val('');
					self.sendValue(null, null, '');
				});
			}

			function initAttrbuteWatchers() {
				$scope.$watch(angular.bind(self, function () {
					return this.rangeModel;
				}), () => {
					if (!isModelValid() || isRangeEmpty()) {
						getInput().trigger('cancel.daterangepicker');
					} else {
						getInput().data('daterangepicker').setStartDate(self.rangeModel.range[0]);
						getInput().data('daterangepicker').setEndDate(self.rangeModel.range[1]);
						showValue(self.rangeModel.range[0], self.rangeModel.range[1]);
					}
				}, true);

				$scope.$watch(angular.bind(self, function () {
					return this.rangeOptions;
				}), (newValue) => {
					if (newValue) {
						resetElementAndOptions();
						initInternalWatchers();
					}
				}, true);
			}

			function isRangeEmpty() {
				return self.rangeModel.range.every(date => date === null);
			}

			let dateFormat = 'MM/DD/YYYY';
			let separator = ' - ';
			function initOptions() {
				if (self.dateRangeOptionsInternal && self.dateRangeOptionsInternal.locale) {
					dateFormat = self.dateRangeOptionsInternal.locale.format || dateFormat;
					separator = self.dateRangeOptionsInternal.locale.separator || separator;
				}
			}

			function resetElementAndOptions() {
				self.dateRangeOptionsInternal = Object.assign({}, self.dateRangeOptionsInternal, self.rangeOptions);
				getInput().daterangepicker(self.dateRangeOptionsInternal, onChange);

				if (self.dateRangeOptionsInternal
					&& self.dateRangeOptionsInternal.autoUpdateInput !== undefined
					&& !self.dateRangeOptionsInternal.autoUpdateInput) {
					getInput().trigger('cancel.daterangepicker');
				}

				initOptions();
			}

			function initRangePickerInternal() {
				if (self.dateRangeOptionsInternal) {
					resetElementAndOptions();
				}
				initAttrbuteWatchers();
			}

			function initModelDefaults() {
				self.sendValue(null, null, '');
			}


			self.dateRangeOptionsInternal = {};
			function loadDateRangeTranslations() {
				const deferred = $q.defer();
				$translate([
					'deduplication.date.ranges.last-hour',
					'deduplication.date.ranges.last-day',
					'deduplication.date.ranges.last-week',
					'deduplication.date.ranges.last-month',
					'deduplication.date.apply',
					'deduplication.date.clear',
					'deduplication.date.from',
					'deduplication.date.to',
					'deduplication.date.custom'
				]).then((translations) => {
					self.dateRangeOptionsInternal = {
						showDropdowns: true,
						timePicker: true,
						timePicker24Hour: true,
						ranges: {
							[translations['deduplication.date.ranges.last-hour']]: [moment().subtract(1, 'hours'), moment()],
							[translations['deduplication.date.ranges.last-day']]: [moment().subtract(1, 'days'), moment()],
							[translations['deduplication.date.ranges.last-week']]: [moment().subtract(1, 'weeks'), moment()],
							[translations['deduplication.date.ranges.last-month']]: [moment().subtract(1, 'month'), moment()]
						},
						locale: {
							format: 'YYYY-MM-DD HH:mm:ss',
							separator: ' - ',
							applyLabel: translations['deduplication.date.apply'],
							cancelLabel: translations['deduplication.date.clear'],
							fromLabel: translations['deduplication.date.from'],
							toLabel: translations['deduplication.date.to'],
							customRangeLabel: translations['deduplication.date.custom'],
							firstDay: 1
						},
						autoUpdateInput: false,
						alwaysShowCalendars: true,
						opens: 'left',
						drops: 'bottom',
					};
					deferred.resolve();
				});
				return deferred.promise;
			}

			self.initRangePicker = function () {
				loadDateRangeTranslations()
					.then(() => {
						if (isModelValid() && isRangeEmpty()) {
							initModelDefaults();
						}
						runAfterRender(initRangePickerInternal);
					});
			};

			self.focusInput = function () {
				getInput().trigger('click');
			};
		}]
	}));
