import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('SubjectModalCtrl', ['$scope', '$uibModalInstance', '$q', '$state', 'AbisService', 'ScenarioService', 'SubjectService', '$uibModal', 'Utils', 'BiometricsService', 'FingerIdentifier',
		function ($scope, $uibModalInstance, $q, $state, AbisService, ScenarioService, SubjectService, $uibModal, Utils, BiometricsService, FingerIdentifier) {
			function transformSubjectObject(subject) {
				const person = {
					encounterId: subject.encounterId,
					subjectId: subject.subjectId,
					biographicData: subject.biographicData,
					signature: {
						image: subject.signatureImage || null,
						imageUrl: subject.signatureImage ? URL.createObjectURL(Utils.b64toBlob(subject.signatureImage, 'image/x-ms-bmp')) : null
					},
					galleryId: subject.galleryId || ''
				};
				person.faces = subject.biometricData.faceImages ? subject.biometricData.faceImages.map(elem => ({
					matchingDetails: [[]],
					position: elem.position,
					image: elem.data || null,
					imageUrl: URL.createObjectURL(Utils.b64toBlob(elem.data, 'image/x-ms-bmp')) || null
				})) : [];
				person.irises = subject.biometricData.irisImages ? subject.biometricData.irisImages.map(elem => ({
					matchingDetails: [[]],
					position: elem.position,
					image: elem.data || null,
					imageUrl: URL.createObjectURL(Utils.b64toBlob(elem.data, 'image/x-ms-bmp')) || null
				})) : [];
				stripIrises(person);
				person.fingers = subject.biometricData.fingerImages ? subject.biometricData.fingerImages.map(elem => ({
					matchingDetails: [[]],
					position: elem.position,
					image: elem.data || null,
					imageUrl: URL.createObjectURL(Utils.b64toBlob(elem.data, 'image/x-ms-bmp')) || null
				})) : [];
				person.palms = subject.biometricData.palmImages ? subject.biometricData.palmImages.map(elem => ({
					matchingDetails: [[]],
					position: elem.position,
					image: elem.data || null,
					imageUrl: URL.createObjectURL(Utils.b64toBlob(elem.data, 'image/x-ms-bmp')) || null
				})) : [];
				return person;
			}
			$scope.person = transformSubjectObject($scope.subject);

			function stripIrises(person) {
				const MAX_IRISES = 2;
				person.irises = person.irises.slice(Math.max(person.irises.length - MAX_IRISES, 0));
			}

			function updateModalitiesOptions() {
				$scope.modalitiesOptions = BiometricsService.getModalitiesOptions($scope.person);
			}

			$scope.modalitiesOptions = {
				showIrises: false,
				showFingers: { common: false, slap: false, unknownCount: 0 },
				showPalms: false,
				showSignature: false
			};
			updateModalitiesOptions();

			$scope.importData = function () {
				ScenarioService.setSelected(null);
				const biometricData = {};
				biometricData.faceImages = $scope.subject.biometricData.faceImages || [];
				biometricData.irisImages = $scope.subject.biometricData.irisImages || [];
				biometricData.palmImages = [];
				biometricData.fingerImages = [];
				if ($scope.subject.biometricData.fingerImages) {
					$scope.subject.biometricData.fingerImages = $scope.subject.biometricData.fingerImages || [];
					const identifier = FingerIdentifier.start();
					for (let i = 0; i < $scope.subject.biometricData.fingerImages.length; i += 1) {
						const finger = $scope.subject.biometricData.fingerImages[i];
						finger.status = 'ok';
						finger.isImported = true;
						finger.id = identifier.getIdByPosition(finger.position);
						biometricData.fingerImages.push([finger]);
					}
				}
				if ($scope.subject.biometricData.palmImages) {
					$scope.subject.biometricData.palmImages = $scope.subject.biometricData.palmImages || [];
					for (let i = 0; i < $scope.subject.biometricData.palmImages.length; i += 1) {
						const palm = $scope.subject.biometricData.palmImages[i];
						palm.status = 'ok';
						palm.isImported = true;
						biometricData.palmImages.push([palm]);
					}
				}
				SubjectService.setBiometricData(biometricData);
				SubjectService.setSignature($scope.subject.signatureImage);
				if ($state.current.name !== 'actions.verify') {
					SubjectService.setBiographicData($scope.person.biographicData);
				}
				$uibModalInstance.close();
			};

			$scope.abisRequest = function () {
				const dto = AbisService.getDto();
				AbisService.postRequest(dto)
					.finally(() => {
						if ($scope.invalidate) $scope.invalidate();
					});
				$uibModalInstance.close();
			};

			$scope.cancel = function () {
				$uibModalInstance.close();
			};

			$scope.showFacePreview = function () {
				previewModal('preview.face', $scope.subject.biometricData.faceImages[0].data, 'face');
			};

			$scope.showFingerPreview = function (index) {
				previewModal('preview.finger', $scope.subject.biometricData.fingerImages[index].data, 'finger');
			};

			$scope.showIrisPreview = function (index) {
				previewModal('preview.iris', $scope.subject.biometricData.irisImages[index].data, 'iris');
			};

			$scope.showPalmPreview = function (index) {
				previewModal('preview.palm', $scope.subject.biometricData.palmImages[index].data, 'palm');
			};

			$scope.showSignaturePreview = function () {
				previewModal('preview.signature', $scope.subject.signatureImage, 'signature');
			};

			function toFunctionPromise(url) {
				return () => $q(resolve => resolve(url));
			}

			function previewModal(title, image, modality) {
				if (!image) { return; }
				image = `data:image/png;base64,${image}`;
				var scope = $scope.$new(true);
				scope.title = title;
				scope.getImageUrl = toFunctionPromise(image);
				scope.modality = modality;
				scope.noExport = true;
				$uibModal.open({
					template: require('../../views/modal/simple-preview-modal.html'),
					controller: 'SimplePreviewModalCtrl',
					size: 'dynamic',
					scope
				}).result.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			}
		}]);
