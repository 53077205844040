import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('iris', ['$sce', '$translate', 'PainterService', 'ParserResource', 'Utils', 'SettingsService', function ($sce, $translate, PainterService, ParserResource, Utils, SettingsService) {
		return {
			template: require('../../views/directives/iris.html'),
			restrict: 'E',
			replace: true,
			scope: {
				iris: '=',
				irisesScore: '=',
				position: '=',
				imgClick: '&',
				showMatchingDetails: '=',
				noHover: '='
			},
			link(scope, element, attrs) {
				scope.isBlur = SettingsService.getShouldBlurImages().iris;

				scope.imageOnError = function () {
					if (scope.iris && scope.iris.image) {
						if (scope.parsedIris !== null && scope.parsedIris !== undefined) {
							URL.revokeObjectURL(scope.parsedIris);
							scope.parsedIris = null;
						}
						ParserResource.parseImage({ data: scope.iris.image }, (result) => {
							scope.parsedIris = URL.createObjectURL(Utils.b64toBlob(result.data));
						});
					}
				};

				scope.hasImgClick = function () {
					function hasHitDetails() {
						return scope.iris.matchingDetails // if iris has hit to compare
							? (scope.iris.matchingDetails != null &&
								scope.iris.matchingDetails.length > 0 &&
								scope.showMatchingDetails)
							: true; // if it does not, accept it (will show simple preview only)
					}
					return angular.isDefined(attrs.imgClick) &&
							scope.iris != null &&
							scope.iris.unclickable !== true &&
							hasHitDetails();
				};

				scope.imgClickInternal = function () {
					if (scope.hasImgClick()) {
						scope.imgClick();
					}
				};

				scope.$watch(() => scope.iris, (newValue) => {
					if (newValue != null) {
						scope.overlay = newValue.isFailedToExtract;
						$translate(['subject.quality', `biometrics.irises.position.${scope.position}`]).then((t) => {
							scope.tooltipText = $sce.trustAsHtml(`${scope.position ? `<div>${t[`biometrics.irises.position.${scope.position}`]}` : ''}
							<br/>
							${newValue.quality ? `${t['subject.quality']}: ${newValue.quality}</div>` : ''}`);
						});

						scope.markIrisAsHovered = function () {
							if (newValue.matchingDetails && !scope.noHover) {
								PainterService.markIrisAsHovered(newValue.matchingDetails, newValue.index);
							}
						};
						scope.unhoverBiometric = function () {
							if (newValue.matchingDetails && !scope.noHover) {
								PainterService.unhoverBiometric();
							}
						};
						scope.shouldIrisHighlight = function () {
							return !scope.noHover ? PainterService.shouldIrisHighlight(newValue.index, scope.showMatchingDetails) : false;
						};
					}
				});
			}
		};
	}]);
