import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.directive('person', () => ({
		template: require('../../views/directives/person.html'),
		restrict: 'E',
		replace: true,
		scope: {
			person: '=',
			otherPerson: '=',
			modalitiesOptions: '=',
			showFaceComparison: '=',
			showIrisComparison: '=',
			showFingerComparison: '=',
			showPalmComparison: '=',
			showSignatureComparison: '=',
			encounters: '=',
			selectedEncounter: '=',
			encounterChanged: '&',
			encounterPrevChanged: '&',
			encounterNextChanged: '&',
			isCaseOwned: '=',
			hitsDecision: '='
		},
		link: (_scope, _element, attrs, ctrl) => {
			ctrl.showMatchingDetails = Object.hasOwnProperty.call(attrs, 'showMatchingDetails');
			ctrl.noHover = Object.hasOwnProperty.call(attrs, 'noHover');
			ctrl.showStatus = Object.hasOwnProperty.call(attrs, 'showStatus');
		},
		controllerAs: 'personCtrl',
		bindToController: true,
		controller: ['$scope', 'BiographicDataService', 'ParametersService', 'JaroWinkler', 'AuthDataHolder', 'GalleriesService', 'ConvertionUtils',
			function ($scope, BiographicDataService, ParametersService, JaroWinkler, AuthDataHolder, GalleriesService, ConvertionUtils) {
				const self = this;
				let personFingerWatcher = () => {};
				let otherFingerWatcher = () => {};
				let personPalmWatcher = () => {};
				let otherPalmWatcher = () => {};
				self.isCaseOwned = self.isCaseOwned !== undefined ? self.isCaseOwned : false;

				self.hasAnyAuthority = function (...args) {
					return AuthDataHolder.hasAnyAuthority(args);
				};

				self.unknownFingersOnly = false;
				self.unknownPalmsOnly = false;

				ParametersService.getParameter('mmabis.management.extraction-modalities').then((data) => {
					self.allowedModalities = data;
				});

				function initBioFields() {
					function removeHidden(fields) {
						return fields.filter(field => !BiographicDataService.hasKey(field.flags, 'HIDE_SUBJECT_FIELD'));
					}

					function setFieldsIntoGroups(fields, firstGroupSize) {
						const fieldsCopy = angular.copy(fields);
						self.fieldsGroup.push(fieldsCopy.slice(0, firstGroupSize));
						self.fieldsGroup.push(fieldsCopy.slice(firstGroupSize, fieldsCopy.length));
					}

					self.fields = [];
					self.fieldsGroup = [];
					BiographicDataService.get().then((result) => {
						const fields = removeHidden(result);
						self.biographicFieldsCount = result.length;
						self.fields = fields;
						const firstGroupSize = 5;
						setFieldsIntoGroups(fields, firstGroupSize);
					});
				}

				self.galleries = [];
				if (AuthDataHolder.hasAnyAuthority('PERMISSION_GALLERY_LIST')) {
					GalleriesService.getAvailableGalleries()
						.then((galleries) => {
							self.galleries = ConvertionUtils.markDuplicateValuesWithKeys(galleries);
							self.galleries.unshift(GalleriesService.getDefaultGallery());
						})
						.finally(initBioFields);
				} else {
					initBioFields();
				}

				function clearPersonWatchers() {
					personFingerWatcher();
					personPalmWatcher();
				}

				function clearOtherPersonWatchers() {
					otherFingerWatcher();
					otherPalmWatcher();
				}

				function assignPersonWatchers() {
					if (!self.person.fingers) return;
					personFingerWatcher = $scope.$watch(angular.bind(self, function () {
						return this.person.fingers;
					}), checkForAllFingers, true);

					if (!self.person.palms) return;
					personPalmWatcher = $scope.$watch(angular.bind(self, function () {
						return this.person.palms;
					}), checkForAllPalms, true);
				}

				function assignOtherPersonWatchers() {
					if (!self.otherPerson.fingers) return;
					otherFingerWatcher = $scope.$watch(angular.bind(self, function () {
						return this.otherPerson.fingers;
					}), checkForAllFingers, true);

					if (!self.otherPerson.fingers) return;
					otherPalmWatcher = $scope.$watch(angular.bind(self, function () {
						return this.otherPerson.palms;
					}), checkForAllPalms, true);
				}

				function unknownBiometricsCount(biometrics) {
					let count = 0;
					if (biometrics) {
						biometrics.forEach((biometric) => {
							if (biometric.position === 'UNKNOWN' || biometric.position === 'UNKNOWN_PALM') count += 1;
						});
					}
					return count;
				}

				function checkForAllFingers() {
					let viableFingersPerson = 0;
					let viableFingersOtherPerson = 0;

					if (self.person) {
						if (self.person.fingers) {
							viableFingersPerson = self.person.fingers.length - unknownBiometricsCount(self.person.fingers);
							if (self.person.fingers.length === 0 && (self.otherPerson && unknownBiometricsCount(self.otherPerson.fingers) === 0)) viableFingersPerson = 1;
						}
					}

					if (self.otherPerson) {
						if (self.otherPerson.fingers) {
							viableFingersOtherPerson = self.otherPerson.fingers.length - unknownBiometricsCount(self.otherPerson.fingers);
							if (self.otherPerson.fingers.length === 0 && (self.person && unknownBiometricsCount(self.person.fingers) === 0)) viableFingersOtherPerson = 1;
						}
					}
					self.unknownFingersOnly = (viableFingersPerson === 0 && viableFingersOtherPerson === 0);
				}

				function checkForAllPalms() {
					let viablePalmsPerson = 0;
					let viablePalmsOtherPerson = 0;

					if (self.person) {
						if (self.person.palms) {
							viablePalmsPerson = self.person.palms.length - unknownBiometricsCount(self.person.palms);
							if (self.person.palms.length === 0 && (self.otherPerson && unknownBiometricsCount(self.otherPerson.palms) === 0)) viablePalmsPerson = 1;
						}
					}

					if (self.otherPerson) {
						if (self.otherPerson.palms) {
							viablePalmsOtherPerson = self.otherPerson.palms.length - unknownBiometricsCount(self.otherPerson.palms);
							if (self.otherPerson.palms.length === 0 && (self.person && unknownBiometricsCount(self.person.palms) === 0)) viablePalmsOtherPerson = 1;
						}
					}
					self.unknownPalmsOnly = (viablePalmsPerson === 0 && viablePalmsOtherPerson === 0);
				}

				function personReferenceChange(newValue) {
					if (!newValue) return;
					self.subjectStatus = self.showStatus && self.person.status;
					clearPersonWatchers();
					assignPersonWatchers();
				}

				function otherPersonReferenceChange(newValue) {
					if (!newValue) return;
					clearOtherPersonWatchers();
					assignOtherPersonWatchers();
				}

				self.arrayIsEmpty = function (array) {
					return !(array && array.length > 0);
				};

				self.hasModalityParameter = (modalityName) => {
					if (!self.allowedModalities) {
						return false;
					}
					return self.allowedModalities.includes(modalityName.toLowerCase());
				};

				$scope.$watch(angular.bind(self, function () {
					return this.person;
				}), personReferenceChange, false);
				$scope.$watch(angular.bind(self, function () {
					return this.otherPerson;
				}), otherPersonReferenceChange, false);

				self.getClass = function (a, b) {
					if (a && b) {
						var distance = JaroWinkler.Distance(a, b);
						if (distance < 1.0) {
							if (distance > 0.85 && isNaN(a)) {
								return 'text-warning';
							}
							return 'text-danger';
						}
					}
				};
			}]
	}));
