import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('DashboardCtrl', ['$scope', '$timeout', '$q', '$translate', '$state', 'MonitoringService', 'AuthDataHolder', 'StatisticsResouce', 'FilterUtils', 'ChartOptionsService',
		function ($scope, $timeout, $q, $translate, $state, MonitoringService, AuthDataHolder, StatisticsResouce, FilterUtils, ChartOptionsService) {
			$scope.noStatisticsMessage = '';
			$scope.currentPage = 5;
			$scope.datePickerOptions = {
				timePicker: true,
				locale: {
					format: 'MM/DD/YYYY hh:mm A'
				}
			};

			$scope.managementServices = [];
			$scope.ipsServices = [];
			$scope.matchingServices = [];
			$scope.otherServices = [];
			function getMonitoringInfo() {
				return MonitoringService.getSilent({
					info: 'all'
				}, (value) => {
					$scope.managementServices = [];
					$scope.ipsServices = [];
					$scope.matchingServices = [];
					$scope.otherServices = [];
					$scope.monitoringInfo = value;
					Object.keys(value.abisUnits).forEach((unitKey) => {
						const managementServicePayload = value.abisUnits[unitKey].managementService;
						managementServicePayload.unit = unitKey;
						$scope.managementServices.push(managementServicePayload);

						$scope.ipsServices.push(...value.abisUnits[unitKey].imageProcessingServices.map((service) => { service.unit = unitKey; return service; }));
						$scope.matchingServices.push(...value.abisUnits[unitKey].matchingServices.map((service) => { service.unit = unitKey; return service; }));
					});
					$scope.otherServices.push(...value.otherServices);
				}, () => {
					$scope.managementServices = [];
					$scope.ipsServices = [];
					$scope.matchingServices = [];
					$scope.otherServices = [];
				}).$promise;
			}

			$scope.dateFieldsValid = function (prefix) {
				return FilterUtils.dateFieldsValid($scope.filterData, prefix, true);
			};

			$scope.translationTable = {};
			$scope.filterData = {
				createdAtLower: moment().add('-1', 'days'),
				createdAtUpper: moment(),
				rangeFrom: 50,
				rangeTo: 200,
				step: 10,
				types: ['ENROLL_WITH_DUPLICATE_CHECK', 'ENROLL'],
				status: ['OK', 'DUPLICATE_FOUND']
			};

			function handleCustomTooltip(tooltipId) {
				return function (tooltipModel) {
					// Tooltip Element
					const tooltipEl = document.getElementById(tooltipId);

					// Hide if no tooltip
					const tooltips = document.getElementsByClassName('chartjs-tooltip');
					for (let i = 0; i < tooltips.length; i += 1) {
						tooltips[i].style.opacity = 0;
					}
					if (!tooltipModel || !tooltipModel.opacity) {
						return;
					}

					// TODO: Set caret Position
					tooltipEl.classList.remove('top', 'bottom', 'no-transform');
					if (tooltipModel.yAlign) {
						tooltipEl.classList.add(tooltipModel.yAlign);
					} else {
						tooltipEl.classList.add('no-transform');
					}

					// Set Text
					if (tooltipModel.body) {
						const titleLines = tooltipModel.title || [];
						const bodyLines = tooltipModel.body.map(bodyItem => bodyItem.lines);

						let innerHtml = '<thead>';

						titleLines.forEach((title) => {
							innerHtml += `<tr><th>${title}</th></tr>`;
						});
						innerHtml += '</thead><tbody>';

						bodyLines.forEach((body, i) => {
							const colors = tooltipModel.labelColors[i];

							let style = `background:${colors.backgroundColor}`;
							style += `; border-color:${colors.borderColor}`;
							style += '; border-width: 2px';

							const span = `<span class="chartjs-tooltip-key" style="${style};"></span>`;
							innerHtml += `<tr><td>${span}${body}</td></tr>`;
						});
						innerHtml += '</tbody>';

						const tableRoot = tooltipEl.querySelector('table');
						tableRoot.innerHTML = innerHtml;
					}

					const chartElem = this._chart.canvas;
					const positionY = chartElem.parentElement.offsetTop; // Parent element is container for canvas to make it responsive
					const positionX = chartElem.parentElement.offsetLeft;

					// const panelHeader = document.getElementById(`${tooltipId.split('-')[0]}-header`);

					tooltipEl.style.opacity = 1;
					tooltipEl.style.position = 'absolute';
					tooltipEl.style.top = `${positionY + tooltipModel.caretY}px`;
					tooltipEl.style.left = `${positionX + tooltipModel.caretX}px`; // 15 - panel padding
					tooltipEl.style.pointerEvents = 'none';
					tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
					tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
					tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
					tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`;
				};
			}

			function findMaxDataValueAndReplace(options, data) {
				let max = Math.max(...data[0]);
				data.forEach((arr) => {
					const tempMax = Math.max(...arr);
					if (tempMax > max) {
						max = tempMax;
					}
				});

				options.scales.yAxes[0].ticks.max = max + 0.5;
			}

			function reloadAttributes() {
				$scope.graphsAttributes = [
					{
						statistic: $scope.deduplicationDist.adjudication,
						chartType: 'line',
						graphId: 'cases',
						graphTooltipId: 'cases-tooltip',
						link: 'admin.metrics.deduplication',
						tab: 'tab-adjudication',
						title: 'admin.metrics.deduplication.adjudication'
					},
					{
						statistic: $scope.operationsDist.operations,
						chartType: 'line',
						graphId: 'operations',
						graphTooltipId: 'operations-tooltip',
						link: 'admin.metrics.operations',
						tab: 'tab-operations',
						title: 'admin.metrics.operations.title'
					},
					{
						statistic: $scope.matchingDist.scores,
						chartType: 'line',
						graphId: 'scores',
						graphTooltipId: 'scores-tooltip',
						link: 'admin.metrics.matching',
						tab: 'tab-score',
						title: 'admin.metrics.matching'
					},
					{
						statistic: $scope.matchingDist.hits,
						chartType: 'bar',
						graphId: 'hits',
						graphTooltipId: 'hits-tooltip',
						link: 'admin.metrics.matching',
						tab: 'tab-hits',
						title: 'admin.metrics.matching.hits'
					}
				];
			}

			$scope.$watch('operationsDist.operations.data', (newValue) => {
				if (newValue && newValue.length > 0) {
					findMaxDataValueAndReplace($scope.operationsDist.operations.chartOptions, $scope.operationsDist.operations.data);
					reloadAttributes();
				}
			}, true);
			$scope.$watch('deduplicationDist.adjudication.data', (newValue) => {
				if (newValue && newValue.length > 0) {
					findMaxDataValueAndReplace($scope.deduplicationDist.adjudication.chartOptions, $scope.deduplicationDist.adjudication.data);
					reloadAttributes();
				}
			}, true);
			$scope.$watch('matchingDist.scores.data', (newValue) => {
				if (newValue && newValue.length > 0) {
					findMaxDataValueAndReplace($scope.matchingDist.scores.chartOptions, $scope.matchingDist.scores.data);
					reloadAttributes();
				}
			}, true);
			$scope.$watch('matchingDist.hits.data', (newValue) => {
				if (newValue && newValue.length > 0) {
					findMaxDataValueAndReplace($scope.matchingDist.hits.chartOptions, $scope.matchingDist.hits.data);
					reloadAttributes();
				}
			}, true);

			$scope.subjectsDist = {
				subjects: {
					loading: false,
					error: null
				},
				filter: {
					types: ['ENROLL_WITH_DUPLICATE_CHECK', 'ENROLL'],
					statuses: ['OK', 'DUPLICATE_FOUND']
				},
			};
			$scope.operationsDist = {
				operations: {
					loading: false,
					error: null,
				},
				filter: {
					createdAtUpper: null,
					createdAtLower: null,
					types: ['ENROLL_WITH_DUPLICATE_CHECK']
				},
			};
			$scope.deduplicationDist = {
				adjudication: {
					loading: false,
					error: null,
					filter: {
						createdAtUpper: null,
						createdAtLower: null,
						adjudicated: true
					}
				},
				deduplication: {
					loading: false,
					error: null,
					filter: {}
				}
			};
			$scope.matchingDist = {
				filter: {
					createdAtUpper: null,
					createdAtLower: null,
				},
				scores: {
					datasetOverride: [
						{
							label: '',
							borderWidth: 1,
							type: 'bar',
						}
					],
					data: [],
					loading: false,
					error: null,
				},
				hits: {
					datasetOverride: [
						{
							label: '',
							borderWidth: 1,
							type: 'bar',
						}
					],
					data: [],
					loading: false,
					error: null,
				},
			};

			const {
				chartOptions, termsOptions, barOptions, pieOptions, matchingBarOptions
			} = ChartOptionsService.getDashboardOptions($scope.subjectsDist.subjects);

			$scope.subjectsDist.subjects.chartOptions = pieOptions();
			$scope.operationsDist.operations.chartOptions = chartOptions();
			$scope.deduplicationDist.adjudication.chartOptions = chartOptions();
			$scope.deduplicationDist.deduplication.chartOptions = barOptions();
			$scope.matchingDist.scores.chartOptions = termsOptions();
			$scope.matchingDist.hits.chartOptions = matchingBarOptions();

			// Handle non translated values eg. numbers, unknown strings.
			function handleDefault(str) {
				str = str.replace(/\.0/g, '').replace(/-/g, ' - ').replace(/_/g, ' ');
				return str;
			}

			function parseDate(dateStr) {
				if (dateStr.length > 24) {
					const from = dateStr.substring(0, 24);
					return moment(from, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
				}
				return moment(dateStr, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
			}

			function parseHitsData(obj, data) {
				data.buckets = data.buckets ? data.buckets : {};
				obj.data = [];
				obj.labels = [];

				obj.data = [Object.values(data.buckets).map(bucket => bucket.count)];
				obj.count = obj.data.reduce((acc, current) => acc + current, 0);
				obj.labels = Object.keys(data.buckets).map(label => handleDefault(label));
			}

			function parseScoresData(obj, dataArr) {
				// data.buckets = data.buckets || {};
				obj.data = [];
				obj.labels = [];
				obj.count = 0;

				dataArr.forEach((data) => {
					obj.data.push(Object.values(data.buckets).map(bucket => bucket.count));
				});
				obj.labels = Object.keys(dataArr[0].buckets).map(label => [handleDefault(label)]);
				obj.count += dataArr.map(container => container.count).reduce((count, current) => count + current, 0);
			}

			function parseData(obj, data, translations) {
				data.buckets = data.buckets || {};
				obj.data = [];
				obj.labels = [];
				const translationsKeys = Object.keys(translations);
				const tempData = translationsKeys.reduce((acc, current) => { acc[current] = []; return acc; }, {});
				let count = 0;
				Object.keys(data.buckets).forEach((dateObj) => {
					if (data.buckets[dateObj].buckets) {
						count += data.buckets[dateObj].count;
						translationsKeys.forEach((status) => {
							if (data.buckets[dateObj].buckets[status]) {
								tempData[status].push(data.buckets[dateObj].buckets[status].count);
							} else {
								tempData[status].push(0);
							}
						});
					} else {
						translationsKeys.forEach(translationsKey => tempData[translationsKey].push(0));
					}
				});

				obj.data = count > 0 ? Object.values(tempData) : translationsKeys.map(() => []);
				obj.labels = count > 0 ? Object.keys(data.buckets).map(label => parseDate(label)) : translationsKeys.map(() => '');
				obj.count = count;
			}

			function parsePieData(obj, result, labels) {
				const toPercent = v => Math.max(v / result.count * 100, 1);
				const labelEntries = Object.entries(labels);
				obj.data = labelEntries.map(() => 0);
				obj.dataRaw = labelEntries.map(() => 0);
				obj.labels = labelEntries.map(label => label[1]);
				obj.count = result.count;

				labelEntries.forEach(([labelKey], index) => {
					obj.data[index] = result.buckets[labelKey] ? toPercent(result.buckets[labelKey].count) : 0;
					obj.dataRaw[index] = result.buckets[labelKey] ? result.buckets[labelKey].count : 0;
				});
			}

			function parseBarData(obj, result, labels) {
				const labelEntries = Object.entries(labels);
				obj.data = labelEntries.map(() => 0);
				obj.labels = labelEntries.map(label => label[1]);
				obj.count = result.count;

				labelEntries.forEach(([labelKey], index) => {
					obj.data[index] = result.buckets[labelKey] ? result.buckets[labelKey].count : 0;
				});
			}

			function copyProcessedFilterData(to) {
				['statuses', 'types', 'rangeFrom', 'rangeTo', 'step'].forEach((filterElem) => {
					if (to[filterElem] !== undefined) {
						FilterUtils.filterBySingleField($scope.filterData, to, filterElem);
					}
				});
				if (Object.keys(to).some(field => field.includes('createdAt'))) {
					FilterUtils.filterByDateFields($scope.filterData, to, 'createdAt');
				}
			}

			function resetSettings(container) {
				container.loading = false;
				container.error = null;
				container.to = $timeout(() => {
					container.loading = true;
				}, 50);
			}

			function loadMatchingDistData() {
				function calculateRanges() {
					const rangeFrom = 50;
					const rangeTo = 300;
					const step = 10;
					const result = [];
					for (let i = rangeFrom; i < rangeTo; i += step) {
						result.push({ from: i, to: i + step });
					}
					return result;
				}

				const scoreRequestBodies = [
					...['matchingResults.score', 'matchingResults.facesScore',
						'matchingResults.fingersScore',	'matchingResults.irisesScore',
						'matchingResults.palmsScore'].map(aggregation => ({
						rangeAggregations: [
							{
								elasticField: aggregation,
								order: 1,
								ranges: calculateRanges()
							}
						]
					}))];
				const hitsBody = {
					rangeAggregations: [
						{
							elasticField: 'matchingResultCount',
							order: 1,
							ranges: calculateRanges()
						}
					]
				};

				const finalFilter = $scope.matchingDist.filter;
				copyProcessedFilterData(finalFilter);

				resetSettings($scope.matchingDist.scores);
				resetSettings($scope.matchingDist.hits);

				$q.all([
					...scoreRequestBodies.map(body => StatisticsResouce.agregate(finalFilter, body).$promise),
					StatisticsResouce.agregate(finalFilter, hitsBody).$promise
				])
					.then((results) => {
						parseScoresData($scope.matchingDist.scores, results.slice(0, scoreRequestBodies.length - 2));
						parseHitsData($scope.matchingDist.hits, results[results.length - 1]);
					})
					.catch((result) => {
						if (result.status === 500) {
							$scope.noStatisticsMessage = result.data.message;
						}

						$scope.matchingDist.scores.error = 'reports.error';
						$scope.matchingDist.hits.error = 'reports.error';

						$scope.matchingDist.scores.data = null;
						$scope.matchingDist.hits.data = null;
					})
					.finally(() => {
						if (!$timeout.cancel($scope.matchingDist.scores.to)) {
							$scope.matchingDist.scores.loading = false;
						}
						if (!$timeout.cancel($scope.matchingDist.hits.to)) {
							$scope.matchingDist.hits.loading = false;
						}
					});
			}

			function loadDeduplicationData() {
				const adjudicationBody = {
					autoDateHistogramAggregations: [
						{
							elasticField: 'createdAt',
							order: 1,
							numberOfBuckets: 100
						},
					],
					termsAggregations: [
						{
							elasticField: 'status',
							order: 2,
							bucketCount: 20
						},
					]
				};

				const deduplicationBody = {
					termsAggregations: [
						{
							elasticField: 'status',
							order: 1,
							bucketCount: 20
						},
					]
				};

				const finalAdjudicationFilter = $scope.deduplicationDist.adjudication.filter;
				const finalDeduplicationFilter = $scope.deduplicationDist.deduplication.filter;

				copyProcessedFilterData(finalAdjudicationFilter);
				copyProcessedFilterData(finalDeduplicationFilter);

				resetSettings($scope.deduplicationDist.adjudication);
				resetSettings($scope.deduplicationDist.deduplication);

				$q.all([
					StatisticsResouce.agregate(finalAdjudicationFilter, adjudicationBody).$promise,
					StatisticsResouce.agregate(finalDeduplicationFilter, deduplicationBody).$promise,
				])
					.then((results) => {
						parseData($scope.deduplicationDist.adjudication, results[0], $scope.adjudicationStatuses);
						parseBarData($scope.deduplicationDist.deduplication, results[1], $scope.statuses);
					})
					.catch((result) => {
						if (result.status === 500) {
							$scope.noStatisticsMessage = result.data.message;
						}
					})
					.finally(() => {
						if (!$timeout.cancel($scope.deduplicationDist.adjudication.to)) {
							$scope.deduplicationDist.adjudication.loading = false;
						}
						if (!$timeout.cancel($scope.deduplicationDist.deduplication.to)) {
							$scope.deduplicationDist.deduplication.loading = false;
						}
					});
			}

			function loadOperationsData() {
				const operationsBody = {
					autoDateHistogramAggregations: [
						{
							elasticField: 'createdAt',
							order: 1,
							numberOfBuckets: 100
						},
					],
					termsAggregations: [
						{
							elasticField: 'type',
							order: 2,
							bucketCount: 20
						},
					]
				};

				const finalOperationsFilter = $scope.operationsDist.filter;
				copyProcessedFilterData(finalOperationsFilter);

				resetSettings($scope.operationsDist.operations);

				$q.all([
					StatisticsResouce.agregate(finalOperationsFilter, operationsBody).$promise,
				])
					.then((results) => {
						parseData($scope.operationsDist.operations, results[0], $scope.types);
					})
					.catch((result) => {
						if (result.status === 500) {
							$scope.noStatisticsMessage = result.data.message;
						}
					})
					.finally(() => {
						if (!$timeout.cancel($scope.operationsDist.operations.to)) {
							$scope.operationsDist.operations.loading = false;
						}
					});
			}

			function loadSubjectsData() {
				const subjectsBody = {
					termsAggregations: [
						{
							elasticField: 'status',
							order: 1,
							bucketCount: 20
						},
					]
				};

				const finalFilter = $scope.subjectsDist.filter;
				copyProcessedFilterData(finalFilter);

				resetSettings($scope.subjectsDist.subjects);

				$q.all([
					StatisticsResouce.agregate(finalFilter, subjectsBody).$promise,
				])
					.then((results) => {
						parsePieData($scope.subjectsDist.subjects, results[0], { OK: $scope.statuses.OK, DUPLICATE_FOUND: $scope.statuses.DUPLICATE_FOUND });
					})
					.catch((result) => {
						if (result.status === 500) {
							$scope.noStatisticsMessage = result.data.message;
						}
					})
					.finally(() => {
						if (!$timeout.cancel($scope.subjectsDist.subjects.to)) {
							$scope.subjectsDist.subjects.loading = false;
						}
					});
			}

			if (AuthDataHolder.hasAnyAuthority('PERMISSION_ADMINISTRATION_DASHBOARD')) {
				let pollEnabled;
				$scope.$on('$viewContentLoaded', () => {
					pollEnabled = true;

					function poll(call, method) {
						if (method == null) {
							method = 'finally';
						}
						(function refresh() {
							call()[method](() => {
								$timeout(() => {
									if (pollEnabled) {
										refresh();
									}
								}, 3000);
							});
						}());
					}

					poll(getMonitoringInfo);
				});
				$scope.$on('$destroy', () => {
					pollEnabled = false;
				});

				$translate([
					'transactions.type.enroll-with-duplicate-check', 'transactions.type.enroll', 'transactions.type.identify',
					'transactions.type.verify', 'transactions.type.verify-update', 'transactions.type.update',
					'transactions.type.delete', 'transactions.status.registered', 'transactions.status.in-progress',
					'transactions.status.adjudication-waiting', 'transactions.status.adjudication-in-progress', 'transactions.status.adjudication-conflict',
					'transactions.status.duplicate-found', 'transactions.status.rejected', 'transactions.status.ok',
					'adjudication.case.status.unique', 'admin.metrics.score', 'admin.metrics.subjects.eligible',
					'adjudication.case.hits', 'admin.metrics.matching.series.overall', 'admin.metrics.matching.series.faces',
					'admin.metrics.matching.series.fingers', 'admin.metrics.matching.series.irises', 'admin.metrics.matching.series.palms'
				])
					.then((translations) => {
						/* eslint-disable dot-notation */
						$scope.types = {
							ENROLL_WITH_DUPLICATE_CHECK: translations['transactions.type.enroll-with-duplicate-check'],
							ENROLL: translations['transactions.type.enroll'],
							IDENTIFY: translations['transactions.type.identify'],
							VERIFY: translations['transactions.type.verify'],
							VERIFY_UPDATE: translations['transactions.type.verify-update'],
							UPDATE: translations['transactions.type.update'],
							DELETE: translations['transactions.type.delete'],
						};

						$scope.adjudicationStatuses = {
							ADJUDICATION_WAITING: translations['transactions.status.adjudication-waiting'],
							ADJUDICATION_IN_PROGRESS: translations['transactions.status.adjudication-in-progress'],
							ADJUDICATION_CONFLICT: translations['transactions.status.adjudication-conflict'],
							OK: translations['transactions.status.ok'],
							DUPLICATE_FOUND: translations['transactions.status.duplicate-found'],
						};

						$scope.statuses = angular.extend({
							REGISTERED: translations['transactions.status.registered'],
							IN_PROGRESS: translations['transactions.status.in-progress'],
							REJECTED: translations['transactions.status.rejected'],
						}, $scope.adjudicationStatuses);

						$scope.deduplicationDist.adjudication.series = Object.values($scope.adjudicationStatuses);
						$scope.operationsDist.operations.series = Object.values($scope.types);
						$scope.subjectsDist.subjects.series = [translations['admin.metrics.subjects.eligible'], $scope.statuses.DUPLICATE_FOUND];
						$scope.matchingDist.scores.series = [
							translations['admin.metrics.matching.series.overall'],
							translations['admin.metrics.matching.series.faces'],
							translations['admin.metrics.matching.series.fingers'],
							translations['admin.metrics.matching.series.irises'],
							translations['admin.metrics.matching.series.palms']];
						$scope.matchingDist.hits.series = [translations['adjudication.case.hits']];

						$scope.deduplicationDist.adjudication.chartOptions.tooltips.custom = handleCustomTooltip('cases-tooltip');
						$scope.operationsDist.operations.chartOptions.tooltips.custom = handleCustomTooltip('operations-tooltip');

						const finalFilter = $scope.deduplicationDist.adjudication.filter;
						copyProcessedFilterData(finalFilter);

						loadMatchingDistData();
						loadDeduplicationData();
						loadOperationsData();
						loadSubjectsData();
						/* eslint-enable dot-notation */
					});
			} else {
				$scope.disabled = true;
			}

			$scope.panelColor = function (abisUnit) {
				var warning = false;
				var danger = false;

				function checkOne(service) {
					switch (service.status) {
					case 'passing':
						return;
					case 'warning':
						warning = true;
						return;
					default:
					case 'critical':
						danger = true;
					}
				}

				function checkMany(services) {
					if (!danger) {
						var total = services.length;
						if (total === 0) {
							danger = true;
						} else {
							var counts = {
								passing: 0,
								warning: 0,
								critical: 0
							};
							for (var i = 0; i < total; i += 1) {
								counts[services[i].status] += 1;
							}
							if (counts.passing === 0) {
								if (counts.warning > 0) {
									warning = true;
								} else {
									danger = true;
								}
							} else if (counts.passing !== total) {
								warning = true;
							}
						}
					}
				}

				checkOne(abisUnit.managementService);
				checkMany(abisUnit.imageProcessingServices);
				checkMany(abisUnit.matchingServices);

				if (danger) {
					return 'bg-danger';
				} else if (warning) {
					return 'bg-warning';
				}
				return 'bg-success';
			};

			$scope.onValueChange = function () {
				if (!$scope.dateFieldsValid('createdAt')) return;

				loadMatchingDistData();
				loadDeduplicationData();
				loadOperationsData();
				loadSubjectsData();
			};

			$scope.isDataEmpty = function (data) {
				return !data // if there is no data
					|| !data.some((column) => {
						if (Array.isArray(column)) {
							return column.length !== 0 // if there are columns that are not empty, then container is not empty
								&& column.some(elem => elem !== 0); // And if there are columns' elements that are not 0, container is not empty
						}
						return column !== 0; // if element is not 0
					});
			};

			$scope.goToStatistic = function (link, tab) {
				$state.go(link, { tab });
			};

			$scope.changePage = function (index) {
				$scope.currentPage = index + 1;
			};
		}]);
