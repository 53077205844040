// spec: https://www.nist.gov/sites/default/files/documents/itl/iad/ig/NIST-SP-500-288-v1.pdf
import angular from 'angular';

const commonDeviceServerActions = {
	initDeviceServer: {
		method: 'POST',
		params: {
			action: 'init'
		},
		headers: {
			'Content-Type': 'application/json'
		},
		transformResponse(json) {
			if (json === null) {
				return;
			}
			return JSON.parse(json);
		}
	},
	getVersion: {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		params: {
			action: 'version'
		},
		transformResponse(xml) {
			// eslint-disable-next-line no-undef
			const parser = new DOMParser(); // for some reason DOMParser is undefined
			const xmlDoc = parser.parseFromString(xml, 'text/xml');
			const deviceServerVersion = {};
			if (xml === null) {
				return deviceServerVersion;
			}
			deviceServerVersion.status = xmlDoc.getElementsByTagName('status')[0].textContent;
			if (deviceServerVersion.status === 'success') {
				const versionInfo = xmlDoc
					.getElementsByTagName('metadata')[0]
					.getElementsByTagName('item');
				for (let i = 0; i < versionInfo.length; i += 1) {
					const key = versionInfo[i].getElementsByTagName('key')[0].textContent;
					const value = versionInfo[i].getElementsByTagName('value')[0].textContent;
					deviceServerVersion[key.toLowerCase()] = value;
				}
			}
			return deviceServerVersion;
		}
	}
};

angular.module('neurotecAbisWebClientApp')

	.factory('DevicesResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseCapturerServiceUrl()}/devices/:action`, null, {
			getDevicesList: {
				method: 'GET',
				params: {
					action: 'list'
				}
			},
			getSelectedDevicesList: {
				method: 'GET',
				params: {
					action: 'selected'
				}
			},
			setSelectedDevices: {
				method: 'POST',
				params: {
					action: 'selected'
				}
			}
		});
	}])
	.factory('DeviceServerResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		const getInfo = {
			getInfo: {
				method: 'GET',
				params: {
					action: 'init',
					actionId: 'info',
				},
				transformResponse(xml) {
					const parser = new DOMParser();
					const xmlDoc = parser.parseFromString(xml, 'text/xml');
					const deviceInfo = {};
					if (xml === null) {
						return deviceInfo;
					}
					deviceInfo.status = xmlDoc.getElementsByTagName('status')[0].textContent;
					deviceInfo.parameters = [];
					if (deviceInfo.status === 'success') {
						const parameters = xmlDoc
							.getElementsByTagName('metadata')[0]
							.getElementsByTagName('item')[0]
							.getElementsByTagName('value')[0]
							.getElementsByTagName('Parameter');
						for (let i = 0; i < parameters.length; i += 1) {
							const parameter = parameters[i];
							deviceInfo.parameters.push({
								allowedValues: parameter.getAttribute('AllowedValues'),
								defaultValue: parameter.getAttribute('DefaultValue'),
								isMandatory: parameter.getAttribute('IsMandatory'),
								name: parameter.getAttribute('Name'),
								type: parameter.getAttribute('Type')
							});
						}
					}
					return deviceInfo;
				}
			},
		};
		const actions = Object.assign({}, commonDeviceServerActions, getInfo);
		return $resource(`${BaseUrlService.getBaseCapturerServiceUrl()}/:action/:actionId`, null, actions);
	}])
	.factory('DeviceServerResourceAdmin', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		const getInfo = {
			getInfo: {
				method: 'GET',
				params: {
					action: 'init',
					actionId: 'info',
				},
				transformResponse(xml) {
					const parser = new DOMParser();
					const xmlDoc = parser.parseFromString(xml, 'text/xml');
					const deviceInfo = {};
					if (xml === null) {
						return deviceInfo;
					}
					deviceInfo.status = xmlDoc.getElementsByTagName('status')[0].textContent;
					deviceInfo.parameters = [];
					if (deviceInfo.status === 'success') {
						const parameters = xmlDoc
							.getElementsByTagName('metadata')[0]
							.getElementsByTagName('item')[0]
							.getElementsByTagName('value')[0]
							.getElementsByTagName('Parameter');
						for (let i = 0; i < parameters.length; i += 1) {
							const parameter = parameters[i];
							deviceInfo.parameters.push({
								allowedValues: parameter.getAttribute('AllowedValues'),
								values: parameter.getAttribute('AllowedValues'),
								defaultValue: parameter.getAttribute('DefaultValue'),
								isMandatory: parameter.getAttribute('IsMandatory'),
								name: parameter.getAttribute('Name'),
								type: parameter.getAttribute('Type')
							});
						}
					}
					return deviceInfo;
				}
			},
		};
		const actions = Object.assign({}, commonDeviceServerActions, getInfo);
		return $resource(`${BaseUrlService.getCapturerServiceUrl()}:port/:action/:actionId`, { port: '@port' }, actions);
	}])
	.factory('CapturerDevicesResource', ['$resource', 'BaseUrlService', 'Utils', 'ETagService', function ($resource, BaseUrlService, Utils, ETagService) {
		function responseImage(data, headers) {
			var response = {};
			response.etag = headers('X-ETag');
			response.status = headers('X-Status');
			response.modality = headers('X-Modality');
			response.submodality = headers('X-Submodality');
			response.isFinalImage = headers('X-IsFinalImage') === 'true';
			if (data) {
				response.image = new Blob([data], {
					type: 'image/jpeg'
				});
			}
			return response;
		}
		return $resource(`${BaseUrlService.getBaseCapturerServiceUrl()}/devices/:deviceId/:action/:actionId/:type`, null, {
			get: {
				method: 'GET',
				transformResponse(xml) {
					if (xml === null) {
						return;
					}
					const parser = new DOMParser();
					const xmlDoc = parser.parseFromString(xml, 'text/xml');
					const xmlDevices = xmlDoc.getElementsByTagName('device');

					const jsonDevices = {};
					for (let i = 0; i < xmlDevices.length; i += 1) {
						const id = xmlDevices[i].getAttribute('id');
						jsonDevices[id] = {
							id,
							displayName: xmlDevices[i].getAttribute('displayName'),
							types: xmlDevices[i].getAttribute('type').split(', ')
						};
					}
					return jsonDevices;
				}
			},
			getInfo: {
				method: 'GET',
				params: {
					action: 'info'
				},
				transformResponse(xml) {
					const parser = new DOMParser();
					const xmlDoc = parser.parseFromString(xml, 'text/xml');
					const xmlItems = xmlDoc.getElementsByTagName('item');
					const deviceInfo = {};
					if (xml === null) {
						return deviceInfo;
					}
					deviceInfo.status = xmlDoc.getElementsByTagName('status')[0].textContent;
					deviceInfo.methods = [];
					if (deviceInfo.status === 'success') {
						deviceInfo.deviceId = xmlDoc.getElementsByTagName('deviceId')[0].textContent;
						for (let i = 0; i < xmlItems.length; i += 1) {
							if (xmlItems[i].getElementsByTagName('key')[0].textContent === 'Modality') {
								const modality = xmlItems[i].getElementsByTagName('defaultValue')[0].textContent;
								deviceInfo.modality = modality;
							}
							if (xmlItems[i].getElementsByTagName('key')[0].textContent === 'Submodality') {
								deviceInfo.positions = [];
								const allowedValues = xmlItems[i].getElementsByTagName('allowedValue');
								for (let j = 0; j < allowedValues.length; j += 1) {
									const position = xmlItems[i].getElementsByTagName('allowedValue')[j].textContent;
									deviceInfo.positions.push(Utils.camelCaseToUpperUnderscore(position));
								}
							}
							if (xmlItems[i].getElementsByTagName('key')[0].textContent === 'CaptureFormat') {
								deviceInfo.formats = [];
								deviceInfo.defaultFormat = xmlItems[i].getElementsByTagName('defaultValue')[0].textContent;
								const allowedValues = xmlItems[i].getElementsByTagName('allowedValue');
								for (let j = 0; j < allowedValues.length; j += 1) {
									const value = xmlItems[i].getElementsByTagName('allowedValue')[j].textContent;
									deviceInfo.formats.push(value);
								}
							}

							if (xmlItems[i].getElementsByTagName('key')[0].textContent === 'SupportedMethods') {
								const allowedValues = xmlItems[i].getElementsByTagName('allowedValue');
								for (let j = 0; j < allowedValues.length; j += 1) {
									const value = xmlItems[i].getElementsByTagName('allowedValue')[j].textContent;
									deviceInfo.methods.push(value);
								}
							}

							if (xmlItems[i].getElementsByTagName('key')[0].textContent === 'MissingFingers') {
								deviceInfo.validMissingFingers = [];
								const allowedValues = xmlItems[i].getElementsByTagName('allowedValue');
								for (let j = 0; j < allowedValues.length; j += 1) {
									const position = xmlItems[i].getElementsByTagName('allowedValue')[j].textContent;
									deviceInfo.validMissingFingers.push(Utils.camelCaseToUpperUnderscore(position));
								}
							}

							if (xmlItems[i].getElementsByTagName('key')[0].textContent === 'ImpressionType') {
								deviceInfo.impressionTypes = {
									allowed: [],
									default: ''
								};
								const rawDefaultValue = xmlItems[i].getElementsByTagName('defaultValue')[0].textContent;
								const allowedValues = xmlItems[i].getElementsByTagName('allowedValue');
								for (let j = 0; j < allowedValues.length; j += 1) {
									const type = allowedValues[j].textContent;
									deviceInfo.impressionTypes.allowed.push(type);
								}
								deviceInfo.impressionTypes.default = deviceInfo.impressionTypes.allowed.find(type => type === rawDefaultValue);
							}
						}
					}
					return deviceInfo;
				}
			},
			register: {
				method: 'POST',
				params: {
					action: 'register'
				},
				headers: {
					'Content-Type': 'text/plain' // Workaround for Firefox
				},
				transformResponse(xml) {
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xml, 'text/xml');
					return {
						status: xmlDoc.getElementsByTagName('status')[0].textContent,
						deviceId: xmlDoc.getElementsByTagName('deviceId')[0].textContent,
						sessionId: xmlDoc.getElementsByTagName('sessionId')[0].textContent
					};
				}
			},
			lock: {
				method: 'POST',
				params: {
					action: 'lock'
				},
				headers: {
					'Content-Type': 'text/plain' // Workaround for Firefox
				},
				transformResponse(xml) {
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xml, 'text/xml');
					var response = {};
					response.status = xmlDoc.getElementsByTagName('status')[0].textContent;
					if (response.status === 'success') {
						response.deviceId = xmlDoc.getElementsByTagName('deviceId')[0].textContent;
					}
					return response;
				}
			},
			unlock: {
				method: 'POST', // Workaround for Firefox
				params: {
					action: 'unlock' // Workaround for Firefox
				},
				headers: {
					'Content-Type': 'text/plain' // Workaround for Firefox
				},
				transformResponse(xml) {
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xml, 'text/xml');
					var response = {};
					response.status = xmlDoc.getElementsByTagName('status')[0].textContent;
					if (response.status === 'success') {
						response.deviceId = xmlDoc.getElementsByTagName('deviceId')[0].textContent;
					}
					return response;
				}
			},
			capture: {
				method: 'POST',
				params: {
					action: 'capture'
				},
				headers: {
					'Content-Type': 'text/plain' // Workaround for Firefox
				},
				transformResponse(xml) {
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xml, 'text/xml');
					var response = {};
					response.status = xmlDoc.getElementsByTagName('status')[0].textContent;
					if (xmlDoc.getElementsByTagName('message').length !== 0) {
						response.message = xmlDoc.getElementsByTagName('message')[0].textContent;
					}
					response.deviceId = xmlDoc.getElementsByTagName('deviceId')[0].textContent;
					if (response.status === 'success') {
						response.captureIds = [];
						var elements = xmlDoc.getElementsByTagName('captureIds')[0].getElementsByTagName('element');
						for (var i = 0; i < elements.length; i += 1) {
							response.captureIds.push(elements[i].textContent);
						}
					}
					return response;
				}
			},
			force: {
				method: 'POST',
				params: {
					action: 'force'
				},
				headers: {
					'Content-Type': 'text/plain' // Workaround for Firefox
				},
				transformResponse(xml) {
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xml, 'text/xml');
					var response = {};
					response.status = xmlDoc.getElementsByTagName('status')[0].textContent;
					response.deviceId = xmlDoc.getElementsByTagName('deviceId')[0].textContent;
					return response;
				}
			},
			startSequence: {
				method: 'POST',
				params: {
					action: 'sequence'
				},
				headers: {
					'Content-Type': 'text/plain' // Workaround for Firefox
				}
			},
			previewImage: {
				method: 'GET',
				responseType: 'arraybuffer',
				params: {
					action: 'preview',
					type: 'image'
				},
				headers: {
					'If-None-Match': ETagService.getETag
				},
				transformResponse: responseImage
			},
			preview: {
				method: 'GET',
				params: {
					action: 'preview'
				},
				transformResponse(xml) {
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xml, 'text/xml');
					var response = {};
					response.metadata = {};
					response.status = xmlDoc.getElementsByTagName('status')[0].textContent;
					response.deviceId = xmlDoc.getElementsByTagName('deviceId')[0].textContent;
					if (response.status === 'success') {
						response.icaoView = {};
						var items = xmlDoc.getElementsByTagName('item');
						for (var i = 0; i < items.length; i += 1) {
							if (items[i].getElementsByTagName('key')[0].textContent === 'Icao') {
								var icaoItems = items[i].getElementsByTagName('item');
								var icaoWarnings = [];
								for (var j = 0; j < icaoItems.length; j += 1) {
									if (icaoItems[j].getElementsByTagName('value')[0].textContent === 'Warning') {
										icaoWarnings.push(Utils.camelCaseToUpperUnderscore(icaoItems[j].getElementsByTagName('key')[0].textContent));
									}
								}
								response.icaoWarnings = icaoWarnings;
							} else if (items[i].getElementsByTagName('key')[0].textContent === 'IcaoView') {
								var icaoViewItems = items[i].getElementsByTagName('item');
								for (var k = 0; k < icaoViewItems.length; k += 1) {
									var key = icaoViewItems[k].getElementsByTagName('key')[0];
									var value = icaoViewItems[k].getElementsByTagName('value')[0];
									var valueString = value.textContent;
									var valueType = value.getAttribute('type');
									var keyName = key.textContent;

									if (valueType === 'int') {
										response.icaoView[keyName] = parseInt(valueString, 10);
									} else if (valueType === 'float') {
										response.icaoView[keyName] = parseFloat(valueString, 10);
									} else {
										response.icaoView[keyName] = valueString;
									}
								}
							} else if (items[i].getElementsByTagName('key')[0].textContent === 'Status') {
								response.metadata.status = items[i].getElementsByTagName('value')[0].textContent;
							} else if (items[i].getElementsByTagName('key')[0].textContent === 'Submodality') {
								response.metadata.submodality = items[i].getElementsByTagName('value')[0].textContent;
							}
						}
					}
					return response;
				}
			},
			download: {
				method: 'GET',
				params: {
					action: 'download'
				},
				transformResponse(xml) {
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xml, 'text/xml');
					var sensorDataTag = xmlDoc.getElementsByTagName('sensorData')[0];
					var response = {};
					response.status = xmlDoc.getElementsByTagName('status')[0].textContent;
					response.deviceId = xmlDoc.getElementsByTagName('deviceId')[0].textContent;
					if (response.status === 'success') {
						response.sensorData = sensorDataTag !== undefined ? sensorDataTag.textContent : null;
						var metadata = xmlDoc.getElementsByTagName('metadata')[0];
						var items = metadata.getElementsByTagName('item');
						response.metadata = {};
						for (var i = 0; i < items.length; i += 1) {
							var key = items[i].getElementsByTagName('key')[0].textContent;
							var value = items[i].getElementsByTagName('value')[0].textContent;
							response.metadata[key] = value;
						}
					}
					return response;
				}
			},
			cancel: {
				method: 'POST',
				params: {
					action: 'cancel'
				},
				transformResponse(xml) {
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xml, 'text/xml');
					var response = {};
					response.status = xmlDoc.getElementsByTagName('status')[0].textContent;
					if (response.status === 'success') {
						response.deviceId = xmlDoc.getElementsByTagName('deviceId')[0].textContent;
					}
					return response;
				}
			},
			getConfiguration: {
				method: 'GET',
				params: {
					action: 'configure'
				},
				transformResponse(xml) {
					var response = {};
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xml, 'text/xml');
					response.status = xmlDoc.getElementsByTagName('status')[0].textContent;
					response.deviceId = xmlDoc.getElementsByTagName('deviceId')[0].textContent;
					response.metadata = {};
					if (response.status === 'success') {
						var items = xmlDoc.getElementsByTagName('item');
						for (var i = 0; i < items.length; i += 1) {
							var key = items[i].getElementsByTagName('key')[0].textContent;
							var value = items[i].getElementsByTagName('value')[0].textContent;
							response.metadata[key] = value;
						}
					}
					return response;
				}
			},
			setConfiguration: {
				method: 'POST',
				params: {
					action: 'configure'
				},
				headers: {
					'Content-Type': 'application/json'
				},
				transformResponse(json) {
					return JSON.parse(json);
				}
			},
			invoke: {
				method: 'POST',
				params: {
					action: 'invoke'
				},
				headers: {
					'Content-Type': 'text/plain' // Workaround for Firefox
				},
				transformRequest(body) {
					let requestBody = null;
					if (body) {
						requestBody =
						`<data>
						<item>
							<key>X</key>
							<value type="float">${Math.max(body.x, 0)}</value>
						</item>
						<item>
							<key>Y</key>
							<value type="float">${Math.max(body.y, 0)}</value>
						</item>
						<item>
							<key>Width</key>
							<value type="float">${body.width}</value>
						</item>
							<item> <key>Height</key>
							<value type="float">${body.height}<value>
						</item>
						</data>`;
					}
					return requestBody;
				}
			}
		});
	}])
	.factory('CaptureLogResource', ['$resource', 'BaseUrlService', function ($resource, BaseUrlService) {
		return $resource(`${BaseUrlService.getBaseCapturerServiceUrl()}/logs/:count`, null, {
			get: {
				method: 'GET',
				transformResponse(xml) {
					const parser = new DOMParser();
					const xmlDoc = parser.parseFromString(xml, 'text/xml');
					const metadata = xmlDoc.getElementsByTagName('metadata')[0];
					const logs = [];
					for (let i = 0; i < metadata.children.length; i += 1) {
						const child = metadata.children[i];
						logs.push({
							date: child.children[0].textContent,
							value: child.children[1].textContent,
						});
					}
					return {
						status: xmlDoc.getElementsByTagName('status')[0].textContent, logs
					};
				}
			},

			all: {
				method: 'GET',
				transformResponse(xml) {
					const parser = new DOMParser();
					const xmlDoc = parser.parseFromString(xml, 'text/xml');
					const metadata = xmlDoc.getElementsByTagName('metadata')[0];
					let data = null;
					const logs = [];
					for (let i = 0; i < metadata.children.length; i += 1) {
						const child = metadata.children[i];
						const key = child.children[0].innerHTML;
						if (key === 'content') {
							data = child.children[1].textContent;
							break;
						}
					}

					if (data) {
						data = atob(data).split('\n');
						data.forEach((log) => {
							const spaceSplited = log.split(' ');
							if (spaceSplited.length > 1) {
								const date = `${spaceSplited.shift()} ${spaceSplited.shift()}`;
								const value = spaceSplited.join(' ');
								if (date) {
									logs.push({ date, value });
								}
							}
						});
					}
					return {
						status: xmlDoc.getElementsByTagName('status')[0].textContent, logs
					};
				},
				params: {
					count: 'full'
				}
			}
		});
	}]);
