import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.factory('DeviceServerParameters', ['Utils',
		function (Utils) {
			const PARAMETER = function (key, type, value = '', defaultValue = '', values = [], translation = '', isDisabled = null, edit = false, savedValue = null, additionalProperties = {}) {
				let newObj = {
					key,
					type,
					value,
					defaultValue,
					edit,
					savedValue,
					translation,
					isDisabled
				};

				if (!Utils.isObjectEmpty(additionalProperties)) {
					newObj = Object.assign({}, newObj, additionalProperties);
				}

				if (values.length > 0) {
					newObj.values = values;
				}

				return newObj;
			};

			const ADDITIONAL_NUMBER_PARAMETERS = function (min = null, max = null, obj = {}) {
				return Object.assign(obj, {
					min,
					max
				});
			};

			const FACE_PARAMETER_TYPE = function (type, obj = {}) {
				return Object.assign(obj, { parameterType: type });
			};

			function isThresholdDisabled(parameterGroup, thresholdToggleName) {
				return !parameterGroup.find(param => param.key === thresholdToggleName).value;
			}

			const deviceServer = {
				fingers: [
					PARAMETER(
						'Fingers.QualityAlgorithm', 'multi-select', 'NfiqQuality', 'NfiqQuality',
						['NfiqQuality', 'Nfiq2Quality', 'Nfiq21Quality', 'Off'], 'settings.capture-options.fingers.options.nfiq-quality-algorithm'
					),
					PARAMETER('Fingers.CheckForDuplicates', 'checkbox', false, false, [], 'settings.capture-options.fingers.options.check-for-duplicates'),
				],
				palms: [],
				faces: {
					capture: [
						// Disabled for now
						// PARAMETER(
						// 	'Faces.DetectProperties', 'checkbox', true, true, [], 'settings.capture-options.faces.options.detect-properties', null, false, null,
						// 	FACE_PARAMETER_TYPE('capture', { tooltip: 'Enable/disable detection of glasses, dark glasses, blinking, open mouth, beard, mustaches and hat' })
						// ),
						PARAMETER('Faces.DetermineGender', 'checkbox', false, false, [], 'settings.capture-options.faces.options.determine-gender', null, false, null, FACE_PARAMETER_TYPE('capture')),
						PARAMETER('Faces.RecognizeExpression', 'checkbox', true, true, [], 'settings.capture-options.faces.options.recognize-expression', null, false, null, FACE_PARAMETER_TYPE('capture')),
						PARAMETER('Faces.RecognizeEmotion', 'checkbox', true, true, [], 'settings.capture-options.faces.options.recognize-emotion', null, false, null, FACE_PARAMETER_TYPE('capture')),
						PARAMETER('Faces.DetermineAge', 'checkbox', false, false, [], 'settings.capture-options.faces.options.determine-age', null, false, null, FACE_PARAMETER_TYPE('capture')),
						PARAMETER('Faces.DetermineEthnicity', 'checkbox', false, false, [], 'settings.capture-options.faces.options.determine-ethnicity', null, false, null, FACE_PARAMETER_TYPE('capture')),
					],
					icao: [
						PARAMETER('Faces.CheckIcaoCompliance', 'checkbox', true, true, [], 'settings.capture-options.faces.options.icao', null, false, null, FACE_PARAMETER_TYPE('icao')),
						PARAMETER(
							'Faces.IcaoMaximalRoll', 'number', 10, 10, [], 'settings.capture-options.faces.options.maximal-roll',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 180))
						),
						PARAMETER(
							'Faces.IcaoMaximalYaw', 'number', 10, 10, [], 'settings.capture-options.faces.options.maximal-yaw',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 180))
						),
						PARAMETER(
							'Faces.IcaoMaximalPitch', 'number', 10, 10, [], 'settings.capture-options.faces.options.maximal-pitch',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 180))
						),
						PARAMETER(
							'Faces.IcaoSaturationThreshold', 'number', 50, 50, [], 'settings.capture-options.faces.options.minimal-saturation',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoSharpnessThreshold', 'number', 50, 50, [], 'settings.capture-options.faces.options.minimal-sharpness',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoBackgroundUniformityThreshold', 'number', 50, 50, [], 'settings.capture-options.faces.options.minimal-background-uniformity',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoGrayscaleDensityThreshold', 'number', 50, 50, [], 'settings.capture-options.faces.options.minimal-grayscale-density',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoLookingAwayThreshold', 'number', 50, 50, [], 'settings.capture-options.faces.options.looking-away-threshold',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoRedEyeThreshold', 'number', 50, 50, [], 'settings.capture-options.faces.options.red-eye-treshold',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoFaceDarknessThreshold', 'number', 50, 50, [], 'settings.capture-options.faces.options.face-darkness-threshold',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoUnnaturalSkinToneThreshold', 'number', 30, 30, [], 'settings.capture-options.faces.options.unnatural-skin-threshold',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoWashedOutThreshold', 'number', 50, 50, [], 'settings.capture-options.faces.options.washed-out-image',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoPixelationThreshold', 'number', 50, 50, [], 'settings.capture-options.faces.options.pixelation-threshold',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoSkinReflectionThreshold', 'number', 30, 30, [], 'settings.capture-options.faces.options.skin-reflection-threshold',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoGlassesReflectionThreshold', 'number', 50, 50, [], 'settings.capture-options.faces.options.glasses-reflection-threshold',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoRemoveRedEye', 'checkbox', true, true, [], 'settings.capture-options.faces.options.remove-red-eye',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao')
						),
						PARAMETER(
							'Faces.IcaoRemoveBackground', 'checkbox', false, false, [], 'settings.capture-options.faces.options.remove-background',
							() => isThresholdDisabled(deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao')
						),
					],
				},
			};

			return deviceServer;
		}]);
