import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('ConfGalleriesCtrl', ['$q', '$scope', '$translate', 'AuthDataHolder', 'GalleriesService', 'TaskPollerService', 'ConvertionUtils',
		function ($q, $scope, $translate, AuthDataHolder, GalleriesService, TaskPollerService, ConvertionUtils) {
			const GLOBAL_GALLERY_NAME = 'Global';
			const GLOBAL_GALLERY_ID = 'default';
			$scope.galleriesAlerts = [];

			$scope.galleries = [];

			$scope.hasAnyAuthority = function (...args) {
				return AuthDataHolder.hasAnyAuthority(args);
			};

			$scope.cnv = ConvertionUtils;

			$scope.states = {};
			$scope.statesAll = {};
			$translate([
				'admin.configuration.galleries.active', 'admin.configuration.galleries.disabled',
				'admin.configuration.galleries.deleted', 'admin.configuration.galleries.locked'
			]).then((translations) => {
				$scope.states = {
					/* eslint-disable dot-notation */
					ACTIVE: translations['admin.configuration.galleries.active'],
					DISABLED: translations['admin.configuration.galleries.disabled']
					/* eslint-enable dot-notation */
				};

				$scope.statesAll = angular.extend(angular.copy($scope.states), {
					/* eslint-disable dot-notation */
					DELETED: translations['admin.configuration.galleries.deleted'],
					LOCKED: translations['admin.configuration.galleries.locked']
					/* eslint-enable dot-notation */
				});
			});

			GalleriesService.getGalleries()
				.then((result) => {
					result.forEach((gallery) => {
						$scope.galleries.push({
							data: gallery
						});
					});
				});

			$scope.editGallery = function (gallery) {
				gallery.edit = angular.copy(gallery.data);
			};

			$scope.deleteGallery = function (gallery) {
				var startMsg = 'admin.configuration.galleries.saving.delete';
				var successMsg = 'admin.configuration.galleries.success.delete';
				$translate([startMsg, successMsg], { galleryId: gallery.data.id }).then((translations) => {
					TaskPollerService.pollAndAlert((success, error) => {
						GalleriesService.delete({ id: gallery.data.id }, success, error);
					}, $scope.galleriesAlerts, translations[startMsg], translations[successMsg], () => {
						gallery.data.state = 'DELETED';
					});
				});
			};

			$scope.discardGalleryChanges = function (gallery) {
				if (gallery.data) {
					gallery.edit = null;
				} else {
					$scope.galleries.splice($scope.galleries.indexOf(gallery), 1);
				}
			};

			function isValidGallery(gallery) {
				if (gallery.edit.name === GLOBAL_GALLERY_NAME || gallery.edit.id === GLOBAL_GALLERY_ID) {
					return false;
				}
				return true;
			}

			function addGallery(gallery) {
				var startMsg = 'admin.configuration.galleries.saving.new';
				var successMsg = 'admin.configuration.galleries.success.new';
				const reservedGalleryName = 'admin.configuration.galleries.failure.new';

				$translate([startMsg, successMsg, reservedGalleryName], { galleryId: gallery.edit.id }).then((translations) => {
					if (!isValidGallery(gallery)) {
						$scope.galleriesAlerts.push({ type: 'danger', msg: translations[reservedGalleryName] });
						return;
					}

					TaskPollerService.pollAndAlert((success, error) => {
						GalleriesService.save(gallery.edit, success, error);
					}, $scope.galleriesAlerts, translations[startMsg], translations[successMsg], () => {
						gallery.data = gallery.edit;
						gallery.edit = null;
					});
				});
			}

			function updateGallery(gallery) {
				const startMsg = 'admin.configuration.galleries.saving.update';
				const successMsg = 'admin.configuration.galleries.success.update';
				$translate([startMsg, successMsg], { galleryId: gallery.data.id }).then((translations) => {
					TaskPollerService.pollAndAlert((success, error) => {
						GalleriesService.save({ id: gallery.data.id }, gallery.edit, success, error);
					}, $scope.galleriesAlerts, translations[startMsg], translations[successMsg], () => {
						gallery.data = gallery.edit;
						gallery.edit = null;
					});
				});
			}

			$scope.saveGallery = function (gallery) {
				if (gallery.data) {
					updateGallery(gallery);
				} else {
					addGallery(gallery);
				}
			};

			$scope.newGallery = function () {
				$scope.galleries.push({
					edit: {
						state: 'ACTIVE'
					}
				});
			};
		}]);
