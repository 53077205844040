import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('StatisticsCtrl', ['$scope', '$translate', '$state', '$timeout', '$uibModal', '$q', 'FilterUtils', 'UsernamesService', 'StatisticsResouce', 'ChartOptionsService',
		function ($scope, $translate, $state, $timeout, $uibModal, $q, FilterUtils, UsernamesService, StatisticsResouce, ChartOptionsService) {
			$scope.noStatisticsMessage = '';
			$scope.isGraphVisible = true;
			$scope.datePickerOptions = {
				timePicker: true,
				locale: {
					format: 'MM/DD/YYYY hh:mm A'
				}
			};

			$scope.filterType = 'time';
			$scope.stringSettings = {
				smartButtonTextConverter(itemText) { return itemText; },
				scrollableHeight: '100px',
				scrollable: true,
				smartButtonMaxItems: 2
			};

			function identifyPage() {
				if ($state.is('admin.metrics.deduplication')) {
					$scope.container = $scope.statistics.deduplication;
					$scope.opts = { activeTab: $state.params.tab || 'tab-deduplication' };
				}
				if ($state.is('admin.metrics.operations')) {
					$scope.container = $scope.statistics.biometrics;
					$scope.opts = { activeTab: $state.params.tab || 'tab-operations' };
				}
				if ($state.is('admin.metrics.subjects')) {
					$scope.container = $scope.statistics.subjects;
					$scope.opts = { activeTab: $state.params.tab || 'tab-subjects' };
				}
				if ($state.is('admin.metrics.matching')) {
					$scope.container = $scope.statistics.matching;
					$scope.opts = { activeTab: $state.params.tab || 'tab-score' };
				}
			}

			$scope.$on('$viewContentLoaded', () => {
				$translate([
					'transactions.type.enroll-with-duplicate-check', 'transactions.type.enroll', 'transactions.type.identify',
					'transactions.type.verify', 'transactions.type.verify-update', 'transactions.type.update',
					'transactions.type.delete', 'transactions.status.registered', 'transactions.status.in-progress',
					'transactions.status.adjudication-waiting', 'transactions.status.adjudication-in-progress', 'transactions.status.adjudication-conflict',
					'transactions.status.duplicate-found', 'transactions.status.rejected', 'transactions.status.ok',
					'adjudication.case.status.unique', 'admin.metrics.subjects.eligible',
					'admin.metrics.filter.custom', 'admin.metrics.filter.day', 'admin.metrics.filter.week',
					'admin.metrics.filter.month', 'admin.metrics.filter.year', 'admin.metrics.filter.date',
					'admin.metrics.filter.hits', 'admin.metrics.matching.series.overall', 'admin.metrics.matching.series.faces',
					'admin.metrics.matching.series.fingers', 'admin.metrics.matching.series.irises', 'admin.metrics.matching.series.palms',
					'admin.metrics.matching.series.hits', 'admin.metrics.filter.graph', 'admin.metrics.filter.list'
				])
					.then((translations) => {
						/* eslint-disable dot-notation */
						$scope.types = {
							ENROLL_WITH_DUPLICATE_CHECK: translations['transactions.type.enroll-with-duplicate-check'],
							ENROLL: translations['transactions.type.enroll'],
							IDENTIFY: translations['transactions.type.identify'],
							VERIFY: translations['transactions.type.verify'],
							VERIFY_UPDATE: translations['transactions.type.verify-update'],
							UPDATE: translations['transactions.type.update'],
							DELETE: translations['transactions.type.delete'],
						};

						$scope.adjudicationStatuses = {
							ADJUDICATION_WAITING: translations['transactions.status.adjudication-waiting'],
							ADJUDICATION_IN_PROGRESS: translations['transactions.status.adjudication-in-progress'],
							ADJUDICATION_CONFLICT: translations['transactions.status.adjudication-conflict'],
							OK: translations['transactions.status.ok'],
							DUPLICATE_FOUND: translations['transactions.status.duplicate-found'],
						};

						$scope.statuses = angular.extend({
							REGISTERED: translations['transactions.status.registered'],
							IN_PROGRESS: translations['transactions.status.in-progress'],
							REJECTED: translations['transactions.status.rejected'],
						}, $scope.adjudicationStatuses);

						$scope.adjudicationStatusOptions = Object.keys($scope.adjudicationStatuses).reduce((acc, current) => {
							acc.push({ id: current, label: $scope.adjudicationStatuses[current] });
							return acc;
						}, []);
						$scope.statusOptions = Object.keys($scope.statuses).reduce((acc, current) => {
							acc.push({ id: current, label: $scope.statuses[current] });
							return acc;
						}, []);
						$scope.typesOptions = Object.keys($scope.types).reduce((acc, current) => {
							acc.push({ id: current, label: $scope.types[current] });
							return acc;
						}, []);

						$scope.translationTable = angular.extend({}, $scope.statuses, $scope.types);

						$scope.subjectsLabels = {
							OK: translations['admin.metrics.subjects.eligible'],
							DUPLICATE_FOUND: $scope.statuses.DUPLICATE_FOUND
						};

						$scope.$watch('statistic.data', (newValue) => {
							if (newValue) {
								const isArray = Array.isArray(newValue[0]);
								let max = isArray ? Math.max(...newValue[0]) : newValue[0];
								newValue.forEach((arr) => {
									const tempMax = isArray ? Math.max(...arr) : arr;
									if (tempMax > max) {
										max = tempMax;
									}
								});
								$scope.statistic.chartOptions.scales.yAxes[0].ticks.max = max + 0.5;
							}
						}, true);

						function handleDownloadLink() {
							const canvas = document.getElementById('statistics-chart');
							canvas.toBlob((data) => {
								$scope.chartDownloadData = data;
							});
						}

						const { termsOptions, chartOptions } = ChartOptionsService.getMetricsOptions(handleDownloadLink);

						/* eslint-disable dot-notation */
						$scope.timeFrames = [
							{
								name: translations['admin.metrics.filter.custom'],
								checked: true,
								action() {
									updateSelection(0);

									filterDataInternal = {};
									$scope.filterData = angular.copy($scope.statistic.filters);
									if ($scope.statistic.filterOptions) {
										checkAndSetDateSteps();
									}

									copyProcessedFilterData(filterDataInternal);
									$scope.statistic.actionFunc();
									// toggle to graph
									$scope.isGraphVisible = true;
								}
							},
							{
								name: translations['admin.metrics.filter.day'],
								checked: false,
								action() {
									updateSelection(1);
									filterInterval('days');
									// toggle to graph
									$scope.isGraphVisible = true;
								}
							},
							{
								name: translations['admin.metrics.filter.week'],
								checked: false,
								action() {
									updateSelection(2);
									filterInterval('weeks');
									$scope.statistic.chartOptions.scales.xAxes[0].time.unit = 'day';
									// toggle to graph
									$scope.isGraphVisible = true;
								}
							},
							{
								name: translations['admin.metrics.filter.month'],
								checked: false,
								action() {
									updateSelection(3);
									filterInterval('months');
									// toggle to graph
									$scope.isGraphVisible = true;
								}
							},
							{
								name: translations['admin.metrics.filter.year'],
								checked: false,
								action() {
									updateSelection(4);
									filterInterval('years');
									// toggle to graph
									$scope.isGraphVisible = true;
								}
							}
						];
						/* eslint-disable dot-notation */

						$scope.statistics = {
							deduplication: {
								adjudication: {
									title: 'admin.metrics.deduplication.adjudication',
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										dateStep: { value: 1, type: '' },
										statuses: [],
										adjudicated: true
									},
									filterOptions: {
										dateSteps: ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years']
									},
									type: 'termsAggregations',
									aggregation: [{
										elasticField: 'status',
										order: 2,
										bucketCount: 100
									}],
									csvSeries: [translations['admin.metrics.filter.date']],
									isFormValid: () => ($scope.dateFieldsValid('createdAt')
										&& $scope.filterData.dateStep.value > 0),
									// eslint-disable-next-line object-shorthand
									actionFunc: function () { loadDateStatistics.call(this, $scope.adjudicationStatuses); },
									chartOptions: chartOptions(),
									modalFileTemplate: require('../../../views/modal/adjudication-generate-report-modal.html')
								},
								deduplication: {
									title: 'admin.metrics.deduplication',
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										dateStep: { value: 1, type: '' },
										statuses: [],
										users: []
									},
									filterOptions: {
										dateSteps: ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years']
									},
									type: 'termsAggregations',
									aggregation: [{
										elasticField: 'status',
										order: 2,
										bucketCount: 20
									}],
									csvSeries: [translations['admin.metrics.filter.date']],
									isFormValid: () => ($scope.dateFieldsValid('createdAt')
									&& $scope.filterData.dateStep.value > 0),
									// eslint-disable-next-line object-shorthand
									actionFunc: function () { loadDateStatistics.call(this, $scope.statuses); },
									chartOptions: chartOptions(),
									modalFileTemplate: require('../../../views/modal/adjudication-generate-report-modal.html')
								}
							},
							biometrics: {
								operations: {
									title: 'admin.metrics.operations.title',
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										dateStep: { value: 1, type: '' },
										types: []
									},
									filterOptions: {
										dateSteps: ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years']
									},
									type: 'termsAggregations',
									aggregation: [{
										elasticField: 'type',
										order: 2,
										bucketCount: 100
									}],
									csvSeries: [translations['admin.metrics.filter.date']],
									isFormValid: () => ($scope.dateFieldsValid('createdAt')
									&& $scope.stepFieldValid($scope.filterData.dateStep.value)),
									// eslint-disable-next-line object-shorthand
									actionFunc: function () { loadDateStatistics.call(this, $scope.types); },
									chartOptions: chartOptions()
								}
							},
							subjects: {
								subjects: {
									title: 'admin.metrics.subjects',
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										dateStep: { value: 1, type: '' },
									},
									filterOptions: {
										dateSteps: ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years']
									},
									type: 'termsAggregations',
									aggregation: [{
										elasticField: 'status',
										order: 2,
										bucketCount: 20
									}],
									csvSeries: [translations['admin.metrics.filter.date']],
									isFormValid: () => ($scope.dateFieldsValid('createdAt')
									&& $scope.filterData.dateStep.value > 0),
									// eslint-disable-next-line object-shorthand
									actionFunc: function () { loadDateStatistics.call(this, $scope.subjectsLabels); },
									chartOptions: chartOptions()
								}
							},
							matching: {
								hits: {
									title: 'admin.metrics.matching.hits',
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										adjudicated: null,
										rangeFrom: 50,
										rangeTo: 300,
										step: 10,
									},
									type: 'rangeAggregations',
									chartOptions: termsOptions(),
									aggregation: [{
										rangeAggregations: [{
											elasticField: 'matchingResultCount',
											order: 1,
											ranges: []
										}]
									}],
									csvSeries: [translations['admin.metrics.filter.hits']],
									series: [translations['admin.metrics.matching.series.hits']],
									isFormValid: () => ($scope.dateFieldsValid('createdAt')
									&& $scope.rangeFieldValid()
									&& $scope.stepFieldValid()),
									// eslint-disable-next-line object-shorthand
									actionFunc: function () { loadHitsAggregation.call(this); }
								},
								score: {
									title: 'admin.metrics.matching.scores',
									filters: {
										createdAtUpper: null,
										createdAtLower: null,
										adjudicated: null,
										rangeFrom: 50,
										rangeTo: 300,
										step: 10,
									},
									type: 'rangeAggregations',
									chartOptions: termsOptions(),
									aggregation: ['matchingResults.score', 'matchingResults.facesScore',
										'matchingResults.fingersScore',	'matchingResults.irisesScore',
										'matchingResults.palmsScore'].map(aggregation => ({
										rangeAggregations: [{
											elasticField: aggregation,
											order: 1,
											ranges: []
										}]
									})),
									csvSeries: [],
									series: [
										translations['admin.metrics.matching.series.overall'],
										translations['admin.metrics.matching.series.faces'],
										translations['admin.metrics.matching.series.fingers'],
										translations['admin.metrics.matching.series.irises'],
										translations['admin.metrics.matching.series.palms'],
									],
									isFormValid: () => ($scope.dateFieldsValid('createdAt')
									&& $scope.rangeFieldValid()
									&& $scope.stepFieldValid()),
									// eslint-disable-next-line object-shorthand
									actionFunc: function () {
										loadScoresAggregation.call(this);
									}
								}
							}
						};
						$scope.translationTexts = {
							buttonDefaultText: translations['admin.metrics.matching.all']
						};

						$scope.toggle = {
							graph: translations['admin.metrics.filter.graph'],
							list: translations['admin.metrics.filter.list']
						};

						identifyPage();
						$scope.onUpdate();
						/* eslint-enable dot-notation */
					});
			});

			function updateSelection(position) {
				$scope.timeFrames.forEach((frame, index) => {
					frame.checked = index === position;
				});
			}

			$scope.getObjectFromTabName = function (tabName) {
				return $scope.container[tabName.split('-')[1]];
			};

			function checkAndSetDateSteps() {
				if ($scope.filterData.dateStep) {
					[
						/* seconds */,
						/* minutes */,
						/* hours */,
						/* days */ $scope.filterData.dateStep.type
					] = $scope.statistic.filterOptions.dateSteps;
				}
			}

			$scope.onUpdate = function (tabName = null) {
				if (tabName !== null) { $scope.opts.activeTab = tabName; }

				$scope.statistic = $scope.getObjectFromTabName($scope.opts.activeTab);
				$scope.filterData = angular.copy($scope.statistic.filters);
				checkAndSetDateSteps();

				$scope.statistic.actionFunc();
			};

			$scope.dateFieldsValid = function (prefix) {
				return FilterUtils.dateFieldsValid($scope.filterData, prefix, true);
			};
			$scope.rangeFieldValid = function () {
				return FilterUtils.rangeFieldValid($scope.filterData, 'range');
			};
			$scope.stepFieldValid = function (obj = null) {
				return FilterUtils.stepFieldValid(obj || $scope.filterData.step);
			};

			$scope.changeSection = function (title) {
				const statisticsTabGroup = $scope.getObjectFromTabName({ funcParam: 'tab-adjudication' });
				const [newSectionKey] = Object.keys(statisticsTabGroup).filter(key => statisticsTabGroup[key].title === title);
				$scope.statistic = statisticsTabGroup[newSectionKey];
				$scope.filterData = angular.copy($scope.statistic.filters);
				$scope.statistic.actionFunc();
			};

			$scope.fetchUsers = function (name) {
				if (name === '' || name.length < 3) return [];

				return UsernamesService.get({ name }).$promise.then((users) => {
					if (!users) {
						$scope.noResults = false;
					}
					return users.map(user => ({ id: user.id, name: user.name }));
				});
			};

			$scope.onValueChange = function () { updateSelection(0); };
			$scope.events = { onSelectionChanged: () => $scope.onValueChange() };

			let filterDataInternal = {};
			$scope.filterData = {};

			function copyProcessedFilterData(to) {
				['users', 'rangeFrom', 'rangeTo', 'step', 'adjudicated', 'dateStep'].forEach((filterElem) => {
					if ($scope.filterData[filterElem] !== undefined) {
						FilterUtils.filterBySingleField($scope.filterData, to, filterElem);
					}
				});
				if (Object.keys($scope.filterData).some(field => field.includes('createdAt'))) {
					FilterUtils.filterByDateFields($scope.filterData, to, 'createdAt');
				}
				if (Object.keys($scope.filterData).some(field => field.includes('statuses'))) {
					FilterUtils.filterBySingleField($scope.filterData, to, 'statuses', statuses => statuses.map(status => status.id));
				}
				if (Object.keys($scope.filterData).some(field => field.includes('types'))) {
					FilterUtils.filterBySingleField($scope.filterData, to, 'types', types => types.map(type => type.id));
				}
			}

			function filterInterval(timeString) {
				filterDataInternal = {};
				$scope.filterData = angular.copy($scope.statistic.filters);
				if ($scope.statistic.filterOptions) {
					checkAndSetDateSteps();
				}

				$scope.filterData.createdAtLower = moment().add(-1, timeString);
				$scope.filterData.createdAtUpper = moment();

				copyProcessedFilterData(filterDataInternal);
				$scope.statistic.actionFunc();
			}

			$scope.filter = function () {
				if ($scope.statistic.isFormValid()) {
					filterDataInternal = {};
					$scope.statistic.actionFunc();
				}
			};

			function calculateDateRange(from, to, step) {
				if (from === null || to === null) return [];

				const range = [];
				while (from <= to) {
					const tempTo = from.clone().add(step.value, step.type);
					range.push({ from: from.clone().valueOf(), to: tempTo.clone().valueOf() });
					from = tempTo;
				}
				return range;
			}

			// Word grouping by two must be done becouse of how char.js handles new lines
			// eslint-disable-next-line no-unused-vars
			function splitByTwoWords(str) {
				const split = str.split(' ');
				if (split.length < 3) return str;
				const result = Array(Math.floor(split.length / 3 + 1)).fill('');
				for (let i = 0; i < split.length; i += 1) {
					result[Math.floor(i / 2)] += ` ${split[i]}`;
				}
				return result;
			}

			// Handle non translated values eg. numbers, unknown strings.
			function handleDefault(str) {
				str = str.replace(/\.0/g, '').replace(/-/g, ' - ').replace(/_/g, ' ');
				return str;
			}

			function parseDate(dateStr) {
				if (dateStr.length > 24) {
					const from = dateStr.substring(0, 24);
					return moment(from).toDate();
				}
				return moment(dateStr).toDate();
			}

			function parseAdjudication(obj, data, translations) {
				data.buckets = data.buckets || {};
				obj.data = [];
				const translationsKeys = Object.keys(translations);
				const tempData = translationsKeys.reduce((acc, current) => { acc[current] = []; return acc; }, {});
				let count = 0;
				Object.keys(data.buckets).forEach((dateObj) => {
					if (data.buckets[dateObj].buckets) {
						count += data.buckets[dateObj].count;
						translationsKeys.forEach((status) => {
							if (data.buckets[dateObj].buckets[status]) {
								tempData[status].push(data.buckets[dateObj].buckets[status].count);
							} else {
								tempData[status].push(0);
							}
						});
					} else {
						translationsKeys.forEach(translationsKey => tempData[translationsKey].push(0));
					}
				});

				obj.data = count > 0 ? Object.values(tempData) : translationsKeys.map(() => []);
				obj.labels = count > 0 ? Object.keys(data.buckets).map(label => parseDate(label)) : translationsKeys.map(() => '');
				obj.count = count;
				obj.series = Object.values(translations);
			}

			function loadDateStatistics(translationsGroup) {
				const ranges = calculateDateRange($scope.filterData.createdAtLower, $scope.filterData.createdAtUpper, $scope.filterData.dateStep);
				const body = {};
				if (ranges.length > 0) {
					body.dateRangeAggregations = [
						{
							elasticField: 'createdAt',
							order: 1,
							ranges
						}
					];
				} else {
					body.autoDateHistogramAggregations = [
						{
							elasticField: 'createdAt',
							order: 1,
							numberOfBuckets: 100
						},
					];
				}

				body[this.type] = this.aggregation;

				filterDataInternal = {};
				copyProcessedFilterData(filterDataInternal);

				$scope.statistic.loading = false;
				$scope.statistic.error = null;
				$scope.statistic.to = $timeout(() => {
					$scope.statistic.loading = true;
				}, 50);

				StatisticsResouce.agregate(filterDataInternal, body).$promise
					.then((results) => {
						const filteredTypes = Object.keys(translationsGroup).reduce((acc, translationKey) => {
							if (filterDataInternal.statuses && filterDataInternal.statuses.some(filterStatus => filterStatus === translationKey)) {
								acc[translationKey] = $scope.statuses[translationKey];
							}
							if (filterDataInternal.types && filterDataInternal.types.some(filterType => filterType === translationKey)) {
								acc[translationKey] = $scope.types[translationKey];
							}
							return acc;
						}, {});
						parseAdjudication($scope.statistic, results, Object.values(filteredTypes).length > 0 ? filteredTypes : translationsGroup);
					})
					.catch((result) => {
						if (result.status === 500) {
							$scope.noStatisticsMessage = result.data.message;
						}
					})
					.finally(() => {
						if (!$timeout.cancel($scope.statistic.to)) {
							$scope.statistic.loading = false;
						}
					});
			}

			function parseData(obj, data) {
				data.buckets = data.buckets ? data.buckets : {};
				obj.data = [Object.values(data.buckets).map(bucket => bucket.count)];
				obj.labels = Object.keys(data.buckets).map(label => ($scope.translationTable[label] ? splitByTwoWords($scope.translationTable[label]) : handleDefault(label)));
				obj.count = data.count;
			}

			function calculateRanges() {
				const result = [];
				for (let i = $scope.filterData.rangeFrom; i < $scope.filterData.rangeTo; i += $scope.filterData.step) {
					// FIXME: Steps validation
					result.push({ from: i, to: i + $scope.filterData.step });
				}
				return result;
			}

			function prepareMatchingFiltering(context) {
				const body = context.aggregation;
				body.forEach((aggregationsObj) => {
					Object.keys(aggregationsObj).forEach((key) => {
						aggregationsObj[key][0].ranges = calculateRanges();
					});
				});

				const finalFilter = {};
				copyProcessedFilterData(finalFilter);

				context.loading = false;
				context.error = null;
				context.to = $timeout(() => {
					context.loading = true;
				}, 50);

				return { finalFilter, body };
			}

			function loadScoresAggregation() {
				function parseScoresData(obj, dataArr) {
					obj.data = context.series.map(() => []);
					obj.labels = [];
					obj.count = 0;

					dataArr.forEach((data, index) => {
						obj.data[index] = Object.values(data.buckets).map(bucket => bucket.count);
					});
					obj.labels = Object.keys(dataArr[0].buckets).map(label => handleDefault(label));
					obj.count += dataArr.map(container => container.count).reduce((count, current) => count + current, 0);
				}

				const context = this;
				const { finalFilter, body } = prepareMatchingFiltering(context);

				return $q.all([
					...Object.keys(body).map(aggregation => StatisticsResouce.agregate(finalFilter, body[aggregation]).$promise),
				])
					.then(result => parseScoresData(context, result))
					.catch((result) => {
						if (result.status === 500) {
							$scope.noStatisticsMessage = result.data.message;
						}

						context.error = 'reports.error';
						context.data = null;
					})
					.finally(() => {
						if (!$timeout.cancel(context.to)) {
							context.loading = false;
						}
					});
			}

			function loadHitsAggregation() {
				const context = this;
				const { finalFilter, body } = prepareMatchingFiltering(context);

				return StatisticsResouce.agregate(finalFilter, body[0], result => parseData(context, result)).$promise
					.catch((result) => {
						if (result.status === 500) {
							$scope.noStatisticsMessage = result.data.message;
						}

						context.error = 'reports.error';
						context.data = null;
					})
					.finally(() => {
						if (!$timeout.cancel(context.to)) {
							context.loading = false;
						}
					});
			}

			$scope.resetFilter = function () {
				$scope.filterData = angular.copy($scope.statistic.filters);
				checkAndSetDateSteps();

				if ($scope.filterForm) {
					$scope.filterForm.$setPristine();
				}
				filterDataInternal = {};
				$scope.statistic.actionFunc();
			};

			$scope.isDataEmpty = function (data) {
				return !data // if there is no data
					|| !data.some((column) => {
						if (Array.isArray(column)) {
							return column.length !== 0 // if there are columns that are not empty, then container is not empty
								&& column.some(elem => elem !== 0); // And if there are columns' elements that are not 0, container is not empty
						}
						return column !== 0; // if element is not 0
					});
			};

			$scope.isMatchingDataEmpty = function (data) {
				return !data || !data.some(column => column !== 0);
			};

			$scope.toDate = function (date) {
				return date ? date.toLocaleString() : date;
			};

			function findTemplate() {
				let template;
				switch ($state.current.name) {
				case 'admin.metrics.deduplication':
					template = require('../../../views/modal/adjudication-generate-report-modal.html');
					break;
				case 'admin.metrics.operations':
					template = require('../../../views/modal/biometrics-generate-report-modal.html');
					break;
				case 'admin.metrics.subjects':
					template = require('../../../views/modal/subjects-generate-report-modal.html');
					break;
				case 'admin.metrics.matching':
					template = require('../../../views/modal/matching-generate-report-modal.html');
					break;
				default:
					throw new Error(`Unrecognized state: ${$state.current.name}`);
				}
				return template;
			}

			$scope.generateReport = function () {
				const scope = $scope.$new(true);
				scope.filterData = $scope.statistic.filters;
				copyProcessedFilterData(scope.filterData);
				scope.tab = $scope.opts.activeTab;
				scope.statusOptions = $scope.statusOptions;
				scope.typesOptions = $scope.typesOptions;

				const template = findTemplate();
				const modalInstance = $uibModal.open({
					template,
					scope,
					controller: 'ReportGenerationCtrl',
					backdrop: 'static',
					animation: false,
					windowClass: 'modal-w-6'
				});

				modalInstance.result.catch((res) => {
					if (!['backdrop click', 'escape key press'].includes(res)) {
						throw new Error(res);
					}
				});
			};

			function getFileName(type) {
				const date = moment().format('YYYYMMDD_HHmmss');
				return `statistics_${type}_${date}`;
			}

			$scope.downloadChart = function () {
				FileSaver.saveAs($scope.chartDownloadData, `${getFileName('chart')}.png`);
			};

			$scope.downloadCSV = function () {
				function createElement() {
					const newElement = document.createElement('a');
					newElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
					newElement.target = '_blank';
					newElement.download = `${getFileName('data')}.csv`;
					return newElement;
				}

				function createCSV() {
					let csv = `${$scope.statistic.csvSeries.join()},`; // Either 'Date' or graph specific header
					csv += `${$scope.statistic.series.join()},`; // Statistics headers
					csv += '\n'; // New line

					$scope.statistic.labels.forEach((label, index) => {
						try {
							csv += `"${$scope.toDate(label)}",`;
						} catch (err) { // If label is not a date
							csv += `"${label}", `;
						}

						$scope.statistic.series.forEach((_status, seriesIndex) => { // Adding data
							const dataArr = $scope.statistic.data[seriesIndex];
							csv += `${dataArr ? dataArr[index] : 0},`;
						});

						csv = csv.replace(/.$/, '\n'); // replacing last comma with new line
					});
					return csv;
				}

				const csv = createCSV();
				const hiddenElement = createElement();
				hiddenElement.click();
			};
		}]);
