import angular from 'angular';
import * as $ from 'jquery';

angular.module('neurotecAbisWebClientApp')
	.directive('datePicker', () => ({
		template: require('../../views/directives/date-picker.html'),
		restrict: 'E',
		replace: true,
		scope: {
			inputId: '@',
			inputName: '@',
			placeholder: '@',
			startAddonText: '@?',
			dateOptions: '=',
			dateModel: '=',
			onChangeCallback: '&?onChange',
			dateDisabled: '=?'
		},
		controllerAs: 'datePickerCtrl',
		bindToController: true,
		controller: ['$scope', '$timeout', '$q', '$translate', 'DatetimePickerService', function ($scope, $timeout, $q, $translate, DatetimePickerService) {
			const self = this;

			self.sendValue = function (value) {
				self.dateModel = value;
			};

			function getInput() {
				return $(`#${self.inputId}`);
			}

			function runAfterRender(func) {
				$timeout(func);
			}

			function onChange(value) {
				self.onChangeCallback?.(value);
				self.sendValue(value);
			}

			function isModelValid() {
				return self.dateModel !== null || self.dateModel !== undefined;
			}

			function showValue(date) {
				getInput().val(date.format(dateFormat));
			}

			function initInternalWatchers() {
				getInput().on('apply.daterangepicker', (_ev, picker) => {
					showValue(picker.startDate);
				});

				getInput().on('cancel.daterangepicker', () => {
					getInput().val('');
					self.sendValue(null);
				});
			}

			function initAttrbuteWatchers() {
				$scope.$watch(angular.bind(self, function () {
					return this.dateModel;
				}), () => {
					if (!isModelValid() || isDateEmpty()) {
						getInput().trigger('cancel.daterangepicker');
					} else {
						getInput().data('daterangepicker').setStartDate(self.dateModel);
						getInput().data('daterangepicker').setEndDate(self.dateModel);
						showValue(self.dateModel);
					}
				}, true);

				$scope.$watch(angular.bind(self, function () {
					return this.dateOptions;
				}), (newValue) => {
					if (newValue) {
						resetElementAndOptions();
						initInternalWatchers();
					}
				}, true);
			}

			function isDateEmpty() {
				return self.dateModel === null || self.dateModel === undefined;
			}

			let dateFormat = 'MM/DD/YYYY';
			let separator = ' - ';
			function initOptions() {
				if (self.datePickerOptionsInternal && self.datePickerOptionsInternal.locale) {
					dateFormat = self.datePickerOptionsInternal.locale.format || dateFormat;
					separator = self.datePickerOptionsInternal.locale.separator || separator;
				}
			}

			function resetElementAndOptions() {
				self.datePickerOptionsInternal = Object.assign({}, self.datePickerOptionsInternal, self.dateOptions);
				getInput().daterangepicker(self.datePickerOptionsInternal, onChange);

				if (self.datePickerOptionsInternal
					&& self.datePickerOptionsInternal.autoUpdateInput !== undefined
					&& !self.datePickerOptionsInternal.autoUpdateInput) {
					getInput().trigger('cancel.daterangepicker');
				}

				initOptions();
			}

			function initRangePickerInternal() {
				if (self.datePickerOptionsInternal) {
					resetElementAndOptions();
				}
				initAttrbuteWatchers();
			}

			function initModelDefaults() {
				self.sendValue(null);
			}


			self.datePickerOptionsInternal = {};
			function loadDateOptions() {
				return DatetimePickerService.getDefaultOptions()
					.then((options) => {
						self.datePickerOptionsInternal = options;
					});
			}

			self.initRangePicker = function () {
				loadDateOptions()
					.then(() => {
						if (isModelValid() && isDateEmpty()) {
							initModelDefaults();
						}
						runAfterRender(initRangePickerInternal);
					});
			};

			self.focusInput = function () {
				if (!self.dateDisabled) {
					getInput().trigger('click');
				}
			};
		}]
	}));
