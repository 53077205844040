import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.service('CommonValues', ['$translate', function ($translate) {
		this.fillUserPermissions = function (map) {
			$translate([
				'admin.common.biometric.enroll',
				'admin.common.biometric.enroll-with-duplicate-check',
				'admin.common.biometric.identify',
				'admin.common.biometric.verify',
				'admin.common.biometric.verify-update',
				'admin.common.biometric.update',
				'admin.common.biometric.delete',

				'admin.common.transaction.view',
				'admin.common.transaction.list',
				'admin.common.transaction.count',
				'admin.common.transaction.report-generation',

				'admin.common.subject.view',
				'admin.common.subject.list',

				'admin.common.encounter.view',
				'admin.common.encounter.list',
				'admin.common.encounter.face-images',
				'admin.common.encounter.finger-images',
				'admin.common.encounter.iris-images',
				'admin.common.encounter.palm-images',
				'admin.common.encounter.signature-images',
				'admin.common.encounter.face-features',
				'admin.common.encounter.finger-features',
				'admin.common.encounter.palm-features',
				'admin.common.encounter.export',

				'admin.common.hits.base',
				'admin.common.hits.details',

				'admin.common.matching-results',

				'admin.common.adjudication.view',
				'admin.common.adjudication.list',
				'admin.common.adjudication.count',
				'admin.common.adjudication.solve',
				'admin.common.adjudication.report',

				'admin.common.user.view',
				'admin.common.user.list',
				'admin.common.user.modify',

				'admin.common.gallery.view',
				'admin.common.gallery.list',
				'admin.common.gallery.modify',
				'admin.common.gallery.delete',

				'admin.common.administration.dashboard',
				'admin.common.administration.tasks',
				'admin.common.administration.metrics',
				'admin.common.administration.loga',
				'admin.common.administration.parameter-modification',
				'admin.common.administration.biographic-field-management',
				'admin.common.administration.role-management',
				'admin.common.administration.licensing',
				'admin.common.administration.development',

				'admin.common.tools.finger-examiner',
				'admin.common.tools.clear-transactions',
				'admin.common.tools.retrospective-modification',
				'admin.common.tools.calculate-nfiq',

				'admin.common.ui-data-import',

				'admin.common.latent-fingerprint-editor'
			]).then((translations) => {
				/* eslint-disable dot-notation */
				map['BIOMETRIC_ENROLL'] = translations['admin.common.biometric.enroll'];
				map['BIOMETRIC_ENROLL_WITH_DUPLICATE_CHECK'] = translations['admin.common.biometric.enroll-with-duplicate-check'];
				map['BIOMETRIC_IDENTIFY'] = translations['admin.common.biometric.identify'];
				map['BIOMETRIC_VERIFY'] = translations['admin.common.biometric.verify'];
				map['BIOMETRIC_VERIFY_UPDATE'] = translations['admin.common.biometric.verify-update'];
				map['BIOMETRIC_UPDATE'] = translations['admin.common.biometric.update'];
				map['BIOMETRIC_DELETE'] = translations['admin.common.biometric.delete'];

				map['TRANSACTION_VIEW'] = translations['admin.common.transaction.view'];
				map['TRANSACTION_LIST'] = translations['admin.common.transaction.list'];
				map['TRANSACTION_COUNT'] = translations['admin.common.transaction.count'];
				map['TRANSACTION_REPORT'] = translations['admin.common.transaction.report-generation'];

				map['SUBJECT_VIEW'] = translations['admin.common.subject.view'];
				map['SUBJECT_LIST'] = translations['admin.common.subject.list'];

				map['ENCOUNTER_VIEW'] = translations['admin.common.encounter.view'];
				map['ENCOUNTER_LIST'] = translations['admin.common.encounter.list'];
				map['ENCOUNTER_FACE_IMAGE'] = translations['admin.common.encounter.face-images'];
				map['ENCOUNTER_FINGER_IMAGE'] = translations['admin.common.encounter.finger-images'];
				map['ENCOUNTER_IRIS_IMAGE'] = translations['admin.common.encounter.iris-images'];
				map['ENCOUNTER_PALM_IMAGE'] = translations['admin.common.encounter.palm-images'];
				map['ENCOUNTER_SIGNATURE_IMAGE'] = translations['admin.common.encounter.signature-images'];
				map['ENCOUNTER_FACE_FEATURES'] = translations['admin.common.encounter.face-features'];
				map['ENCOUNTER_FINGER_FEATURES'] = translations['admin.common.encounter.finger-features'];
				map['ENCOUNTER_PALM_FEATURES'] = translations['admin.common.encounter.palm-features'];
				map['ENCOUNTER_EXPORT'] = translations['admin.common.encounter.export'];

				map['HITS_BASE'] = translations['admin.common.hits.base'];
				map['HITS_DETAILS'] = translations['admin.common.hits.details'];

				map['MATCHING_RESULTS'] = translations['admin.common.matching-results'];

				map['ADJUDICATION_VIEW'] = translations['admin.common.adjudication.view'];
				map['ADJUDICATION_LIST'] = translations['admin.common.adjudication.list'];
				map['ADJUDICATION_COUNT'] = translations['admin.common.adjudication.count'];
				map['ADJUDICATION_SOLVE'] = translations['admin.common.adjudication.solve'];
				map['ADJUDICATION_REPORT'] = translations['admin.common.adjudication.report'];

				map['USER_VIEW'] = translations['admin.common.user.view'];
				map['USER_LIST'] = translations['admin.common.user.list'];
				map['USER_MODIFY'] = translations['admin.common.user.modify'];

				map['GALLERY_VIEW'] = translations['admin.common.gallery.view'];
				map['GALLERY_LIST'] = translations['admin.common.gallery.list'];
				map['GALLERY_MODIFY'] = translations['admin.common.gallery.modify'];
				map['GALLERY_DELETE'] = translations['admin.common.gallery.delete'];

				map['ADMINISTRATION_DASHBOARD'] = translations['admin.common.administration.dashboard'];
				map['ADMINISTRATION_TASKS'] = translations['admin.common.administration.tasks'];
				map['ADMINISTRATION_METRICS'] = translations['admin.common.administration.metrics'];
				map['ADMINISTRATION_LOGS'] = translations['admin.common.administration.loga'];
				map['ADMINISTRATION_PARAMETERS'] = translations['admin.common.administration.parameter-modification'];
				map['ADMINISTRATION_BIOGRAPHIC_FIELDS'] = translations['admin.common.administration.biographic-field-management'];
				map['ADMINISTRATION_ROLES'] = translations['admin.common.administration.role-management'];
				map['ADMINISTRATION_LICENSING'] = translations['admin.common.administration.licensing'];
				map['ADMINISTRATION_DEVELOPMENT'] = translations['admin.common.administration.development'];

				map['TOOLS_FINGER_EXAMINER'] = translations['admin.common.tools.finger-examiner'];
				map['ADMINISTRATION_TOOLS_CLEAR'] = translations['admin.common.tools.clear-transactions'];
				map['ADMINISTRATION_TOOLS_RETROSPECTIVE_MODIFICATION'] = translations['admin.common.tools.retrospective-modification'];
				map['ADMINISTRATION_TOOLS_NFIQ'] = translations['admin.common.tools.calculate-nfiq'];

				map['UI_DATA_IMPORT'] = translations['admin.common.ui-data-import'];

				map['OTHER_LATENT_PRINT'] = translations['admin.common.latent-fingerprint-editor'];
				/* eslint-enable dot-notation */
			});
		};

		this.fillUserModifiers = function (map) {
			$translate([
				'admin.common.modifications.transaction-from-all-users',
				'admin.common.modifications.adjudication-from-all-users',
				'admin.common.modifications.adjudication-supervisor',
				'admin.common.modifications.gallery-list-all'
			]).then((translations) => {
				/* eslint-disable dot-notation */
				map['TRANSACTION_ALL_USERS'] = translations['admin.common.modifications.transaction-from-all-users'];
				map['ADJUDICATION_ALL_USERS'] = translations['admin.common.modifications.adjudication-from-all-users'];
				map['ADJUDICATION_SUPERVISOR'] = translations['admin.common.modifications.adjudication-supervisor'];
				map['GALLERY_LIST_ALL'] = translations['admin.common.modifications.gallery-list-all'];
				/* eslint-enable dot-notation */
			});
		};
	}]);
