import angular from 'angular';
import * as $ from 'jquery';

angular.module('neurotecAbisWebClientApp')
	.controller('PalmComparisonModalCtrl', ['$filter', '$scope', '$q', '$uibModalInstance', '$translate', '$window', 'namingService', 'ConvertionUtils', 'drawingService', 'matchingTreeService', 'store', 'HotkeyService', 'hotkeys', 'blockUI', 'ImageService',
		function ($filter, $scope, $q, $uibModalInstance, $translate, $window, namingService, ConvertionUtils, drawingService, matchingTreeService, store, HotkeyService, hotkeys, blockUI, ImageService) {
			let stage;
			let mouseDown = false;
			let callbackHit;
			let callbackProbe;

			$scope.getAllFeatures = true;
			$scope.wheelMode = 'zoom';
			$scope.wheelStep = 'default';
			$scope.frontPalm = 'hit';
			$scope.overlapped = false;
			$scope.lockMode = false;

			var zoomFactor;
			var rotationStep;

			$scope.$watch('wheelStep', (newValue) => {
				if (newValue === 'precision') {
					zoomFactor = 1.005;
					rotationStep = 0.1;
				} else {
					zoomFactor = 1.05;
					rotationStep = 2;
				}
			});

			function getFeaturesByName(name) {
				if (name === 'probe') {
					return $scope.probe.features;
				} else if (name === 'hit') {
					return $scope.hit.features;
				}
			}

			function getMTStrokeCommandByName(name) {
				if (name === 'probe') {
					return 'index1';
				} else if (name === 'hit') {
					return 'index2';
				}
			}

			function moveShapeToFront(shape) {
				shape.parent.setChildIndex(shape, shape.parent.numChildren - 1);
			}

			$scope.$watch('frontPalm', (newValue, oldValue) => {
				if (newValue !== oldValue) {
					moveShapeToFront(stage.getChildByName(newValue));
					stage.update();
				}
			});

			// HOTKEYS----------------------------------------------------------------------
			var bindings = HotkeyService.getHotkeyBindings();
			function handleHotKeyCallback(hotkey) {
				var currentFront = null;
				if (hotkey === bindings.switchFront) {
					if ($scope.frontPalm === 'hit') {
						$scope.frontPalm = 'probe';
					} else {
						$scope.frontPalm = 'hit';
					}
				} else if (hotkey === bindings.rotateZoomToggle) {
					if ($scope.wheelMode === 'zoom') {
						$scope.wheelMode = 'rotation';
					} else {
						$scope.wheelMode = 'zoom';
					}
				} else if (hotkey === bindings.moveLeft) {
					currentFront = stage.getChildByName($scope.frontPalm);
					currentFront.x -= 1;
					stage.update();
				} else if (hotkey === bindings.moveRight) {
					currentFront = stage.getChildByName($scope.frontPalm);
					currentFront.x += 1;
					stage.update();
				} else if (hotkey === bindings.moveUp) {
					currentFront = stage.getChildByName($scope.frontPalm);
					currentFront.y -= 1;
					stage.update();
				} else if (hotkey === bindings.moveDown) {
					currentFront = stage.getChildByName($scope.frontPalm);
					currentFront.y += 1;
					stage.update();
				} else {
				// Do nothing
				}
			}

			function setHotkeys() {
				function setHotkeySwitchFront(newHotkey) {
					hotkeys.bindTo($scope).add({
						combo: newHotkey,
						description: 'Switch front palmprint.',
						callback() { handleHotKeyCallback(newHotkey); }
					});
				}

				function setHotkeyRotateZoomToggle(newHotkey) {
					hotkeys.bindTo($scope).add({
						combo: newHotkey,
						description: 'Toggle rotation or zoom',
						callback() { handleHotKeyCallback(newHotkey); }
					});
				}

				function setHotkeyMoveLeft(newHotkey) {
					hotkeys.bindTo($scope).add({
						combo: newHotkey,
						description: 'Move image left.',
						callback() { handleHotKeyCallback(newHotkey); }
					});
				}

				function setHotkeyMoveRight(newHotkey) {
					hotkeys.bindTo($scope).add({
						combo: newHotkey,
						description: 'Move image right.',
						callback() { handleHotKeyCallback(newHotkey); }
					});
				}

				function setHotkeyMoveUp(newHotkey) {
					hotkeys.bindTo($scope).add({
						combo: newHotkey,
						description: 'Move image up.',
						callback() { handleHotKeyCallback(newHotkey); }
					});
				}
				function setHotkeyMoveDown(newHotkey) {
					hotkeys.bindTo($scope).add({
						combo: newHotkey,
						description: 'Move image down.',
						callback() { handleHotKeyCallback(newHotkey); }
					});
				}

				if (bindings) {
					setHotkeySwitchFront(bindings.switchFront);
					setHotkeyRotateZoomToggle(bindings.rotateZoomToggle);
					setHotkeyMoveUp(bindings.moveUp);
					setHotkeyMoveDown(bindings.moveDown);
					setHotkeyMoveLeft(bindings.moveLeft);
					setHotkeyMoveRight(bindings.moveRight);
				}
			}
			//------------------------------------------------------------------------------

			function changeVisibility(container, check, visibility) {
				for (var i = 0; i < container.numChildren; i += 1) {
					var child = container.getChildAt(i);
					if (check(child.name)) {
						child.visible = visibility;
					} else if (child instanceof window.createjs.Container) {
						changeVisibility(child, check, visibility);
					}
				}
			}
			function changeImage(palm, imageName) {
				var container = palm.getChildByName('image');
				var foundOne = false;
				for (var i = 0; i < container.numChildren; i += 1) {
					var child = container.getChildAt(i);
					if (child.name === imageName) {
						child.visible = true;
						foundOne = true;
					} else {
						child.visible = false;
					}
				}

				if (foundOne || imageName === 'NONE') {
					container.updateCache();
				} else {
					return getPalmImageUrl($scope[palm.name], imageName).then(() => {
						var image = new Image();
						image.onload = function () {
							var bitmap = new window.createjs.Bitmap(image);
							bitmap.name = imageName;
							container.addChild(bitmap);
							container.updateCache();
							stage.update();
							var bounds = palm.getBounds();
							palm.compositeOperation = 'darken';
							palm.cache(bounds.x, bounds.y, bounds.width, bounds.height);
						};
						image.src = $scope[palm.name].imageUrl[imageName];
					});
				}
			}

			function changeFilter(palmName) {
				const image = stage.getChildByName(palmName).getChildByName('image');

				const { color } = $scope[`${palmName}Options`];
				const r = parseInt(color.substr(1, 2), 16);
				const g = parseInt(color.substr(3, 2), 16);
				const b = parseInt(color.substr(5, 2), 16);

				const a = $scope[`${palmName}Options`].alpha;
				const matrix = new window.createjs.ColorMatrix().adjustContrast($scope[`${palmName}Options`].contrast).adjustBrightness($scope[`${palmName}Options`].brightness);

				image.filters = [
					new window.createjs.ColorFilter(1, 1, 1, a, r, g, b),
					new window.createjs.ColorMatrixFilter(matrix)
				];
				image.updateCache();
				const container = stage.getChildByName(palmName);
				container.compositeOperation = 'darken';
				container.updateCache();
			}

			function drawingOptionsChanged(name, newValue, oldValue) {
				if (oldValue == null) {
					oldValue = {};
				}
				if (newValue.image !== oldValue.image) {
					changeImage(stage.getChildByName(name), newValue.image);
				}
				if (newValue.color !== oldValue.color || newValue.alpha !== oldValue.alpha || newValue.brightness !== oldValue.brightness || newValue.contrast !== oldValue.contrast) {
					changeFilter(name);
				}
				if (newValue.overlayColor !== oldValue.overlayColor) {
					drawingService.getMTStrokeCommands()[getMTStrokeCommandByName(name)].style = drawingService.getDefaultDarkerHexColor(newValue.overlayColor);
					drawFeatures(stage.getChildByName(name), getFeaturesByName(name));
				}
				if (newValue.drawMatchingTree !== oldValue.drawMatchingTree) {
					changeVisibility(stage.getChildByName(name), namingService.isTreeName, newValue.drawMatchingTree);
				}
			}

			function genDefaultDrawingOptions() {
				return {
					image: 'ORIGINAL',
					color: '#000000',
					alpha: 1,
					brightness: 0,
					contrast: 0,
					drawMatchingTree: true,
					drawFeaturesMaster: true,
					drawMinutiae: true,
					drawCores: true,
					drawDeltas: true,
					drawDoubleCores: true,
					overlayColor: '#FF0000'
				};
			}

			function initDrawingOptions(name) {
				$scope[`${name}Options`] = genDefaultDrawingOptions();
				$scope.$watch(`${name}Options`, (newValue, oldValue) => {
					if (newValue !== oldValue) {
						drawingOptionsChanged(name, newValue, oldValue);
						stage.update();
						store.set(`${name}Options`, $scope[`${name}Options`]);
					}
				}, true);
			}

			function initCustomDrawingOptions() {
				if (store.get('probeOptions')) {
					Object.assign($scope.probeOptions, genDefaultDrawingOptions(), store.get('probeOptions'));
				}
				if (store.get('hitOptions')) {
					Object.assign($scope.hitOptions, genDefaultDrawingOptions(), store.get('hitOptions'));
				}

				// Override image settings in case palm has no images
				// FIXME: Think of a way to restore last image type user preference after preview of palm with no images
				if ($scope.probe.forceImageType === 'NONE') {
					$scope.probeOptions.image = 'NONE';
					$scope.probeOptions.hasNoImage = true;
				} else {
					$scope.probeOptions.image = 'ORIGINAL';
					delete $scope.probeOptions.hasNoImage;
				}
				if ($scope.hit.forceImageType === 'NONE') {
					$scope.hitOptions.image = 'NONE';
					$scope.hitOptions.hasNoImage = true;
				} else {
					$scope.hitOptions.image = 'ORIGINAL';
					delete $scope.hitOptions.hasNoImage;
				}
			}

			initDrawingOptions('probe');
			initDrawingOptions('hit');

			const cachedChildren = {
				probe: {
					needScale: false,
					lastScale: 0
				},
				hit: {
					needScale: false,
					lastScale: 0
				},
			};
			function enableWheelActions() {
				function mouseWheel(event) {
					let scale;
					let rotation;
					if (event.deltaY < 0) {
						scale = zoomFactor;
						rotation = rotationStep;
					} else {
						scale = 1 / zoomFactor;
						rotation = -rotationStep;
					}

					let child;
					let local;
					for (let i = stage.numChildren - 1; i >= 0; i -= 1) {
						child = stage.getChildAt(i);
						local = child.globalToLocal(stage.mouseX, stage.mouseY);
						if (($scope.wheelMode === 'zoom' && $scope.lockMode) || child.getChildByName('image').hitArea.hitTest(local.x, local.y)) {
							child.regX = local.x;
							child.regY = local.y;
							child.x = stage.mouseX;
							child.y = stage.mouseY;

							if ($scope.wheelMode === 'zoom') {
								child.scaleY *= scale;
								child.scaleX = child.scaleY;
								if (Math.floor(child.scaleX) % 4 === 0 && Math.floor(child.scaleX) > cachedChildren[child.name].lastScale) {
									cachedChildren[child.name].needScale = true;
									cachedChildren[child.name].lastScale = Math.floor(child.scaleX);
								}
							} else {
								child.rotation += rotation;
							}

							if (!($scope.wheelMode === 'zoom' && $scope.lockMode)) {
								break;
							}
						}
					}

					$scope.$digest();
					Object.keys(cachedChildren).forEach((childName) => {
						if (cachedChildren[childName].needScale) {
							stage.getChildByName(childName).updateCache();
							cachedChildren[childName].needScale = false;
						}
					});
					stage.update();
				}

				stage.canvas.addEventListener('dblclick', () => {
					$scope.$apply((scope) => {
						if (scope.wheelMode === 'zoom') {
							scope.wheelMode = 'rotation';
						} else {
							scope.wheelMode = 'zoom';
						}
					});
				});
				const canvasElem = document.getElementById('canvas');
				canvasElem.addEventListener('wheel', (event) => {
					event.stopPropagation();
					mouseWheel(event);
				}, { capture: false, passive: true });
			}

			function resizeCanvas(canvas) {
				var parent = $(canvas).parent();
				canvas.width = parent.width();
				canvas.height = parent.height();
			}

			function init() {
				var canvas = document.getElementById('canvas');
				resizeCanvas(canvas);

				if (stage) {
					stage.removeAllChildren();
					stage.update();
				}

				stage = new window.createjs.Stage(canvas);

				enableWheelActions();
			}

			$scope.overlap = function () {
				var probe = stage.getChildByName('probe');
				var hit = stage.getChildByName('hit');
				$scope.frontPalm = 'hit';

				var positionOld = probe.localToGlobal(0, 0);
				probe.regX = 0;
				probe.regY = 0;

				probe.scaleX = probe.scaleY;

				var positionNew = probe.localToGlobal(0, 0);
				probe.x += positionOld.x - positionNew.x;
				probe.y += positionOld.y - positionNew.y;
				hit.x += positionOld.x - positionNew.x;
				hit.y += positionOld.y - positionNew.y;

				hit.regX = $scope.matchingDetails.centerX;
				hit.regY = $scope.matchingDetails.centerY;
				hit.scaleX = probe.scaleX;
				hit.scaleY = probe.scaleY;
				hit.rotation = probe.rotation + ConvertionUtils.radiansToDegress($scope.matchingDetails.rotation);
				var compoundTranslationX = ($scope.matchingDetails.centerX + $scope.matchingDetails.translationX) * Math.cos(ConvertionUtils.degreesToRadians(probe.rotation)) - ($scope.matchingDetails.centerY + $scope.matchingDetails.translationY) * Math.sin(ConvertionUtils.degreesToRadians(probe.rotation));
				var compoundTranslationY = ($scope.matchingDetails.centerX + $scope.matchingDetails.translationX) * Math.sin(ConvertionUtils.degreesToRadians(probe.rotation)) + ($scope.matchingDetails.centerY + $scope.matchingDetails.translationY) * Math.cos(ConvertionUtils.degreesToRadians(probe.rotation));
				hit.x = probe.x + (compoundTranslationX) * hit.scaleX;
				hit.y = probe.y + (compoundTranslationY) * hit.scaleY;

				$scope.overlapped = true;

				stage.update();
			};

			$scope.reset = function () {
				var halfCanvasWidth = stage.canvas.width / 2;

				var probe = stage.getChildByName('probe');
				var hit = stage.getChildByName('hit');

				var probeBounds = probe.getChildByName('image').getBounds();
				var hitBounds = hit.getChildByName('image').getBounds();

				var scaleProbe = Math.min(halfCanvasWidth / probeBounds.width, stage.canvas.height / probeBounds.height);
				var scaleCandidate = Math.min(halfCanvasWidth / hitBounds.width, stage.canvas.height / hitBounds.height);

				probe.regX = 0;
				probe.regY = 0;
				hit.regX = 0;
				hit.regY = 0;

				probe.scaleX = scaleProbe;
				probe.scaleY = scaleProbe;
				hit.scaleX = scaleCandidate;
				hit.scaleY = scaleCandidate;

				probe.rotation = 0;
				hit.rotation = 0;

				probe.x = (halfCanvasWidth - probeBounds.width * scaleProbe) / 2 - probeBounds.x * scaleProbe;
				probe.y = (stage.canvas.height - probeBounds.height * scaleProbe) / 2 - probeBounds.y * scaleProbe;
				hit.x = halfCanvasWidth + (halfCanvasWidth - hitBounds.width * scaleCandidate) / 2 - hitBounds.x * scaleCandidate;
				hit.y = (stage.canvas.height - hitBounds.height * scaleCandidate) / 2 - hitBounds.y * scaleCandidate;

				$scope.overlapped = false;

				stage.update();
			};

			function getPalmImageUrl(palm, type) {
				if (palm.imageUrl == null) {
					palm.imageUrl = {};
				}

				function inflate(value) {
					const INFLATE_RATIO = 2.2;
					return value * INFLATE_RATIO;
				}

				return palm.getImageUrl(type).then((imageUrl) => {
					palm.imageUrl[type] = imageUrl;
				}, (response) => {
					if (response.status === 404) {
						var maxX = Math.max(...palm.features.minutiae.map(obj => obj.x));
						var maxY = Math.max(...palm.features.minutiae.map(obj => obj.y));
						var canvas = document.createElement('canvas');
						canvas.width = inflate(maxX);
						canvas.height = maxY;
						palm.imageUrl[type] = canvas.toDataURL('image/png', 0.1);
						palm.forceImageType = 'NONE';
					} else {
						throw new Error('Internal error');
					}
				});
			}

			function getPalmFeatures(palm) {
				return palm.getFeatures($scope.getAllFeatures).then((features) => {
					palm.features = features;
				});
			}

			function getMatchingDetails() {
				return $scope.getMatchingDetails()
					.then((matchingDetails) => {
						$scope.matchingDetails = matchingDetails;
					});
			}

			function createPalm(palm) {
				const deferred = $q.defer();
				const container = new window.createjs.Container();

				const imageContainer = new window.createjs.Container();
				imageContainer.name = 'image';

				const name = 'ORIGINAL';

				const image = new Image();
				image.onload = function () {
					const trimmedBounds = ImageService.getImageBoundsWithoutAlpha(image, this.width, this.height);
					let imageBounds;
					if (trimmedBounds.width !== 0 || trimmedBounds.height !== 0) {
						palm.hasImage = true;
						imageBounds = {
							x: trimmedBounds.left.x,
							y: trimmedBounds.top.y,
							width: trimmedBounds.width,
							height: trimmedBounds.height,
						};
					} else {
						palm.hasImage = false;
						const processedBounds = ImageService.getImageBoundsFromMinutia(palm.features.minutiae);
						imageBounds = {
							x: processedBounds.left.x,
							y: processedBounds.top.y,
							width: processedBounds.width,
							height: processedBounds.height,
						};
					}

					const bitmap = new window.createjs.Bitmap(this);
					bitmap.name = name;
					imageContainer.hitArea = drawingService.createRectangleHitArea(imageBounds);
					imageContainer.addChild(bitmap);
					imageContainer.cache(0, 0, bitmap.image.width, bitmap.image.height);
					container.addChild(imageContainer);
					deferred.resolve(container);
					stage.update();
				};
				image.src = palm.imageUrl[name];

				return deferred.promise;
			}

			function drawFeatures(container, features) {
				let i;
				let shape;

				if (features.minutiae != null) {
					for (i = 0; i < features.minutiae.length; i += 1) {
						shape = drawingService.createMinutia(features.minutiae[i], i, drawingService.getMinutiaColor(i, $scope.matchingDetails.matedMinutiae, container.name));
						shape.name = namingService.minutiaIndexToName(i);
						container.addChild(shape);
					}
				}
				container.compositeOperation = 'darken';
				container.updateCache();
			}

			function createPalmActions() {
				function pressMove() {
					if ($scope.lockMode) {
						for (let i = 0; i < stage.children.length; i += 1) {
							stage.children[i].x = stage.mouseX;
							stage.children[i].y = stage.mouseY;
						}
					} else {
						const child = stage.getChildAt(stage.numChildren - 1);
						child.x = stage.mouseX;
						child.y = stage.mouseY;
					}
					stage.update();
				}

				return () => {
					if (mouseDown) {
						let child;
						let local;
						for (var i = stage.numChildren - 1; i >= 0; i -= 1) {
							child = stage.getChildAt(i);
							local = child.globalToLocal(stage.mouseX, stage.mouseY);
							if (child.getChildByName('image').hitArea.hitTest(local.x, local.y)) {
								pressMove();
								break;
							}
						}
					}
				};
			}

			$translate(['comparison.palm.wheel.step.on', 'comparison.palm.wheel.step.off',
				'comparison.palm.lock-mode.on', 'comparison.palm.lock-mode.off',
			]).then((translations) => {
				$scope.comparison = {
					wheelStep: {
						off: translations['comparison.palm.wheel.step.off'],
						on: translations['comparison.palm.wheel.step.on']
					},
					lockMode: {
						on: translations['comparison.palm.lock-mode.on'],
						off: translations['comparison.palm.lock-mode.off']
					},
				};
			});

			function draw() {
				blockUI.start('app.loading');
				$q.all([
					createPalm($scope.probe),
					createPalm($scope.hit)
				]).then((person) => {
					var probe = person[0];
					probe.name = 'probe';
					var hit = person[1];
					hit.name = 'hit';

					var edgesMST = matchingTreeService.calculateMST($scope.matchingDetails.matedMinutiae, 'index1', $scope.probe.features.minutiae);
					var probeTree = drawingService.createMatchingTree(edgesMST, $scope.matchingDetails.matedMinutiae, 'index1', $scope.probe.features.minutiae, drawingService.getDefaultDarkerHexColor($scope.probeOptions.overlayColor));
					probeTree.name = namingService.getTreeName();
					probe.addChild(probeTree);

					var hitTree = drawingService.createMatchingTree(edgesMST, $scope.matchingDetails.matedMinutiae, 'index2', $scope.hit.features.minutiae, drawingService.getDefaultDarkerHexColor($scope.hitOptions.overlayColor));
					hitTree.name = namingService.getTreeName();
					hit.addChild(hitTree);

					const probeBounds = probe.getBounds();
					const hitBounds = hit.getBounds();
					probe.cache(probeBounds.x, probeBounds.y, probeBounds.width, probeBounds.height, 3);
					hit.cache(hitBounds.x, hitBounds.y, hitBounds.width, hitBounds.height, 3);

					drawFeatures(probe, $scope.probe.features);
					drawFeatures(hit, $scope.hit.features);

					callbackProbe = createPalmActions();
					callbackHit = createPalmActions();

					document.getElementById('canvas').addEventListener('mousemove', () => { callbackProbe(); callbackHit(); }, {
						capture: false,
						passive: true
					});

					stage.addChild(probe);
					stage.addChild(hit);

					moveShapeToFront(stage.getChildByName($scope.frontPalm));
					stage.update();
				}).finally(() => {
					blockUI.stop();
					initCustomDrawingOptions();
					setHotkeys();
					$scope.reset();
					$(window).on('resize', onWindowResize);
				});
			}

			function onWindowResize() {
				resizeCanvas(stage.canvas);
				stage.update();
			}

			$uibModalInstance.rendered.then(() => {
				init();
				$q.all([
					getPalmFeatures($scope.probe), getPalmFeatures($scope.hit)
				]).then(() => {
					blockUI.start('app.loading');
					$q.all([
						getPalmImageUrl($scope.probe, 'ORIGINAL'), getPalmImageUrl($scope.hit, 'ORIGINAL'),
						getMatchingDetails()
					]).then(() => {
						draw();
						document.getElementById('canvas').addEventListener('mousedown', () => {
							function assignCoordinatesToChild(child, local) {
								child.regX = local.x;
								child.regY = local.y;
								child.x = stage.mouseX;
								child.y = stage.mouseY;
							}

							let local;
							const oldChild = stage.getChildAt(stage.numChildren - 1);
							let selectedChild = oldChild;
							let childImg;
							for (let i = stage.numChildren - 1; i >= 0; i -= 1) {
								selectedChild = stage.getChildAt(i);
								local = selectedChild.globalToLocal(stage.mouseX, stage.mouseY);
								childImg = selectedChild.getChildByName('image');
								if (childImg.hitArea.hitTest(local.x, local.y)) {
									assignCoordinatesToChild(selectedChild, local);
									mouseDown = true;
									if (!$scope.lockMode) {
										break;
									}
								} else if ($scope.lockMode) {
									assignCoordinatesToChild(selectedChild, local);
								} else {
									selectedChild = oldChild;
								}
							}
							if (!$scope.lockMode) {
								moveShapeToFront(selectedChild);
								$scope.$apply((scope) => {
									scope.frontPalm = selectedChild.name;
								});
							}
						});
						document.getElementById('canvas').addEventListener('mouseup', () => {
							mouseDown = false;
						});
					}).finally(() => {
						blockUI.stop();
					});
				});
			});

			$uibModalInstance.closed.then(() => {
				$(window).off('resize', onWindowResize);
			});

			$scope.onChangeWheelStep = function (wheelStepBool) {
				$scope.wheelStep = wheelStepBool ? 'precision' : 'default';
			};

			$scope.getProbeExportImageUrl = function () {
				return $scope.probe.getImageUrl('ORIGINAL');
			};
			$scope.getHitExportImageUrl = function () {
				return $scope.hit.getImageUrl('ORIGINAL');
			};
		}]);
