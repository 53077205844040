import angular from 'angular';

angular.module('neurotecAbisWebClientApp')
	.controller('DeviceServerCtrl', ['$scope', '$translate', '$timeout', '$q', 'DeviceServerParametersResource', 'AuthDataHolder', 'TaskPollerService', 'DeviceServerResourceAdmin', 'DeviceServerParametersService',
		function ($scope, $translate, $timeout, $q, DeviceServerParametersResource, AuthDataHolder, TaskPollerService, DeviceServerResourceAdmin, DeviceServerParametersService) {
			$scope.firstTime = false;
			$scope.hasAnyAuthority = (...params) => AuthDataHolder.hasAnyAuthority(...params);
			$scope.parametersAlerts = [];
			$scope.opts = {
				activeTab: 'tab-init'
			};
			let selectedID = 0;

			function onTabOpen() {
				$timeout(() => {
					$scope.$broadcast('rzSliderForceRender');
				});
			}

			$scope.$watch('opts.activeTab', () => {
				$scope.parametersAlerts = [];
				onTabOpen();
			});

			const DEVICE_STATUSES = {
				disconnected: 'admin.configuration.device-server.status.disconnected',
				connecting: 'admin.configuration.device-server.status.connecting',
				connected: 'admin.configuration.device-server.status.connected',
				sameVersions: 'admin.configuration.device-server.status.same-versions',
				versionDowngrade: 'admin.configuration.device-server.status.version-downgrade'
			};
			$scope.deviceConfig = {
				port: 8001,
				autoUpdate: false,
				status: DEVICE_STATUSES.disconnected,
			};

			let reloadInterval;
			$scope.$on('$destroy', () => {
				stopPolling();
			});

			function poll(call, method) {
				if (method == null) {
					method = 'finally';
				}
				(function refresh() {
					call()[method](() => {
						if (reloadInterval !== null) {
							reloadInterval = $timeout(() => {
								refresh();
							}, 5000);
						}
					});
				}());
			}

			function stopPolling() {
				$timeout.cancel(reloadInterval);
				reloadInterval = null;
			}

			function isObjectEmpty(obj) {
				return Object.keys(obj).length === 0 && obj.constructor === Object;
			}

			const PARAMETER = function (key, type, value = '', defaultValue = '', values = [], translation = '', isDisabled = null, edit = false, savedValue = null, additionalProperties = {}) {
				let newObj = {
					key,
					type,
					value,
					defaultValue,
					edit,
					savedValue,
					translation,
					isDisabled
				};

				if (!isObjectEmpty(additionalProperties)) {
					newObj = Object.assign({}, newObj, additionalProperties);
				}

				if (values.length > 0) {
					newObj.values = values;
				}

				return newObj;
			};

			const ADDITIONAL_NUMBER_PARAMETERS = function (min = null, max = null, obj = {}) {
				return Object.assign(obj, {
					min,
					max
				});
			};

			const FACE_PARAMETER_TYPE = function (type, obj = {}) {
				return Object.assign(obj, { parameterType: type });
			};

			$scope.translationsMap = new Map([
				['AutoDropLRUSessions', 'admin.configuration.device-server.parameters.init.auto-drop-lru-sessions'],
				['CaptureTimeout', 'admin.configuration.device-server.parameters.init.capture-timeout'],
				['InactivityTimeout', 'admin.configuration.device-server.parameters.init.inactivity-timeout'],
				['LRUCaptureDataAutomaticallyDropped', 'admin.configuration.device-server.parameters.init.lru-capture-data-automatically-dropped'],
				['LastUpdated', 'admin.configuration.device-server.parameters.init.last-updated'],
				['LockExpireTimeout', 'admin.configuration.device-server.parameters.init.lock-expire-timeout'],
				['LockStealingPreventionPeriod', 'admin.configuration.device-server.parameters.init.lock-stealing-prevention-period'],
				['LoggingLevel', 'admin.configuration.device-server.parameters.init.logging-level'],
				['MaximumConcurrentSessions', 'admin.configuration.device-server.parameters.init.maximum-concurrent-sessions'],
				['MaximumStorageCapacity', 'admin.configuration.device-server.parameters.init.maximum-storage-capacity'],
				['LicensingServer', 'admin.configuration.device-server.parameters.init.licensing-server'],
				['LicensingPort', 'admin.configuration.device-server.parameters.init.licensing-port'],
				['AdditionalLicenses', 'admin.configuration.device-server.parameters.init.additional-licenses'],
				['NfiqQuality', 'admin.configuration.device-server.parameters.init.nfiq-quality'],
				['Nfiq2Quality', 'admin.configuration.device-server.parameters.init.nfiq2-quality'],
				['Nfiq21Quality', 'admin.configuration.device-server.parameters.init.nfiq21-quality'],
				['CheckForDuplicates', 'admin.configuration.device-server.parameters.init.check-for-duplicates'],
				['Off', 'admin.configuration.device-server.parameters.off'],
			]);

			$scope.clickDeviceParameters = function () {
				$scope.opts.activeTab = 'tab-device';
				if ($scope.deviceServer.deviceParameters.length !== 0) {
					const index = $scope.deviceServer.deviceParameters.length - 1;
					$scope.setField($scope.deviceServer.deviceParameters[index], index);
				}
			};

			function isThresholdDisabled(parameterGroup, thresholdToggleName) {
				return !parameterGroup.find(param => param.key === thresholdToggleName).value;
			}

			$scope.deviceServer = {
				init: [],
				fingers: [
					PARAMETER(
						'Fingers.QualityAlgorithm', 'multi-select', 'NfiqQuality', 'NfiqQuality',
						['NfiqQuality', 'Nfiq2Quality', 'Nfiq21Quality', 'Off'], 'admin.configuration.device-server.parameters.fingers.quality-algorithm'
					),
					PARAMETER('Fingers.CheckForDuplicates', 'checkbox', false, false, [], 'CheckForDuplicates'),
				],
				palms: [],
				faces: {
					capture: [
					// Disabled for now
					// PARAMETER(
					// 	'Faces.DetectProperties', 'checkbox', true, true, [], 'Detect Properties', null, false, null,
					// 	FACE_PARAMETER_TYPE('capture', { tooltip: 'Enable/disable detection of glasses, dark glasses, blinking, open mouth, beard, mustaches and hat' })
					// ),
						PARAMETER('Faces.DetermineGender', 'checkbox', false, false, [], 'admin.configuration.device-server.parameters.faces.determine-gender', null, false, null, FACE_PARAMETER_TYPE('capture')),
						PARAMETER('Faces.RecognizeExpression', 'checkbox', true, true, [], 'admin.configuration.device-server.parameters.faces.recognize-expression', null, false, null, FACE_PARAMETER_TYPE('capture')),
						PARAMETER('Faces.RecognizeEmotion', 'checkbox', true, true, [], 'admin.configuration.device-server.parameters.faces.recognize-emotion', null, false, null, FACE_PARAMETER_TYPE('capture')),
						PARAMETER('Faces.DetermineAge', 'checkbox', false, false, [], 'admin.configuration.device-server.parameters.faces.determine-age', null, false, null, FACE_PARAMETER_TYPE('capture')),
						PARAMETER('Faces.DetermineEthnicity', 'checkbox', false, false, [], 'admin.configuration.device-server.parameters.faces.determine-ethnicity', null, false, null, FACE_PARAMETER_TYPE('capture')),
					],
					icao: [
						PARAMETER('Faces.CheckIcaoCompliance', 'checkbox', true, true, [], 'admin.configuration.device-server.parameters.faces.check-icao-compliance', null, false, null, FACE_PARAMETER_TYPE('icao')),
						PARAMETER(
							'Faces.IcaoMaximalRoll', 'number', 10, 10, [], 'admin.configuration.device-server.parameters.faces.icao-maximal-roll',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 180))
						),
						PARAMETER(
							'Faces.IcaoMaximalYaw', 'number', 10, 10, [], 'admin.configuration.device-server.parameters.faces.icao-maximal-yaw',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 180))
						),
						PARAMETER(
							'Faces.IcaoMaximalPitch', 'number', 10, 10, [], 'admin.configuration.device-server.parameters.faces.icao-maximal-pitch',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 180))
						),
						PARAMETER(
							'Faces.IcaoSaturationThreshold', 'number', 50, 50, [], 'admin.configuration.device-server.parameters.faces.icao-saturation-threshold',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoSharpnessThreshold', 'number', 50, 50, [], 'admin.configuration.device-server.parameters.faces.icao-sharpness-threshold',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoBackgroundUniformityThreshold', 'number', 50, 50, [], 'admin.configuration.device-server.parameters.faces.icao-background-uniformity-threshold',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoGrayscaleDensityThreshold', 'number', 50, 50, [], 'admin.configuration.device-server.parameters.faces.icao-grayscale-density-threshold',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoLookingAwayThreshold', 'number', 50, 50, [], 'admin.configuration.device-server.parameters.faces.icao-looking-away-threshold',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoRedEyeThreshold', 'number', 50, 50, [], 'admin.configuration.device-server.parameters.faces.icao-red-eye-threshold',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoFaceDarknessThreshold', 'number', 50, 50, [], 'admin.configuration.device-server.parameters.faces.icao-face-darkness-threshold',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoUnnaturalSkinToneThreshold', 'number', 30, 30, [], 'admin.configuration.device-server.parameters.faces.icao-unnatural-skin-tone-threshold',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoWashedOutThreshold', 'number', 50, 50, [], 'admin.configuration.device-server.parameters.faces.icao-washed-out-threshold',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoPixelationThreshold', 'number', 50, 50, [], 'admin.configuration.device-server.parameters.faces.icao-pixelation-threshold',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoSkinReflectionThreshold', 'number', 30, 30, [], 'admin.configuration.device-server.parameters.faces.icao-skin-reflection-threshold',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoGlassesReflectionThreshold', 'number', 50, 50, [], 'admin.configuration.device-server.parameters.faces.icao-glasses-reflection-threshold',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao', ADDITIONAL_NUMBER_PARAMETERS(0, 100))
						),
						PARAMETER(
							'Faces.IcaoRemoveRedEye', 'checkbox', true, true, [], 'admin.configuration.device-server.parameters.faces.icao-remove-red-eye',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao')
						),
						PARAMETER(
							'Faces.IcaoRemoveBackground', 'checkbox', false, false, [], 'admin.configuration.device-server.parameters.faces.icao-remove-background',
							() => isThresholdDisabled($scope.deviceServer.faces.icao, 'Faces.CheckIcaoCompliance'), false, null, FACE_PARAMETER_TYPE('icao')
						),
					],
				},
				deviceParameters: [],
			};

			function tryParseIfNumber(container, value) {
				if (container.type === 'number') {
					return parseInt(value, 10);
				}
				return value;
			}

			function init() {
				DeviceServerParametersResource.getParameters({ names: [] }).$promise
					.then((results) => {
						if (results.length === 0) {
							$scope.synchronize(true);
							return;
						}

						let devicesList = [];
						const devicesParametersArray = [];
						results = results.reduce((arr, param) => {
							if (param.name.includes('device-parameters')) {
								devicesParametersArray.push(param);
							} else if (param.name === 'devices') {
								devicesList = param.value;
							} else {
								arr.push(param);
							}
							return arr;
						}, []);

						let fingersGroupIndex;
						let facesCaptureGroupIndex;
						let facesIcaoGroupIndex;
						results.forEach((rawResult) => {
							if (rawResult.name === 'LastUpdated' || rawResult.name === 'version') return;

							const result = {
								name: rawResult.name,
								value: rawResult.value
							};

							const resultTranslation = result.name.includes('.') ? result.name.split('.')[1] : result.name;
							fingersGroupIndex = $scope.deviceServer.fingers.findIndex(param => param.key === result.name);
							facesCaptureGroupIndex = $scope.deviceServer.faces.capture.findIndex(param => param.key === result.name);
							facesIcaoGroupIndex = $scope.deviceServer.faces.icao.findIndex(param => param.key === result.name);

							if (fingersGroupIndex !== -1) {
								$scope.deviceServer.fingers[fingersGroupIndex].value = result.value.value;
								$scope.deviceServer.fingers[fingersGroupIndex].defaultValue = result.value.defaultValue;
								$scope.deviceServer.fingers[fingersGroupIndex].savedValue = result.value.value;
								$scope.deviceServer.fingers[fingersGroupIndex].translation = !$scope.deviceServer.fingers[fingersGroupIndex].translation
									? resultTranslation
									: $scope.deviceServer.fingers[fingersGroupIndex].translation;
								if (result.value === null) $scope.deviceServer.fingers[fingersGroupIndex].edit = true;
							} else if (facesCaptureGroupIndex !== -1) {
								$scope.deviceServer.faces.capture[facesCaptureGroupIndex].value = result.value.value;
								$scope.deviceServer.faces.capture[facesCaptureGroupIndex].defaultValue = result.value.defaultValue;
								$scope.deviceServer.faces.capture[facesCaptureGroupIndex].savedValue = result.value.value;
								if (result.value === null) $scope.deviceServer.faces.capture[facesCaptureGroupIndex].edit = true;
							} else if (facesIcaoGroupIndex !== -1) {
								$scope.deviceServer.faces.icao[facesIcaoGroupIndex].value = result.value.value;
								$scope.deviceServer.faces.icao[facesIcaoGroupIndex].defaultValue = result.value.defaultValue;
								$scope.deviceServer.faces.icao[facesIcaoGroupIndex].savedValue = result.value.value;
								if (result.value === null) $scope.deviceServer.faces.icao[facesIcaoGroupIndex].edit = true;
							} else {
								const value = (result.value.values && result.value.values.length > 0) ? result.value.values[result.value.values.indexOf(result.value.value)] : result.value.value;
								$scope.deviceServer.init.push(PARAMETER(result.name, result.value.type, value, result.value.defaultValue, result.value.values, resultTranslation, null, false, value));
							}
						});

						devicesList.forEach(device => $scope.deviceServer.deviceParameters.push(DEVICES_TEMPLATE(device, [], false, false, device)));
						devicesParametersArray.forEach((param) => {
							const deviceName = param.name.split('.')[1];
							const deviceParameters = JSON.parse(param.value);
							const idx = $scope.deviceServer.deviceParameters.findIndex(device => device.key === deviceName);
							$scope.deviceServer.deviceParameters[idx].values = deviceParameters.map(prop => DEVICES_PROPERTIES_TEMPLATE(prop.name, prop.value, false, false, prop.name, prop.value));
						});
					})
					.catch((error) => {
						if (error.status === 400) {
							$scope.synchronize(true);
						}
					});
			}
			init();

			function constructValuePayload(param) {
				const payload = {
					value: param.type === 'checkbox' ? param.value : (param.value || (!param.defaultValue && param.defaultValue !== false ? '' : param.defaultValue)),
					values: param.values,
					defaultValue: param.defaultValue,
					type: param.type
				};

				if (param.parameterType) {
					payload.parameterType = param.parameterType;
				}
				return JSON.stringify(payload);
			}

			function connectToDeviceServer() {
				function deviceServerTypeToJSInput(variable) {
					let type;
					switch (variable) {
					case 'boolean':
						type = 'checkbox';
						break;
					case 'float':
					case 'unsigned int':
					case 'positiveInteger':
					case 'nonNegativeInteger':
						type = 'number';
						break;
					case 'string':
						type = 'text';
						break;
					case 'logLevel':
						type = 'select';
						break;
					default:
						type = 'text';
						break;
					}
					return type;
				}

				function parseStringToArray(str) {
					return str === '' ? ([]) : str.split(',');
				}

				function sendUpdateToServer(parameters) {
					const deferred = $q.defer();
					const payload = [
						{ name: 'LastUpdated', value: moment().format('YYYY-MM-DD HH:mm:ss') },
						{ name: 'devices', value: '[]' }
					];
					payload.push(...parameters.init.map(param => ({ name: param.key, value: constructValuePayload(param) })));
					payload.push(...parameters.fingers.map(param => ({ name: param.key, value: constructValuePayload(param) })));
					payload.push(...parameters.palms.map(param => ({ name: param.key, value: constructValuePayload(param) })));
					payload.push(...parameters.faces.capture.map(param => ({ name: param.key, value: constructValuePayload(param) })));
					payload.push(...parameters.faces.icao.map(param => ({ name: param.key, value: constructValuePayload(param) })));

					if (parameters.remove) {
						payload.push(...parameters.remove.map(param => ({ name: param.key, value: null })));
					}

					DeviceServerResourceAdmin.getVersion({ port: $scope.deviceConfig.port }).$promise
						.then((versionData) => {
							payload.push({ name: 'version', value: JSON.stringify({ date: versionData.date, hash: versionData.hash, version: versionData.version }) });

							DeviceServerParametersService.setParameters(payload)
								.then(deferred.resolve)
								.catch(deferred.reject);
						})
						.catch(deferred.reject);

					return deferred.promise;
				}

				function copyInitParameters(initArrayCopy, deviceServerParameters) {
					const newParameters = [];
					deviceServerParameters.parameters.forEach((deviceServerParameter) => {
						newParameters.push(PARAMETER(deviceServerParameter.name, deviceServerTypeToJSInput(deviceServerParameter.type), '', '', parseStringToArray(deviceServerParameter.values), deviceServerParameter.name));
						const lastElement = newParameters[newParameters.length - 1];
						lastElement.defaultValue = deviceServerParameter.defaultValue === 'true'
						|| (lastElement.values && lastElement.values[lastElement.values.indexOf(deviceServerParameter.defaultValue)])
						|| tryParseIfNumber(lastElement, deviceServerParameter.defaultValue);
						lastElement.value = deviceServerParameter.defaultValue === 'true'
						|| (lastElement.values && lastElement.values[lastElement.values.indexOf(deviceServerParameter.defaultValue)])
						|| tryParseIfNumber(lastElement, deviceServerParameter.defaultValue);
						lastElement.savedValue = lastElement.value;
					});

					// populate map
					const newParametersMap = new Map(newParameters.reduce((acc, param) => {
						acc.push([param.key, param]);
						return acc;
					}, []));
					newParametersMap.delete('LastUpdated');

					// copy already existing parameters
					for (let i = 0; i < initArrayCopy.length; i += 1) {
						const param = initArrayCopy[i];
						const existingParameter = newParametersMap.get(param.key);
						if (existingParameter) {
							existingParameter.value = param.value;
							existingParameter.savedValue = param.savedValue;
							initArrayCopy.splice(i, 1);
							i -= 1;
						}
					}
					// return new parameters
					return [
						...Array.from(newParametersMap.values()),
						...initArrayCopy.map((param) => {
							const paramToDelete = param;
							paramToDelete.value = null;
							return paramToDelete;
						})
					];
				}

				function copyModalitiesParamaters() {
					const deferred = $q.defer();
					DeviceServerParametersResource.getParameters({ names: [] }).$promise
						.then((results) => {
							if (results.length === 0) {
								deferred.resolve({
									faces: $scope.deviceServer.faces,
									fingers: $scope.deviceServer.fingers,
									palms: $scope.deviceServer.palms
								});
							}

							let fingersGroupIndex;
							let facesCaptureGroupIndex;
							let facesIcaoGroupIndex;
							const removeQueueParameters = [];
							const fingersParametersCopy = angular.copy($scope.deviceServer.fingers);
							const facesCaptureParametersCopy = angular.copy($scope.deviceServer.faces.capture);
							const facesIcaoParametersCopy = angular.copy($scope.deviceServer.faces.icao);
							results.forEach((rawResult) => {
								if (rawResult.name === 'LastUpdated' || rawResult.name === 'version') return;

								const result = {
									name: rawResult.name,
									value: rawResult.value
								};

								const resultTranslation = result.name.includes('.') ? result.name.split('.')[1] : result.name;
								fingersGroupIndex = fingersParametersCopy.findIndex(param => param.key === result.name);
								facesCaptureGroupIndex = facesCaptureParametersCopy.findIndex(param => param.key === result.name);
								facesIcaoGroupIndex = facesIcaoParametersCopy.findIndex(param => param.key === result.name);

								if (fingersGroupIndex !== -1) {
									fingersParametersCopy[fingersGroupIndex].value = result.value.value;
									fingersParametersCopy[fingersGroupIndex].defaultValue = result.value.defaultValue;
									fingersParametersCopy[fingersGroupIndex].savedValue = result.value.value;
									fingersParametersCopy[fingersGroupIndex].translation = resultTranslation;
									if (result.value === null) fingersParametersCopy[fingersGroupIndex].edit = true;
								} else if (facesCaptureGroupIndex !== -1) {
									facesCaptureParametersCopy[facesCaptureGroupIndex].value = result.value.value;
									facesCaptureParametersCopy[facesCaptureGroupIndex].defaultValue = result.value.defaultValue;
									facesCaptureParametersCopy[facesCaptureGroupIndex].savedValue = result.value.value;
									if (result.value === null) facesCaptureParametersCopy[facesCaptureGroupIndex].edit = true;
								} else if (facesIcaoGroupIndex !== -1) {
									facesIcaoParametersCopy[facesIcaoGroupIndex].value = result.value.value;
									facesIcaoParametersCopy[facesIcaoGroupIndex].defaultValue = result.value.defaultValue;
									facesIcaoParametersCopy[facesIcaoGroupIndex].savedValue = result.value.value;
									if (result.value === null) facesIcaoParametersCopy[facesIcaoGroupIndex].edit = true;
								} else if (result.name.toLowerCase().includes('finger') || result.name.toLowerCase().includes('face')) {
									removeQueueParameters.push(PARAMETER(result.name, result.value.type, null));
								}
							});
							deferred.resolve({
								fingers: fingersParametersCopy,
								faces: { capture: facesCaptureParametersCopy, icao: facesIcaoParametersCopy },
								palms: [],
								remove: removeQueueParameters
							});
						});
					return deferred.promise;
				}

				const deferred = $q.defer();
				$scope.deviceConfig.status = DEVICE_STATUSES.connecting;
				// .query({ actionId: action.Id })
				DeviceServerResourceAdmin.getInfo({ port: $scope.deviceConfig.port }).$promise
					.then((deviceServerParameters) => {
						const initArrayCopy = angular.copy($scope.deviceServer.init);
						$scope.deviceServer.init = [];
						const newInitParameters = copyInitParameters(initArrayCopy, deviceServerParameters);
						copyModalitiesParamaters()
							.then((newModalitiesParameters) => {
								sendUpdateToServer({
									init: newInitParameters,
									faces: newModalitiesParameters.faces,
									fingers: newModalitiesParameters.fingers,
									palms: newModalitiesParameters.palms,
									deviceParameters: $scope.deviceServer.deviceParameters,
									remove: newModalitiesParameters.remove
								})
									.then(() => {
										$scope.deviceServer.init = newInitParameters.filter(param => param.value !== null);
										$scope.deviceConfig.status = DEVICE_STATUSES.connected;
										$scope.toggleAutoUpdate();
										$scope.cancelSyncing();
										deferred.resolve();
									})
									.catch(() => {
										$scope.deviceServer.init = [];
										$scope.deviceConfig.status = DEVICE_STATUSES.versionDowngrade;
										$scope.deviceConfig.autoUpdate = false;
										stopPolling();
										deferred.reject();
									});
							});
					})
					.catch(() => {
						$scope.deviceConfig.status = DEVICE_STATUSES.disconnected;
						deferred.reject();
					});

				return deferred.promise;
			}

			$scope.attemptConnect = function (force = false) {
				if ($scope.deviceConfig.status === DEVICE_STATUSES.connecting) return;
				stopPolling();

				const connect = () => poll(connectToDeviceServer);
				if (force) {
					connect();
				} else {
					$q.all([
						DeviceServerResourceAdmin.getVersion({ port: $scope.deviceConfig.port }).$promise,
						DeviceServerParametersResource.getParameters({ names: 'version' }).$promise,
					])
						.then((versionData) => {
							const [deviceServerVersionData, rawBackendParametersVersionData] = versionData;
							const backendParametersVersionData = JSON.parse(rawBackendParametersVersionData[0].value);
							if (deviceServerVersionData.hash !== backendParametersVersionData.hash) {
								connect();
							} else {
								$scope.deviceConfig.status = DEVICE_STATUSES.sameVersions;
							}
						})
						.catch(() => {
							stopPolling();
							$scope.deviceConfig.status = DEVICE_STATUSES.versionDowngrade;
						});
				}
			};

			$scope.isDowngradeOrNothingNew = function () {
				return $scope.deviceConfig.status === DEVICE_STATUSES.sameVersions
				|| $scope.deviceConfig.status === DEVICE_STATUSES.versionDowngrade;
			};

			$scope.toggleAutoUpdate = function (newValue) {
				if (newValue) {
					poll(connectToDeviceServer);
				} else {
					stopPolling();
				}
			};

			$scope.synchronize = function (firstTime = false) {
				$scope.isSyncing = true;
				$scope.deviceConfig.autoUpdate = true;

				if (!firstTime) {
					$q.all([
						DeviceServerResourceAdmin.getVersion({ port: $scope.deviceConfig.port }).$promise,
						DeviceServerParametersResource.getParameters({ names: 'version' }).$promise,
					])
						.then((versionData) => {
							const [deviceServerVersionData, rawBackendParametersVersionData] = versionData;
							const backendParametersVersionData = JSON.parse(rawBackendParametersVersionData[0].value);
							if (deviceServerVersionData.hash !== backendParametersVersionData.hash) {
								poll(connectToDeviceServer);
							} else {
								$scope.deviceConfig.status = DEVICE_STATUSES.sameVersions;
							}
						})
						.catch((error) => {
							if (error.status !== -1) { // not disconnected
								$scope.deviceConfig.status = DEVICE_STATUSES.versionDowngrade;
							} else {
								$scope.deviceConfig.status = DEVICE_STATUSES.disconnected;
							}
							stopPolling();
						});
				} else {
					poll(connectToDeviceServer);
					$scope.firstTime = true;
					$timeout(() => { $scope.firstTime = false; }, 3000);
				}
			};
			$scope.cancelSyncing = function () {
				$scope.isSyncing = false;
				stopPolling();
			};

			$scope.discardParameterChanges = function (index, array) {
				if (index !== -1) {
					array[index].value = array[index].savedValue || array[index].defaultValue;
					$scope.validate(array[index]);
				}
			};
			$scope.discardDevicePropertiesChanges = function (index, array) {
				if (index !== -1) {
					array[index].value = array[index].savedValue;
					array[index].key = array[index].savedKey;
					$scope.validateDeviceProps(array[index], array);
				}
			};

			$scope.setDefaultParameter = function (index, array) {
				if (index !== -1) {
					array[index].value = array[index].defaultValue;
					$scope.validate(array[index]);
				}
			};

			$scope.validate = function (param) {
				param.edit = (param.savedValue !== null ? param.value !== param.savedValue : param.value !== param.defaultValue);
			};
			$scope.validateDevice = function (device) {
				device.edit = (device.savedKey && (device.key !== device.savedKey)) || (device.values && device.values.some(props => props.edit));
			};
			$scope.validateDeviceProps = function (param, device) {
				param.edit = (param.savedKey && (param.key !== param.savedKey))
				|| (param.savedValue && (param.value !== param.savedValue))
				|| (!param.savedKey && param.edit);
				$scope.validateDevice(device || param);
			};

			$scope.isDeviceValid = function (device) {
				return !(device.error || !device.key
				|| device.values.length === 0
				|| device.values.some(property =>
					!property.deleted
					&& (property.error
					|| !property.key
					|| !property.value)));
			};

			$scope.enableSubmit = function (container, devicesChecking = false) {
				const parametersChanged = container.some(parameterObj => parameterObj.edit
				|| (parameterObj.values && parameterObj.values.some(obj => obj.edit)));

				if (devicesChecking) {
					const devicesHaveError = $scope.deviceServer.deviceParameters.some(device => !device.deleted && !$scope.isDeviceValid(device));
					return $scope.hasAnyAuthority('PERMISSION_ADMINISTRATION_PARAMETERS') && !devicesHaveError;
				}
				return $scope.hasAnyAuthority('PERMISSION_ADMINISTRATION_PARAMETERS') && parametersChanged;
			};

			function transformDataToPayload(data) {
				const payload = data.reduce((result, param) => {
					if (param.edit) {
						result.push({ name: param.key, value: constructValuePayload(param) });
					}
					return result;
				}, []);
				return payload;
			}

			function transformDevicesToPayload(devices) {
				const devicesList = [];
				const payload = [];
				devices.forEach((device) => {
				// update device list
					if (!device.deleted) {
						devicesList.push(device.key);
					}

					// push all properties
					const properties = [];
					device.values.forEach((valueObj) => {
						if (!valueObj.deleted) {
							properties.push({ name: valueObj.key, value: valueObj.value });
						}
					});
					payload.push({ name: `device-parameters.${device.key}`, value: device.deleted ? null : JSON.stringify(properties) });
				});

				payload.push({ name: 'devices', value: JSON.stringify(devicesList) });
				return payload;
			}

			$scope.submit = function (container) {
				function deleteMarkedParameters() {
					$scope.deviceServer.deviceParameters.forEach((param, index) => {
						if (param.deleted) {
							$scope.deviceServer.deviceParameters.splice(index, 1);
						}
					});
				}
				function markGroupParametersAsSubmitted(group) {
					group.forEach((param) => {
						if (param.edit) {
							param.edit = false;
							param.savedValue = angular.copy(param.value);
						}
					});
				}

				if (!AuthDataHolder.hasAnyAuthority('PERMISSION_ADMINISTRATION_PARAMETERS')) {
					return;
				}

				var startMsg = 'Setting parameters...';
				var successMsg = 'Parameters have been set';

				$translate([startMsg, successMsg]).then((translations) => {
					TaskPollerService.pollAndAlert((success, error) => {
						const payload = [{ name: 'LastUpdated', value: moment().format('YYYY-MM-DD HH:mm:ss') }];
						if ($scope.opts.activeTab === 'tab-device') {
							payload.push(...transformDevicesToPayload(container));
						} else {
							payload.push(...transformDataToPayload(container));
						}
						DeviceServerParametersService.setParameters(payload)
							.then(success)
							.catch(error);
					}, $scope.parametersAlerts, translations[startMsg], translations[successMsg], () => {
						markGroupParametersAsSubmitted($scope.deviceServer.init);
						markGroupParametersAsSubmitted($scope.deviceServer.fingers);
						markGroupParametersAsSubmitted($scope.deviceServer.palms);
						markGroupParametersAsSubmitted($scope.deviceServer.faces.capture);
						markGroupParametersAsSubmitted($scope.deviceServer.faces.icao);
						deleteMarkedParameters();
					});
				});
			};

			const DEVICES_TEMPLATE = function (key = 'New device', values = [], edit = true, error = false, savedKey = null) {
				return {
					key,
					values,
					edit,
					error,
					savedKey,
					deleted: false
				};
			};

			const DEVICES_PROPERTIES_TEMPLATE = function (key = '', value = '', edit = true, error = false, savedKey = null, savedValue = null) {
				return {
					key,
					value,
					edit,
					error,
					savedKey,
					savedValue,
					deleted: false
				};
			};

			$scope.areAnyDevices = function () {
				return $scope.deviceServer.deviceParameters.length !== 0 && $scope.deviceServer.deviceParameters.some(param => !param.deleted);
			};
			$scope.areAnyParameters = function (field) {
				return field.values.length !== 0 && field.values.some(param => !param.deleted);
			};

			$scope.addDevice = function () {
				const field = DEVICES_TEMPLATE();
				$scope.deviceServer.deviceParameters.push(field);

				$scope.setField($scope.deviceServer.deviceParameters[$scope.deviceServer.deviceParameters.length - 1], $scope.deviceServer.deviceParameters.length - 1);

				$scope.checkForDuplicates($scope.deviceServer.deviceParameters);
			};

			$scope.removeDevice = function (index) {
				if (!$scope.hasPermissionToEdit()) return;

				if ($scope.deviceServer.deviceParameters[index].savedKey) {
					$scope.deviceServer.deviceParameters[index].deleted = true;
					$scope.deviceServer.deviceParameters[index].values.forEach((param) => {
						param.deleted = true;
					});
					$scope.deviceServer.deviceParameters[index].edit = true;
				} else {
					$scope.deviceServer.deviceParameters.splice(index, 1);
					$scope.checkForDuplicates($scope.deviceServer.deviceParameters);
				}

				let nextIndex;
				for (let i = $scope.deviceServer.deviceParameters.length - 1; i > 0; i -= 1) {
					if (!$scope.deviceServer.deviceParameters[i].deleted) {
						nextIndex = i;
						break;
					}
				}

				$scope.field = $scope.deviceServer.deviceParameters[nextIndex] || false;
				selectedID = nextIndex;
			};

			$scope.addDeviceParameter = function (field) {
				if (!$scope.hasPermissionToEdit()) return;

				field.values.push(DEVICES_PROPERTIES_TEMPLATE());
				field.edit = true;
				$scope.checkForDuplicates(field.values);
			};

			$scope.removeDeviceParameter = function (index, device) {
				if (!$scope.hasPermissionToEdit()) return;

				if (index !== -1) {
					if (device.values[index].savedKey) {
						device.values[index].deleted = true;
						device.values[index].edit = true;
					} else {
						device.values.splice(index, 1);
					}
					$scope.validateDevice(device);
					$scope.checkForDuplicates(device.values);
				}
			};

			$scope.isActive = function (index) {
				return index === selectedID;
			};

			$scope.setField = function (selectedField, index) {
				$scope.field = selectedField;
				selectedID = index;
			};

			$scope.checkForDuplicates = function (array) {
				const countDict = array.reduce((acc, obj, index) => {
					array[index].error = false;
					if (array[index].deleted) return acc;

					if (!acc[obj.key]) acc[obj.key] = { count: 0, indexes: [] };

					acc[obj.key].count += 1;
					acc[obj.key].indexes.push(index);
					return acc;
				}, {});
				const duplicates = Object.keys(countDict).filter(key => countDict[key].count > 1);

				duplicates.forEach((key) => {
					countDict[key].indexes.forEach((index) => {
						array[index].error = true;
					});
				});
			};

			$scope.hasPermissionToEdit = function () {
				return $scope.hasAnyAuthority('PERMISSION_ADMINISTRATION_PARAMETERS');
			};

			$scope.isNumberType = function (parameter) {
				return parameter.type === 'number'
				&& !$scope.isRangeSliderType(parameter);
			};
			$scope.isRangeSliderType = function (parameter) {
				return parameter.min !== undefined
				&& parameter.max !== undefined;
			};

			$scope.onSliderChange = function (param) {
				$scope.validate(param);
			};

			$scope.getSliderOptions = function (parameter, index) {
				return {
					floor: parameter.min,
					ceil: parameter.max,
					disabled: !$scope.hasPermissionToEdit() || ($scope.hasPermissionToEdit() && parameter.isDisabled && parameter.isDisabled()),
					id: `slider-${index}`,
					onChange: $scope.onSliderChange(parameter)
				};
			};
		}]);
